import React from 'react';
import { useStyles } from './style';
import { useFormik } from "formik";
import { resetpasswordvalidationSchema } from './utils';
import { Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './style.css';


const ForgotPasswordForm = (props) => {
    const classes = useStyles();
    const [forgotopen, setForgotOpen] = React.useState(false);
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: resetpasswordvalidationSchema,
        onSubmit: (values) => {
            props.resetpassword(values);
            setForgotOpen(false);
        },
    });
    React.useEffect(() => {
        props.resetForgotPasswordProps();
    },
        // eslint-disable-next-line
        []);
    React.useEffect(() => {
        if (props.isReset === true) {
            setTimeout(() => {
                props.resetForgotPasswordProps();
            }, (3000))
        }
    },
        // eslint-disable-next-line
        [props.isReset])
    return (
        <React.Fragment>
            <div className="pass-link" style={{ display:'flex',width:'100%' }} >
                <span style={{ cursor: 'pointer',width:'auto' }} onClick={() => setForgotOpen(true)}>
                Forgot password?
                </span>
            </div>
            <Dialog
                open={forgotopen}
                PaperProps={{
                    style: {
                        borderRadius: '10px',
                        background: '#fff',
                        width: '100%',
                        padding: '0px',
                        margin: 0
                    }
                }}
                onClose={() => setForgotOpen(false)}>
                <DialogTitle className={classes.modalHeading}>
                    <span
                        className={classes.modalHeading}>
                        {'Forgot Password ?'}
                    </span>
                    <span
                        style={{
                            width: '25px', float: 'right',
                            color: '#ffffff',
                            borderRadius: '50%',
                            fontSize: '14px',
                            height: '25px',
                            background: '#BDBDBD',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center'
                        }}>
                        <CloseIcon
                            style={{
                                cursor: 'pointer',
                                fontSize: '14px'
                            }}
                            onClick={() => setForgotOpen(false)} />
                    </span>
                </DialogTitle>
                <DialogContent dividers>
                    <div className={classes.inputFieldDiv}>
                        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                            <div className={classes.inputDiv}>
                                <TextField
                                    variant='standard'
                                    fullWidth
                                    name={'email'}
                                    placeholder={'Email'}
                                    value={formik.values['email']}
                                    onChange={formik.handleChange}
                                    error={formik.touched['email'] && Boolean(formik.errors['email'])}
                                    helperText={formik.touched['email'] && formik.errors['email']}
                                    InputProps={{
                                        style: {
                                            background: '#FFFFFF',
                                            border: 'none',
                                            borderRadius: '30px',
                                            height: '56px',
                                            display: 'flex',
                                            paddingLeft: '30px',
                                            paddingRight: '30px',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            width: '100%',
                                        },
                                    }}
                                />
                            </div>
                            <div className={classes.buttonsDiv}>
                                <button type="submit" className='solid-button' style={{ width: '100%' }}>
                                    {'Submit'}
                                </button>
                            </div>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default ForgotPasswordForm