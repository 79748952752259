import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    root:{
        // backgroundColor:`${ALERT_GREEN_BG}`,
        background: 'linear-gradient(310deg, rgb(23, 173, 55), rgb(152, 236, 45))',
        opacity:0.3,
        borderRadius:'16px',
        padding:'20px',
        width:'auto',
        height:'auto',
        // fontFamily:'Poppins',
        fontWeight:600,

    },
    error:{
        // backgroundColor:`${ALERT_RED_BG}`,
        background: `linear-gradient(310deg, rgb(234, 6, 6), rgb(255, 102, 124))`,
        opacity:0.3,
        borderRadius:'16px',
        padding:'20px',
        width:'auto',
        height:'auto',
        // fontFamily:'Poppins',
        fontWeight:600,
    },
}));