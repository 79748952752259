import React from "react";
import {
  CHECK_MIN_AMOUNT,
  UPDATE_FULL_CART,
} from "../../Redux/Actions/Queries";
import {
  PLACEORDER_ADDRESS,
  RESTRICT_ORDER,
} from "../../Redux/Actions/Queries";
import client from "../../Configurations/apollo";
import AlertBar from "../AlertBar/index.js";
import carticon from "../../Assets/Images/cart.svg";
import { AppBar } from "@mui/material";
import { db } from "../db";
import { useLiveQuery } from "dexie-react-hooks";
import LoginOrSignupModal from "../Forms/LoginOrSignupModal";
import {
  Alert,
  Badge,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const EasyCheckout = (props) => {
  const { cart } = db;
  const cartdata = useLiveQuery(() => cart.toArray(), [cart]);
  const [isButtonDisabled, setButtonDisabled] = React.useState(false);
  const [restrictorderopen, setRestrictOrderOpen] = React.useState(false);
  const [checkopen, setCheckOpen] = React.useState(false);
  const [addressopen, setAddressOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  // eslint-disable-next-line
  const [loginpopupopen, setLoginPopupOpen] = React.useState(false);
  const [state, setState] = React.useState({
    data: {},
    CheckAmount: [],
    cartid: "",
    storeid: "",
    storename: "",
    storelogo: "",
    storelength: "",
    deleteStore: false,
    error: "",
    restrictordernum: 0,
    right: false,
  });
  const calculateTotalNumberOfItems = (data) => {
    let noofitems = 0;
    data.map((c) => (noofitems = noofitems + c?.products?.length));
    return noofitems;
  };
  const calCulateStoretotal = (products) => {
    let t = 0;
    products.map(
      (p) => (t = t + Number(p.cartquantity) * Number(p.productprice))
    );
    return t;
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  const placeorderCheck = debounce((id, lat, lng, cartdata) => {
    
    
    if (!isButtonDisabled) {
      if (
        !localStorage.getItem("token") ||
        (props.isCartFailed === true && props.carterror === "Unauthenticated!")
      ) {
        setLoginPopupOpen(true);
      } else {
        if (cartdata.length > 1)
          alert(
            "Cannot add more than one store at a time ,Please remove any store from cart to continue"
          );
        else
          client
            .mutate({
              mutation: UPDATE_FULL_CART,
              variables: {
                userid: id,
                storeid: cartdata[0].storeid,
                products: cartdata.flatMap((c) =>
                  c.products.map((p) => ({
                    productid: p.productid,
                    productPrice: Number(p.productPrice),
                    quantity: p.quantity,
                    price: (p.quantity * Number(p.productPrice)).toString(),
                  }))
                ),
              },
            })
            .then((res) => {
              if (res.data) {
                if (
                  res.data &&
                  res.data !== null &&
                  res.data.updateFullCart &&
                  res.data.updateFullCart !== null
                ) {
                  client
                    .query({
                      query: RESTRICT_ORDER,
                    })
                    .then((res) => {
                      if (res.data) {
                        if (cartdata.length <= 10 - res.data.restrictOrder) {
                          setState({
                            ...state,
                            restrictordernum: Number(
                              10 - res.data.restrictOrder
                            ),
                          });
                          client
                            .query({
                              query: CHECK_MIN_AMOUNT,
                            })
                            .then((res) => {
                              if (
                                res.data &&
                                res.data.checkMinAmount &&
                                !res.data.checkMinAmount.length
                              ) {
                                client
                                  .query({
                                    query: PLACEORDER_ADDRESS,
                                    variables: {
                                      userid: `${id}`,
                                      lat: Number(lat),
                                      lng: Number(lng),
                                    },
                                  })
                                  .then((res) => {
                                    if (
                                      res.data &&
                                      res.data.placeOrderNewAddressCheck &&
                                      res.data.placeOrderNewAddressCheck
                                        .length === 0
                                    ) {
                                      props.navigate("/checkout");
                                    } else if (
                                      res.data &&
                                      res.data.placeOrderNewAddressCheck &&
                                      res.data.placeOrderNewAddressCheck
                                        .length > 0
                                    ) {
                                      setAddressOpen(true);
                                      setTimeout(() => {
                                        setAddressOpen(false);
                                      }, 2000);
                                      setState({ ...state, right: true });
                                      setState({ data: res.data });
                                    } else if (res.errors) {
                                      console.log(res.errors[0].message);
                                    }
                                  })
                                  .catch((err) => {
                                    return null;
                                  });
                              } else if (
                                res.data &&
                                res.data.checkMinAmount &&
                                res.data.checkMinAmount.length &&
                                res.data.checkMinAmount.length > 0
                              ) {
                                setCheckOpen(true);
                                setState({
                                  ...state,
                                  CheckAmount: res.data.checkMinAmount,
                                });
                                setTimeout(() => {
                                  setCheckOpen(false);
                                }, 3000);
                              } else if (res.errors) {
                                console.log(res.errors[0].message);
                              }
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        } else if (
                          cartdata.length >
                          10 - res.data.restrictOrder
                        ) {
                          setRestrictOrderOpen(true);
                          setTimeout(() => {
                            setRestrictOrderOpen(false);
                          }, 3000);
                        }
                      }
                      if (res.errors) {
                        alert(res.errors[0].message);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else if (
                  res.data &&
                  res.data.updateFullCartv0 &&
                  res.data.updateFullCartv0 === null
                ) {
                  return null;
                } else if (res.errors) {
                  console.log(res.errors);
                }
              } else if (res.errors) {
                console.log(res.errors);
              }
            })
            .catch((err) => {
              console.log("error occured",err)
              setSnackbarMessage(err.message || "An error occurred");
              setSnackbarOpen(true);
              console.log("ethan  aa error", err);
            });
      }
      setButtonDisabled(true);
      setTimeout(() => {
        setButtonDisabled(false);
      }, 1000);
    }
  }, 300);

  // const checkoutClick = () => {
  //   client
  //     .mutate({
  //       mutation: UPDATE_CART_AT_CHECKOUT,
  //       errorPolicy: "all",
  //     })
  //     .then((res) => {
  //       if (res.data && res.data.updateCartAtCheckout && res.data.updateCartAtCheckout !== null) {
  //         props.navigate("/checkout");
  //       } else if (res.errors) {
  //         alert(res.errors[0].message);
  //         props.getCart(localStorage.getItem("userid"));
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // const setErrorMessage = (data) => {
  //   let a = data.map(
  //     (store, i) =>
  //       cartdata &&
  //       cartdata.length >= 1 &&
  //       cartdata.map((c) => {
  //         return (c.storeid.id === store
  //           ? `${c.storeid.storeName}${Number(i) !== Number(data.length - 1)
  //             ? ',' : ' is not deliverable in the selected location!'}`
  //           : null)
  //       })

  //   )
  //   return a
  // };
  return (
    <>
      {cartdata && cartdata !== null && cartdata.length > 0 ? (
        <>
          <AppBar
            sx={{
              top: "auto",
              left: 0,
              bottom: 0,
              width: "100%",
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
              height: "53px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                bottom: 0,
                background:
                  " linear-gradient(180deg, #FFD951 0%, #FFC800 100%)",
                width: "100%",
                display: "flex",
                paddingTop: "15px",
                paddingBottom: "15px",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
              }}
            >
              <span
                style={{
                  float: "left",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: "#061303",
                  width: "auto",
                  paddingLeft: "80px",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <img
                  src={carticon}
                  alt={"carticon"}
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
              <span
                style={{
                  fontWeight: 1000,
                  textAlign: "left",
                  color: "#061303",
                  width: "100%",
                  fontSize: "16px",
                  paddingLeft: "5px",
                  lineHeight: "22px",
                }}
              >
                <b>
                  &nbsp;
                  {calculateTotalNumberOfItems(cartdata) > 0
                    ? `${calculateTotalNumberOfItems(cartdata)} items in cart`
                    : `${calculateTotalNumberOfItems(cartdata)} items in cart`}
                </b>
              </span>
              {cartdata && cartdata.length > 0 && (
                <span
                  style={{
                    float: "right",
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                    paddingRight: "70px",
                  }}
                >
                  {!localStorage.getItem("token") ||
                  (props.isCartFailed === true &&
                    props.carterror === "Unauthenticated!") ? (
                    <LoginOrSignupModal
                      componentkey={"checkout"}
                      {...props.props}
                    />
                  ) : localStorage.getItem("userid") ? (
                    <button
                      disabled={isButtonDisabled}
                      onClick={() => {
                        localStorage.getItem("userid")
                          ? placeorderCheck(
                              localStorage.getItem("userid"),
                              localStorage.getItem("lattitude"),
                              localStorage.getItem("longitude"),
                              cartdata &&
                                cartdata !== null &&
                                cartdata.length > 0
                                ? cartdata.map((c) => ({
                                    storeid: c.storeid,
                                    totalPrice: calCulateStoretotal(c.products),
                                    products: c.products.map((p) => ({
                                      productid: p.productid,
                                      productPrice: Number(p.productprice),
                                      quantity: p.cartquantity,
                                      price: (
                                        p.cartquantity * p.productprice
                                      ).toString(),
                                    })),
                                  }))
                                : []
                            )
                          : setLoginPopupOpen(true);
                      }}
                      style={{
                        background: "rgba(255, 255, 255, 0.5)",
                        borderRadius: "18px",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "22px",
                        color: "#061303",
                        border: "none",
                        cursor: "pointer",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                    >
                      {"Checkout"}
                    </button>
                  ) : (
                    <LoginOrSignupModal
                      componentkey={"checkout"}
                      {...props.props}
                    />
                  )}
                </span>
              )}
            </div>
          </AppBar>
          {addressopen && (
            <>
              <AlertBar
                type={"error"}
                props={props}
                {...props}
                message={
                  state.data &&
                  state.data.placeOrderNewAddressCheck &&
                  state.data.placeOrderNewAddressCheck.length > 0 &&
                  state.data.placeOrderNewAddressCheck.map(
                    (store, i) =>
                      cartdata &&
                      cartdata.length >= 1 &&
                      cartdata.map((c) =>
                        c.storeid === store
                          ? `${c.storename}${
                              Number(i) !==
                              Number(
                                state.data.placeOrderNewAddressCheck.length - 1
                              )
                                ? ","
                                : " is not deliverable in the selected location"
                            }`
                          : null
                      )
                  )
                }
              />
            </>
          )}
          {checkopen && (
            <AlertBar
              props={props}
              type={"error"}
              message={
                state.CheckAmount &&
                state.CheckAmount.length &&
                state.CheckAmount.length > 0 &&
                state.CheckAmount.map((msg) => msg)
              }
              {...props}
            />
          )}
          {restrictorderopen && (
            <AlertBar
              type={"error"}
              {...props}
              props={props}
              message={
                state.restrictordernum && state.restrictordernum > 0
                  ? `Exceeded maximum number of orders for today!  You may place order from ${state.restrictordernum} more store  `
                  : `Exceeded maximum number of orders for today!`
              }
            />
          )}

          {
             <Snackbar
             open={snackbarOpen}
             autoHideDuration={5000}
             onClose={() => setSnackbarOpen(false)}
             anchorOrigin={{
               vertical: "top",
               horizontal: "center",
             }}
           >
             <MuiAlert
               onClose={() => setSnackbarOpen(false)}
               severity="error"
               sx={{
                 backgroundColor: "#d32f2f",
                 borderRadius: "6px",
                 color: "#fff",
                 boxShadow:
                   "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
               }}
             >
               {snackbarMessage}
             </MuiAlert>
           </Snackbar>
          }
        </>
      ) : null}
    </>
  );
};

export default EasyCheckout;
