import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ReactComponent as Empty_Cart_Img } from "../../Assets/Images/empty_cart_image.svg";

const EmptyCart = (props) => {
  const theme = createTheme({
    typography: {
      cartEmptyHeading: {
        color: "#000",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "1.5rem",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "1.375rem",
        marginBottom: "0.5rem",
      },
      cartEmptyText: {
        color: "#061303",
        opacity: "0.5",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "0.875rem",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
      },
    },
  });

  const redirectToHome = () => {
    props.navigate(`/`);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: { xs: "5px", sm: "10px", md: "30px" },
          boxSizing: "border-box",
        }}
        spacing={2}
      >
        <Grid item>
          <div>
            <Empty_Cart_Img />
          </div>
        </Grid>
        <Grid item sx={{ marginTop: 3 }}>
          <Typography variant="h6" sx={{ typography: "cartEmptyHeading" }}>
            Your cart is empty
          </Typography>

          <Typography variant="body1" sx={{ typography: "cartEmptyText" }}>
            You can go to the home page to view more items.
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <Button
              onClick={() => redirectToHome()}
              variant="contained"
              size="medium"
              sx={{
                borderRadius: "2.4375rem",
                background: "#6AA92A",
                marginTop: 2,
                "&:hover": {
                  backgroundColor: "#6AA92A",
                },
                "&:active": {
                  backgroundColor: "#6AA92A",
                },
              }}
            >
              Go Home
            </Button>
          </div>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default EmptyCart;
