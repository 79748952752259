import React from 'react';
import OTPInput from "otp-input-react";
import './style.css';

const OTPForm = (props) => {
    const [state, setState] = React.useState({
        otp: "",
        heading: "Login with your mobile number",
    });
    const handleOTPChange = (element) => {
        setState({ ...state, otp: element });
    };
    const handleVerifyOTP = (e) => {
        e.preventDefault();
        let otp = Number(state.otp);
        props.verifyOTP(props.phoneNumber, otp);
    };
    const onSubmit = () => {
        props.sendloginOTP(props.phoneNumber)
    };
    const [counter, setCounter] = React.useState(59);
    React.useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);
    React.useEffect(() => {
        if (props.isOTPCleared === true) {
            setState({ ...state, otp: '' })
        }
    },
        // eslint-disable-next-line
        [props.isOTPCleared]);
    return (
        <React.Fragment>
            <p className="form-heading">
                {`An OTP has been sent to your number ending with ${props.phoneNumber && props.phoneNumber.slice(-4)}.Please enter the code to continue`}
            </p>
            <div style={{
                width: '100%', paddingTop: '25px', paddingBottom: '15px', justifyContent: 'center', alignItems: 'center', display: 'flex',
                flexDirection: 'column'
            }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <OTPInput name="otp"
                    autoFocus
                    inputStyles={{border:'1px solid rgba(0,0,0,0.5)'}}
                        value={state.otp}
                        onChange={handleOTPChange} OTPLength={4} otpType="number" disabled={false} />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '25px' }}>
                    <span style={{ width: '100%', float: 'left' }}>
                        {counter > 0 ?
                            <span> 00:{counter}</span>
                            :
                            <button className='outlined-button' type="button"
                                onClick={() => { onSubmit(); setCounter(59) }}>
                                {'Resend OTP'}
                            </button>
                        }
                    </span>
                    <span style={{ width: '100%', float: 'right', paddingLeft: '15px' }}>
                        <button className='solid-button' style={{width:'100%'}} type="submit" onClick={(e) => handleVerifyOTP(e)}>
                            {'Verify'}
                        </button>
                    </span>
                </div>
                {/* </form> */}
            </div>
        </React.Fragment>
    )
}

export default OTPForm