import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import './mob.css';

const MobStoreCard = (props) => {

  const routeToStore = (e, id) => {
    e.persist();
    props.props.getDepartments(props.storeid);
    localStorage.setItem("storeLogo", props.StoreLogo);
    localStorage.setItem('storeid', props.storeid);
    localStorage.setItem("storeName", props.Storename);
    localStorage.setItem("businessType", props.businessType);
    localStorage.setItem("storelocation", props.storelocation);
    props.navigate(`/stores/categories`)
  }

  return (
  
    <Card sx={{ display: 'flex',cursor:'pointer', boxShadow: "0px 2px 8px rgb(0 0 0 / 16%)",borderRadius: '12px' }} onClick={(e) => routeToStore(e, props.storeid)}>
          <div className='store-image-div'>
          <img src={props.StoreLogo} alt= {`${props.Storename} Logo`} className='Card-Image' />
        </div>
    <Box sx={{ display: 'flex',textAlign:'justify',alignItems:'center',verticalAlign: 'middle'}}>
      <CardContent sx={{ flex: '1 0 auto' }}>
      <div className='mobStore-name'>
      {`${props.Storename}`}
          </div>
          <div style={{overflow: 'hidden',
          width: '100px',
          whiteSpace: 'nowrap',
          textOverflow:'ellipsis'}}>
          {`${props.storeDescription}`}
          </div>
      </CardContent>
    </Box>
  </Card>
  )
}
export default MobStoreCard