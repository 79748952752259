import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    root: {
        backgroundColor:'rgba(247,250,255,255)',  
        width: '100%',
        position:'relative',
        marginTop:'45px',
        
    },
    headingDiv: {
    width:'100%',
    //   height: '100px',
      textAlign:'center',
      justifyContent:'center',
      alignItems:'center',
      backgroundColor:'rgba(247,250,255,255)',
    },
    // center: {
    //     top: '50%',
    //     left: '50%',
    //     // transform: 'translate(-50%, -50%)',
    // },
    heading: {
        fontSize: '30px',
        padding: '0 5px',
        fontWeight: 600,
        color:'#424242',
        backgroundColor:'rgba(247,250,255,255)',
    //     justifyContent:'center',
    //   alignItems:'center',
    // textAlign:'center',
    },
    description: {
        color:'#727272',
        justifyContent:'center',
        textAlign:'left',
        fontSize:'16px',
    },
    terms: {
        paddingTop:'25px',
        paddingBottom:'25px',
    },
    termsHeading: {
        textAlign:'left',
        justifyContent:'left',
        alignItems:'left',
    },
    termsTitle: {
        fontSize:'22px',
        fontWeight:600,
        color:'#424242',
        paddingBottom:'15px',
        textAlign:'left',
        justifyContent:'left',
        alignItems:'left',
    },
  });
  