import React from 'react';
import { Grid } from '@mui/material';
import { DESCRIPTION, MAIN_HEADING, termsandconditions } from '../TermsAndPrivacyPolicy/terms';
// import { useStyles } from './styles';
import './index.css';
import Header from '../Header';

const PrivacyPolicy = (props) => {
    
    React.useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
    },
    // eslint-disable-next-line
    []);

    return (
        <React.Fragment>
        <Header {...props}/>
        <Grid container justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
            <Grid item xs={12}>
                <h1 className='mainHeading'>
                    {MAIN_HEADING}
                </h1>
            </Grid>
            <Grid item xs={10}>
            <div className='description'>
                {DESCRIPTION}
            </div>
            </Grid>
            <Grid item xs={10}>
            {termsandconditions.map((terms,index)=>
                <div className='terms' key={index}>
                    <div className='termsHeading'>
                        <h2 className='termsTitle'>
                            {`${terms.index}.\t${terms.heading}`}
                        </h2>
                    </div>
                    <div className='description'>
                        {terms.description}
                    </div>
                </div>
            )}
            </Grid>
        </Grid>
    </React.Fragment>
    )
}

export default PrivacyPolicy
