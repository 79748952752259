import * as React from 'react';
import { Box, Drawer } from '@mui/material';
import logo from '../Assets/Images/trolleygreen.png';
import CloseIcon from '@mui/icons-material/Close';

export default function DownloadApp(props) {
    const [state, setState] = React.useState({
        bottom: true,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const handleClose = () => {
        setState({ ...state,bottom: false });
    };

    return (
        <>
            <Drawer
                anchor={'bottom'}
                open={state['bottom']}
                PaperProps={{
                    style: {
                        background: '#fff',
                        paddingTop: '25px',
                        paddingBottom: '25px',
                        borderTopLeftRadius: '14px',
                        borderTopRightRadius: '14px',
                    }
                }}
                onClose={toggleDrawer('bottom', false)}
                sx={{
                    borderTopLeftRadius: '14px',
                    borderTopRightRadius: '14px',
                }}
            >                   
                <Box style={{ 
                    display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingLeft: '15px', paddingRight: '15px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',width:'100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left',width:'100%' }}>
                        <img src={logo} alt="NearShopz-Logo" style={{ width: '35px', height: '35px', maxWidth: '100%', maxHeight: '100%', borderRadius: '6px' }} />
                        <span style={{ paddingLeft: '15px', fontSize: '14px', fontWeight: 600 }}>
                            {'Speed up your shopping in'}
                            <br/>{' the'}<b>{' NearShopz'}</b>{' App'}
                        </span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span
                            style={{
                                width: '20px',
                                color: '#ffffff',
                                borderRadius: '50%',
                                fontSize: '11px',
                                height: '20px',
                                background: '#BDBDBD',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                float:'right',
                                // position:'absolute',
                                // zIndex:2,
                                // top:5,
                                // right:5
                            }}>
                            <CloseIcon
                                style={{
                                    cursor: 'pointer',
                                    fontSize: '14px'
                                }}
                                onClick={handleClose} />
                        </span>
                        </div>
                    </div>
                    <div style={{ paddingTop: '15px' }} />
                    <a
                        href='https://www.nearshopz.com/invite'
                        style={{
                            cursor: 'pointer',
                            background: '#89C74A',
                            color: '#fff',
                            fontSize: '16px',
                            fontWeight: 600,
                            textAlign: 'center',
                            borderRadius: '14px',
                            height: '40px',
                            width: '100%'
                            , display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        {'Continue in App'}
                    </a>
                    {/* <div style={{paddingTop:'15px'}}/> */}
                    {/* <div 
                onClick={handleClose}
                style={{
                    cursor:'pointer',
                    background:'#fff',
                    color:'#89C74A',
                    fontSize:'14px',
                    fontWeight:600,
                    textAlign:'center',
                    borderRadius:'14px',
                    border:'1px solid #89C74A',
                    height:'40px',
                    width:'70%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'}}>
                    {'Continue in browser'}
                </div> */}
                </Box>
            </Drawer>
        </>
    );
}
