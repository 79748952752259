import { CircularProgress } from '@mui/material';
import React from 'react';
import AddPhoneNumber from '../CheckoutComponents/Contact/AddPhoneNumber';
import { useStyles } from './style';

const PhoneNumber = (props) => {
    const classes = useStyles();
    React.useEffect(() => {
        props.props.getCheckoutPhoneNumber(localStorage.getItem('userid'));
    },
        // eslint-disable-next-line
        [])
    return (
        <>
            {props.props.isCheckoutPhoneNumberLoading &&
                props.props.isCheckoutPhoneNumberLoading === true ?
                <CircularProgress /> : props.props.isCheckoutPhoneNumber &&
                    props.props.isCheckoutPhoneNumber === true ? (
                    props.props.placeorderphonenumber !== null ?
                        <div type='text' className={classes.valueDiv}>{props.props.placeorderphonenumber}</div>
                        :
                        <AddPhoneNumber props={props} />
                )
                    :
                    props.props.isCheckoutPhoneNumberFailed &&
                    props.props.isCheckoutPhoneNumberFailed === true &&
                    'OOPS'
            }
        </>
    )
}

export default PhoneNumber