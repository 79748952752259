import React from "react";
import client from "../../Configurations/apollo";
import { PAY_NOW, VERIFY_PAYMENT } from "./Queries";
import Axios from "axios";
import { useStyles } from "./style";
const PayNow = ({ id, bill }, props) => {
  const [paid,setPaid] = React.useState(false);
  const classes = useStyles();
 
  const paymentHandler = async (id) => {
    const API_URL = "https://www.nearshopz.com/";
    client
      .mutate({
        mutation: PAY_NOW,
        variables: {
          orderid: `${id}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.payNow && res.data.payNow !== null) {
          const options = {
            key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
            amount: 100,
            currency: "INR",
            name: "NearShopz",
            description: "Grocery delivery from shops near you!",
            order_id: res.data.payNow.id && res.data.payNow.id,
            handler: async (response) => {
              try {
                const paymentId = response.razorpay_payment_id;
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
                client
                  .mutate({
                    mutation: VERIFY_PAYMENT,
                    variables: {
                      orderid: `${id}`,
                      razorpayOrderid: `${response.razorpay_order_id}`,
                      paymentid: `${response.razorpay_payment_id}`,
                      signature: `${response.razorpay_signature}`,
                    },
                  })
                  .then((res) => {
                    setPaid(true);
                    client.reFetchObservableQueries();
                  })
                  .catch((err) => {
                    alert(err);
                  });
                
                const url = `${API_URL}capture/${paymentId}`;
                const captureResponse = await Axios.post(url, {});
                
              } catch (err) {
                console.log(err);
              }
            },
            theme: {
              color: "#89C74A",
            },
          };
          const rzp1 = new window.Razorpay(options);
          rzp1.open();
        } else if (res.errors) {
          alert(res.errors[0].message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <div>
      {
      <button
        className={paid===true?classes.disabledpaynow:classes.paynow}
        disabled={paid===true?true:false}
        onClick={() => paymentHandler(id)}
      >
       { paid===true?"Paid":<b>Pay ₹ {parseFloat(bill).toFixed(2)}</b>}
      </button>
}
    </div>
  );
};
export default PayNow;
