import * as React from 'react';
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Notifications from '../Notifications';
import Account from '../Account';
import Cart from '../Cart/index';
import Location from '../Location';
import StoreSearch from '../Search/Storesearch';
import logo from '../../Assets/Images/WhiteLogo.svg';
import ProductSearch from '../Search/ProductSearch';
import { Drawer, List, ListItem, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import io from 'socket.io-client';
import './index.css';

export default function PrimarySearchAppBar(props) {
  const location = useLocation();
  const desktop = useMediaQuery(useTheme().breakpoints.up('md'));
  const [state, setState] = React.useState({
    bottom: false
  });

  const [socket, setSocket] = React.useState(null);

  React.useEffect(() => {
    const newSocket = io(`${process.env.REACT_APP_SOCKET_URL}`);
    setSocket(newSocket);
    return () => newSocket.close();
  }, [setSocket]);

  const toggleDrawer = (anchor, value) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: value });

  };

  const routoToHome = () => {
    props.navigate('/stores')
  };

  React.useEffect(() => {
    props.getLiveOrdersLength(localStorage.getItem('userid'));
  },
    // eslint-disable-next-line
    []);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" 
        sx={{
          background: 'linear-gradient(180deg, #85BE49 0%, #69A85C 100%)', 
          boxShadow: 'none',
          paddingLeft: { xs: '0px', sm: '20px', md: '90px', lg: '90px' },
          paddingRight: { xs: '0px', md: '30px' }
        }}>
          <Toolbar>
            <Box 
            sx={{ 
              display: { xs: 'none', sm: 'flex' }, 
              paddingRight: { sm: '25px', xs: 0 }, 
              justifyContent: location.pathname==='/Search'?'flex-start':'center', 
              alignItems: 'center', 
              width: '100%',
              }}>
              <img src={logo} alt="NearShopz-Logo"
                onClick={() => routoToHome()}
                style={{ 
                  width: '206px', 
                  height: '36px',
                  cursor: 'pointer', 
                  marginLeft:location.pathname==='/Search'&&'110px',
                  marginRight:location.pathname==='/stores'&&'60px',
                // marginLeft:"140px"
                  }} />
            </Box>
            <Box 
            sx={{
              display: { xs: 'flex', sm: 'none' }, 
              paddingRight: { sm: '25px', xs: 0 },
              justifyContent: 'center', 
              alignItems: 'center', 
              width: '100%'
            }}>
              <img src={logo} alt="NearShopz-Logo"
                onClick={() => routoToHome()}
                style={{ 
                  width: '206px', 
                  height: '36px', 
                  cursor: 'pointer' 
                  }} />
            </Box>
            {desktop ?
              ((location.pathname === '/stores' || location.pathname === '/stores/Account/Addresses'
                || location.pathname === '/stores/Account/MyOrders') ?
                <StoreSearch {...props} />
                :location.pathname==='/Search'? null:
                <ProductSearch {...props} />)
              : null
            }
            <Box sx={{ flexGrow: 1 }} />
            <Box 
            sx={{ 
              display: { 
                xs: 'flex', 
                sm: 'flex', 
                md: 'flex' }, 
                paddingLeft: '15px' 
              }}
              >
              <Location
                componentkey='navbar'
                {...props} />
              <div style={{ padding: '15px' }}>
                <Notifications props={props} socket={socket} />
              </div>
              <div style={{ padding: '15px', width: 'auto' }}>
                <Account {...props} socket={socket} />
              </div>
              <div style={{ padding: '15px' }}>
                <Cart {...props} />
              </div>
            </Box>
          </Toolbar>
          <Toolbar sx={{ display: { xs: 'flex', md: 'none' } }}>
            {(location.pathname === '/stores' || location.pathname === '/stores/Account/Addresses'
              || location.pathname === '/stores/Account/MyOrders') ?
              <StoreSearch {...props} />
              :
              <ProductSearch {...props} />
            }
          </Toolbar>
        </AppBar>
      </Box>
      <div className='child-div'>
        {props.children}
        <Drawer
          anchor={'bottom'}
          open={state.bottom}
          onClose={toggleDrawer('bottom', false)}
          sx={{
            '& .MuiDrawer-paper':
            {
              boxSizing: 'border-box',
              width: '100%',
              background: 'linear-gradient(180deg, #85BE49 0%, #69A85C 100%)',
              borderTopLeftRadius: '13px',
              borderTopRightRadius: '13px',
              justifyContent: 'center',
              alignItems: 'center'
            },
          }}
        >
          <div
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              paddingTop: '15px',
              paddingBottom: '15px'
            }}>
            <span 
            style={{ 
              width: '100%', 
              float: 'right', 
              textAlign: 'right', 
              cursor: 'pointer', 
              color: '#fff', 
              paddingRight: '20px' 
            }}
              onClick={toggleDrawer('bottom', false)}>
              <CloseIcon />
            </span>
          </div>
          <List>
            <ListItem 
            style={{ 
              textAlign: 'center', 
              paddingBottom: '15px', 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              width: '100%'
               }}>
              <Cart {...props} />
            </ListItem>
            <ListItem 
            style={{ 
              textAlign: 'center', 
              paddingBottom: '15px', 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center',
              width: '100%' 
               }}>
              <Account {...props} />
            </ListItem>
            <ListItem 
            style={{ 
              textAlign: 'center', 
              paddingBottom: '15px', 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              width: '100%'
               }}>
              <Notifications props={props} socket={socket} />
            </ListItem>
          </List>
        </Drawer>
      </div>

      <AppBar position="fixed" sx={{
        display: { md: 'none', xs: 'flex', sm: 'none' },
        background: 'linear-gradient(180deg, #85BE49 0%, #69A85C 100%)', boxShadow: 'none',
        paddingLeft: { xs: '0px', sm: '20px', md: '90px', lg: '90px' },
        paddingRight: { xs: '0px', md: '30px' },
        bottom: 0,
        top: 'auto',
      }}>
        <Toolbar>
          <Location
            componentkey='navbar/xs'
            {...props} />
        </Toolbar>
        <Toolbar>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <div style={{ paddingRight: '15px', paddingTop: '15px', paddingBottom: '15px' }}>
              <Notifications props={props} socket={socket} />
            </div>
            <div style={{ padding: '15px', width: 'auto' }}>
              <Account {...props} />
            </div>
            <div style={{ padding: '15px' }}>
              <Cart {...props} />
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
