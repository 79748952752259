import React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

const SelectOption = (props) => {
    const [state, setState] = React.useState({
        refundoption: false,
        replacementoption: false,
    });
    const handleRefundChange = (event) => {
        setState({
            refundoption: event.target.checked,
        });
        props.selectOption(event.target.checked, false);
    };
    const handleReplacementChange = (event) => {
        setState({
            replacementoption: event.target.checked,
        });
        props.selectOption(false, event.target.checked);
    };
    const { refundoption, replacementption } = state;
    const error = [refundoption, replacementption].filter((v) => v).length !== 1;
    return (
        <Box sx={{ display: 'flex' }}>
            <FormControl required
                error={error}
                component="fieldset" sx={{ m: 3 }} variant="standard">
                <FormGroup>
                    {props.refund === true && 
                     props.orderdetailssuccess && props.orderdetailssuccess === true &&
                     props.OrderDetails && props.OrderDetails !== null &&
                     props.OrderDetails.paymentStatus === 2 &&
                        <FormControlLabel
                            control={
                                <Checkbox checked={state.refundoption} onChange={handleRefundChange} name="refundoption" />
                            }
                            label="Refund"
                        />
                    }
                    {props.replacement && props.replacement === true &&
                        <FormControlLabel
                            control={
                                <Checkbox checked={state.replacementoption} onChange={handleReplacementChange} name="replacementoption" />
                            }
                            label="Replacement"
                        />}
                </FormGroup>
            </FormControl>
        </Box>
    )
}

export default SelectOption
