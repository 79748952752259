import React from 'react';
import { useFormik } from "formik";
import { fields, mobilesigninfields, mobilesigninvalidationSchema, validationSchema } from './utils';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Dialog, DialogContent, DialogTitle, InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material';
import ForgotPasswordForm from './ForgotPasswordForm';
import OTPForm from './OTPForm';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../Assets/Logos/logo.webp';
import './style.css';

const LoginForm = (props) => {
  const [mobileSignin, setMobileSignin] = React.useState(false);
  const [passwordShown, setPasswordShown] = React.useState(false);
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
  // eslint-disable-next-line 
  const [forgotopen, setForgotOpen] = React.useState(false);

  const handleClose = () => {
    props.closesnackbars();
    props.otpclose();
  };
  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  }
  const setEmailClick = () => {
    setMobileSignin(false);
  };
  const setMobileClick = () => {
    setMobileSignin(true);
  };
  React.useEffect(() => {
    setMobileSignin(false);
  }, [])
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.getLogin(values);
    },
  });
  const mobileformik = useFormik({
    initialValues: {
      // firstName: "",
      // lastName: "",
      phoneNumber: "",
    },
    validationSchema: mobilesigninvalidationSchema,
    onSubmit: (values) => {
      props.sendloginOTP(values.phoneNumber)
    },
  });

  return (
    <>
      {mobileSignin === true ?
        <div className='login-form' >
          <div className='login-form-div'>
            <form onSubmit={mobileformik.handleSubmit}>
              {fullScreen?
              <div className='smallscreen-logo-div'>
              <img src={logo} 
              className="smallscreen-logo"
              alt={'NearShopz-Logo'}/>
              </div>
              :null}
              <p className="form-heading">
                Login in with mobile number
              </p>
              {mobilesigninfields.map(field =>
                <div className='form-section'>
                  <div className="field">
                    <div className="input-label-div">
                      <label className="input-label" >{field.label}</label>
                    </div>
                    <div className='centerDiv'>
                    <TextField
                    fullWidth
                      variant='standard'
                      type={field.type}
                      required
                      name={field.name}
                      autoFocus={true}
                      value={mobileformik.values[field.name]}
                      onChange={mobileformik.handleChange}
                      error={mobileformik.touched[field.name] && Boolean(mobileformik.errors[field.name])}
                      helperText={mobileformik.touched[field.name] && mobileformik.errors[field.name]} />
                      </div>
                  </div>
                </div>
              )}
              <div className="pass-link">
              </div>
              <div className='solid-btn-div'>
              <button type="submit" className='solid-button'>
                Send OTP
              </button>
              </div>
            </form>
          </div>
          <div className='form-second-half'>
            <button className='outlined-button' onClick={() => setEmailClick()}>
              Login with email
            </button>
          </div>
        </div>
        :
        <div className='login-form'>
          <div className='login-form-div'>
            <form onSubmit={formik.handleSubmit} autocomplete="off">
            {fullScreen?
              <div className='smallscreen-logo-div'>
              <img src={logo} 
              className="smallscreen-logo"
              alt={'NearShopz-Logo'}/>
              </div>
              :null}
              <p className="form-heading">
                Log-in now with your username & password to get started with your order.
              </p>
              {fields.map((field, index) =>
                <div className='form-section'>
                  <div className="field">
                    <div className="input-label-div">
                      <label className="input-label" >{field.label}</label>
                    </div>
                    <div className='centerDiv'>
                      <TextField
                        fullWidth
                        autoComplete='new-password'
                        variant='standard'
                        autoFocus={field.name === 'email' ? true : false}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="start"> {field.type === 'password' ?
                          (passwordShown ? <button className='EyeButton'
                            type="button" onClick={togglePassword}><RemoveRedEyeIcon /></button>
                            :
                            <button className='EyeButton'
                              type="button"
                              onClick={togglePassword}><VisibilityOffIcon /></button>
                          )
                          : null}</InputAdornment>,
                        }}
                        type={field.type === 'password' ? (passwordShown ? "text" : "password") : field.type}
                        name={field.name}
                        value={formik.values[field.name]}
                        onChange={formik.handleChange}
                        error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                        helperText={formik.touched[field.name] && formik.errors[field.name]}
                      />
                     
                    </div>
                  </div>
                </div>
              )}
              <div className="forgotpass-link" >
                <span>
                  <ForgotPasswordForm
                    {...props} />
                </span>
              </div>
              <div className='solid-btn-div'>
              <button type="submit" className='solid-button'>
                Login
              </button>
              </div>
            </form>
          </div>
          <div className='form-second-half'>
            <button className='outlined-button' onClick={() => setMobileClick()}>
              Login with Mobile Number
            </button>
          </div>
        </div>
      }
      <Dialog
        PaperProps={{
          style: {
            borderRadius: '10px',
            backgroundColor: '#ffffff',
            width: '100%',
            padding: '0px',
            margin: 0
          }
        }}
        maxWidth={'sm'}
        open={props.isOTP}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle 
        style={{ 
          textAlign: 'center' 
          }}>
          <span
            style=
            {{
              float: 'left'
            }}>
            {"Verify your phone number"}
          </span>
          <span
            style={{
              width: '25px', float: 'right',
              color: '#ffffff',
              borderRadius: '50%',
              fontSize: '14px',
              height: '25px',
              background: '#BDBDBD',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center'
            }}>
            <CloseIcon
              style={{
                cursor: 'pointer',
                fontSize: '14px'
              }}
              onClick={handleClose} />
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <OTPForm
            phoneNumber={mobileformik.values.phoneNumber}
            {...props} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default LoginForm