// MatchingResults.js
import React from "react";
import { ReactComponent as RightArrow } from "../../Assets/Icons/rightarrow.svg";

const MatchingResults = ({ matchingProductList,setSeletcedProductname,props }) => {
  
  const routeToStore = (e, id,storeLogo,Storename,storeStatus,selectedProductId,productname,image,productprice,promoprice,quantity,uom,bxgy) => {
    
    e.persist();
    props.getDepartments(id);
    localStorage.setItem("storeLogo",storeLogo);
    localStorage.setItem('storeid', id);
    localStorage.setItem("storeName", Storename);
    localStorage.setItem("businessType", props.businessType);
    localStorage.setItem("storelocation", props.storelocation);
    props.navigate(`/stores/categories`, { 
      state: { 
          productId: selectedProductId,
          productname:productname,
          image:image,
          price:productprice,
          promoprice:promoprice,
          storeId: id,
          storename:localStorage.getItem("storeName"),
          uom:uom,
          quantity:quantity,
          
            bxgy:bxgy
              ? {
                  active: bxgy.active || null,
                  xvalue: bxgy.xvalue || null,
                  yvalue: bxgy.yvalue || null
                }
              : null
          
      } 
  });
    
  }
  
  
  return (
    <div className="matching_result_conatiner" id="matchingResults">
      <p className="matching-reslt-head">{`Matching Results For  "${setSeletcedProductname}"`} </p>
      <div  className="matching_result_wrapper">
          {matchingProductList?.items.map((item) => {
            
        return item?.products.map((product) => {
          return (
            <div key={product.id} className="store_conatiner">
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p className="store_name">{item?._id?.storeName}</p>
                  <div>
                    <RightArrow
                      className="wid-100"
                      onClick={(e) =>
                        routeToStore(
                          e,
                          item?._id?.id,
                          item?._id?.storeLogoUrl,
                          item?._id?.storeName,
                          item?._id?.storeStatus,
                          product.id,
                          product.productname,
                          product?.image?.primary,
                          product.price,   
                          product.promoprice,
                          product.quantity,
                          product.uom,
                          product?.bxgy || {}
                        )
                      }
                    />
                    {console.log("this is slected product image",product?.image?.primary  )}
                  </div>
                </div>
                <div className="product_details_div">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <div
                      style={{
                        width: "30%",
                        height: "100%",
                      }}
                    >
                      {" "}
                      <img
                        src={product?.image?.primary}
                        alt="product-img"
                        style={{
                          height: "60px",
                          objectFit: "contain",
                        }}
                      ></img>
                    </div>
                    <div>
                      <p className="product_name_prop">
                        {product?.productname}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p>{`₹ ${product?.price}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
      })}
      </div>
    </div>
  );
};

export default MatchingResults;
