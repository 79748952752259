import React, { useEffect, useState } from "react";
import { Grid, SvgIcon } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useStyles } from "../../Cart/style.js";
import {CHECK_MIN_AMOUNT} from "../../../Redux/Actions/Queries.js"
import client from "../../../Configurations/apollo.js";
import AddButton from "../../AddButton";
import removestore from "../../../Assets/Images/removestore.svg";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AlertBar from "../../AlertBar/index.js";
import { db } from "../../db.js";
import "./PaymentDetails.css";
import boxCircle from '../../../Assets/Images/boxCircle.png'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Tooltip,
} from "@mui/material";
import { ReactComponent as PlusIcon } from "../../../Assets/Icons/plusicon.svg";
import { useLiveQuery } from "dexie-react-hooks";
import EmptyCart from "../CartEmptyPage.js";

const ItemDetails = (props) => {
  const classes = useStyles();
  const [prevCart, setPrevCart] = useState(props.cart);
  const [deleteCart, setOpenDeleteCart] = useState(false);
  const [state, setState] = React.useState({ CheckAmount: []});
  const [checkopen, setCheckOpen] = React.useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const { cart } = db;
      const cartdata = await cart.toArray();
    };
    fetchData();
  }, [props.isCartSuccess]);

  const Add_More = () => {
    props.navigate(`/stores/categories`);
  };

  // useEffect(() => {
  //   if (prevCart !== props.cart) {
  //     props?.updatefullcart();
  //     setPrevCart(props.cart);
  //   }
  // }, [props.cart]); // Rerender only when the cart items change
  const handleStoreClose = () => {
    setOpenDeleteCart(false);
  };

  const removestorefromcart = async (storeid) => {
    await db.cart.delete(storeid);
    setOpenDeleteCart(false);
    props.navigate("/stores");
  };

  const handleIncreaseQuantity = async (userid, storeid, productId) => {
    const { Cart, quantityChanges } = props;

    const foundProduct = Cart.products.find(
      (product) => product?.productid?.id === productId
    );

    if (foundProduct) {
      foundProduct.quantity++;
      let quantity = foundProduct.quantity;
      let price = parseFloat(
        (foundProduct.quantity * parseFloat(foundProduct.productPrice)).toFixed(
          2
        )
      );
      let productprice = foundProduct.productPrice;
      const cartDataInDb = await db.cart.toArray();

      const filteredItem = cartDataInDb[0].products.find((item) => {
        return item.productid === productId;
      });
      filteredItem.cartquantity++;
      
      try {
        await db.cart.put(cartDataInDb[0]);
       
      } catch (error) {
        console.error("Error updating database:", error);
      }

      quantityChanges(
        storeid,
        userid,
        productId,
        productprice,
        quantity,
        price
      );
    }
  };

  const handleDecrementQunatity = async (userid, storeid, productId) => {
    const { Cart, quantityChanges } = props;
    const cartDataInDb = await db.cart.toArray();


    client
      .query({
        query: CHECK_MIN_AMOUNT,
      })
      .then(async (res) => {
        if (
          res.data &&
          res.data.checkMinAmount &&
          !res.data.checkMinAmount.length
        ) {

      
          const filteredItem = cartDataInDb[0].products.find((item) => {
            return item.productid === productId;
          });
          const clickedProduct = Cart.products.find(
            (product) => product?.productid?.id === productId
          );
      
          if (clickedProduct) {
            console.log("CHECK_MIN_AMOUNT clicked",clickedProduct)
            if (clickedProduct.quantity > 0) {
              
              // Decrease the quantity of the clicked product
              clickedProduct.quantity--;
      
              // Update price based on the new quantity
              let quantity = clickedProduct.quantity;
              let price = parseFloat(
                (quantity * parseFloat(clickedProduct.productPrice)).toFixed(2)
              );
      
              // Update cart in the database
              const cartDataInDb = await db.cart.toArray();
              const filteredItemIndex = cartDataInDb[0].products.findIndex((item) => {
                return item.productid === productId;
              });
             
      
              // If the product is found in the cart, update its quantity
              if (filteredItemIndex !== -1) {
                cartDataInDb[0].products[filteredItemIndex].cartquantity--;
                
                // Update quantity and price on the backend
                if (cartDataInDb[0].products[filteredItemIndex].cartquantity < 1) {
                  cartDataInDb[0].products.splice(filteredItemIndex, 1);
                  if (cartDataInDb[0].products.length === 0) {
                    quantityChanges(
                      storeid,
                      userid,
                      productId,
                      clickedProduct.productPrice,
                      quantity,
                      price
                    );
      
                    // If the cart has only one product, delete the store from the database
                    try {
                      await db.cart.delete(storeid);
                      setOpenDeleteCart(true);
                      // props.navigate(`/`);
                      
                      return; // Exit the function after deleting the store
                    } catch (error) {
                      console.error("Error deleting store:", error);
                    }
      
                    return;
                  }
                }
                // Update the database
                try {
                  await db.cart.put(cartDataInDb[0]);
                 
                } catch (error) {
                  console.error("Error updating database:", error);
                }
                quantityChanges(
                  storeid,
                  userid,
                  productId,
                  clickedProduct.productPrice,
                  quantity,
                  price
                );
              }
            } else {
              // If the quantity is zero, remove the product from the cartDataInDb
              const cartDataInDb = await db.cart.toArray();
              const filteredItemIndex = cartDataInDb[0].products.findIndex((item) => {
                return item.productid === productId;
              });
      
             
              // If the product is found in the cart, remove it
              if (filteredItemIndex !== -1) {
                cartDataInDb[0].products.splice(filteredItemIndex, 1);
      
               
                // Update the database after removing the product
      
                if (cartDataInDb[0].products.length === 1) {
                  
                  // If the cart has only one product, delete the store from the database
                  try {
                    await db.cart.delete(storeid);
                    setOpenDeleteCart(true);
                   
                    
                    return; // Exit the function after deleting the store
                  } catch (error) {
                    console.error("Error deleting store:", error);
                  }
      
                  return;
                }
      
                try {
                  await db.cart.put(cartDataInDb[0]);
                 
                } catch (error) {
                  console.error("Error updating database:", error);
                }
              }
            }
          }
          
        } else if (
          res.data &&
          res.data.checkMinAmount &&
          res.data.checkMinAmount.length &&
          res.data.checkMinAmount.length > 0
        ) {
          setCheckOpen(true);
          setState({
            ...state,
            CheckAmount: res.data.checkMinAmount,
          });
          setTimeout(() => {
            setCheckOpen(false);
          }, 3000);
        } else if (res.errors) {
          console.log(res.errors[0].message);
        }
      })
      .catch((err) => {
        console.log(err);
      });

  };

  return (
    <>
      <Grid container style={{ display: "flex", alignItems: "center" }}>
        <Grid
          item
          xs={0.5}
          sm={0.5}
          md={0.5}
          style={{ display: "flex", alignItems: "center" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
          >
            <path
              d="M5.99984 16.0002C6.73622 16.0002 7.33317 15.4032 7.33317 14.6668C7.33317 13.9304 6.73622 13.3335 5.99984 13.3335C5.26346 13.3335 4.6665 13.9304 4.6665 14.6668C4.6665 15.4032 5.26346 16.0002 5.99984 16.0002Z"
              fill="black"
            />
            <path
              d="M15.3333 16.0002C16.0697 16.0002 16.6667 15.4032 16.6667 14.6668C16.6667 13.9304 16.0697 13.3335 15.3333 13.3335C14.597 13.3335 14 13.9304 14 14.6668C14 15.4032 14.597 16.0002 15.3333 16.0002Z"
              fill="black"
            />
            <path
              d="M17.7 2.36583C17.6063 2.25127 17.4883 2.159 17.3546 2.09572C17.2208 2.03244 17.0746 1.99975 16.9267 2H4.24542L3.99 0.550833C3.96276 0.39647 3.88201 0.25664 3.76192 0.155903C3.64183 0.0551668 3.49008 -3.29339e-05 3.33333 1.47418e-08H0.666667C0.489856 1.47418e-08 0.320286 0.070238 0.195262 0.195262C0.0702379 0.320287 0 0.489856 0 0.666667C0 0.843478 0.0702379 1.01305 0.195262 1.13807C0.320286 1.2631 0.489856 1.33333 0.666667 1.33333H2.77417L4.67667 12.1158C4.7039 12.2702 4.78466 12.41 4.90475 12.5108C5.02484 12.6115 5.17659 12.6667 5.33333 12.6667H16C16.1768 12.6667 16.3464 12.5964 16.4714 12.4714C16.5964 12.3464 16.6667 12.1768 16.6667 12C16.6667 11.8232 16.5964 11.6536 16.4714 11.5286C16.3464 11.4036 16.1768 11.3333 16 11.3333H5.8925L5.6575 10H15.7267C15.9579 9.99971 16.1819 9.91954 16.3607 9.77308C16.5396 9.62661 16.6624 9.42284 16.7083 9.19625L17.9083 3.19625C17.9372 3.05104 17.9336 2.90123 17.8975 2.75762C17.8615 2.61401 17.794 2.4802 17.7 2.36583Z"
              fill="black"
            />
          </svg>
        </Grid>
        <Grid
          style={{ display: "flex", alignItems: "center", paddingLeft: "12px" }}
          item
          xs={10.5}
          sm={10.5}
          md={10.5}
        >
          <h2 style={{ fontSize: "18px", fontWeight: 700, color: "#000" }}>
            {"Item Details"}
          </h2>
        </Grid>
      </Grid>
      {props?.Cart?.products?.map((product) => (
        <Grid
          container
          style={{ display: "flex", alignItems: "center", paddingTop: "20px" }}
          key={product.id}
        >
          <Grid item xs={4} style={{ display: "flex", alignItems: "center",gap:"20px"}}>
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <rect x="0.5" y="0.5" width="15" height="15" stroke="#13801C" />
              <circle cx="8" cy="8" r="4.375" fill="#13801C" />
            </svg> */}
            <img src={boxCircle} alt="no image" width="16px" height="16px"/>
            <span
              style={{
               // paddingLeft: "13px",
                color: "#061303",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              {product?.productid?.productname}
            </span>
          </Grid>
          <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
            <ButtonGroup
              variant="text"
              style={{
                width: "100%",
                height: "34px",
                userSelect: "none",
                borderRadius: "23px",
                background: "rgb(234, 246, 225)",
                border: "none",
                fontSize: "12px",
              }}
              aria-label="outlined button group"
            >
              <Button
                style={{
                  background: " rgb(255, 255, 255)",
                  borderRight: "1px #00000008",
                  width: "28px",
                  height: "34px",
                  borderRadius: "24px",
                  color: "#000000",
                  userSelect: "none",
                }}
                onClick={() => {
                  const userId = props?.Cart?.userid?.id;
                  const storeId = props?.Cart?.storeid?.id;
                  const clickedProductId = product?.productid?.id;
                  handleDecrementQunatity(userId, storeId, clickedProductId);
                }}
              >
                -
              </Button>

              <Button
                style={{
                  border: "none",
                  color: "#000000",
                  fontSize: "12px",
                  width: "100%",
                  userSelect: "none",
                }}
              >
                {product.quantity}
              </Button>
              <Button
                style={{
                  background: "rgba(133, 190, 73, 1)",
                  border: "none",
                  width: "28px",
                  height: "34px",
                  borderRadius: "14px",
                  color: "#ffffff",
                  userSelect: "none",
                  "&:hover": {
                    background: "rgba(133, 190, 73, 1)",
                  },
                }}
                onClick={() => {
                  const userId = props?.Cart?.userid?.id;
                  const storeId = props?.Cart?.storeid?.id;
                  const clickedProductId = product?.productid?.id;
                  handleIncreaseQuantity(userId, storeId, clickedProductId);
                }}
              >
                +
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid
            item
            xs={5}
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "right",
              justifyContent: "flex-end",
              color: "#061303",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            {`₹ ${product?.price} `}
          </Grid>
        </Grid>
      ))}
      <div
        style={{
          paddingTop: "20px",
          boxSizing: "border-box",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          borderRadius: "2px 0px 0px 0px",
          opacity: " 0px",
          cursor: "pointer",
        }}
        onClick={() => {
          Add_More();
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "3px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(133, 190, 73, 1)",
              border: "none",
              width: "16px",
              height: "16px",
              borderRadius: "2px",
              color: "#ffffff",
              userSelect: "none",
              "&:hover": {
                background: "rgba(133, 190, 73, 1)",
              },
            }}
            onClick={() => {
              const userId = props?.Cart?.userid?.id;
            }}
          >
            <span
              style={{
                textAlign: "center",
              }}
            >
              +
            </span>
          </div>
          <p
            style={{
              textDecoration: "underline",
            }}
          >
            Add More
          </p>
        </div>
      </div>

      {deleteCart && (
        <Dialog
          PaperProps={{
            style: {
              borderRadius: "13px",
              backgroundColor: "#ffffff",
              width: "450px",
              padding: "25px",
            },
          }}
          
          maxWidth={"md"}
          open={deleteCart}
          // onClose={handleStoreClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <img src={removestore} alt={"Delete STORE"} />
            <div
              style={{
                color: "#000000",
                fontSize: "18px",
                fontWeight: 600,
                paddingTop: "20px",
                fontFamily: "Nunito",
              }}
            >
              {`You will Be Redirected to Home`}
            </div>
            <div
              style={{
                color: "rgba(89, 98, 106, 0.8)",
                fontSize: "14px",
                lineHeight: "19px",
                paddingTop: "10px",
                fontFamily: "Nunito",
              }}
            >
              {`This will remove all items from this Store`}
            </div>
          </DialogTitle>
          <DialogContent
            style={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "80%",
                marginTop: "15px",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                paddingTop: "8px",
                display: "flex",
              }}
            >
              <button
                className={classes.button}
                onClick={() =>
                  removestorefromcart(localStorage.getItem("storeid"))
                }
              >
                {"OK"}
              </button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {checkopen && (
            <AlertBar
              props={props}
              type={"error"}
              message={
                state.CheckAmount &&
                state.CheckAmount.length &&
                state.CheckAmount.length > 0 &&
                state.CheckAmount.map((msg) => msg)
              }
              {...props}
            />
          )}
    </>
  );
};

export default ItemDetails;
