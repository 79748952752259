import React from 'react';
import './style.css';

const CategoryCarousel = (props) => {

    const [open, setOpen] = React.useState(false);
    const [categoryopen, setCategoryOpen] = React.useState(false);
    const [category1open, setCategory1Open] = React.useState(false);
    const setCategoryId = (id) => {
        localStorage.setItem("categoryid", id);
        props.props.getCategoryProducts({
            storeid: localStorage.getItem('storeid'),
            categoryid: localStorage.getItem('categoryid'),
            userid: localStorage.getItem('userid')
        });
        props.navigate(`/stores/categories/categoryproducts`);
    };
    const setNoCategoryId = (id) => {
        localStorage.setItem("categoryid", id);
        setCategoryOpen(!open);
        props.props.getCategory1list({ storeid: localStorage.getItem('storeid'), categoryid: localStorage.getItem("categoryid"), userid: localStorage.getItem('userid') });
        props.navigate(`/stores/categories/category1`);
    };
    React.useEffect(() => {
        if (!props.props.isDepartments) {
            if (props.params.storeid)
                props.props.getDepartments(props.params.storeid);
            else
                props.props.getDepartments(localStorage.getItem('storeid'));
        }
    },
        // eslint-disable-next-line
        [])
    return (
        <>
            <div className='category-list-container'>
                <div className='horizontal-scroll-list'>
                    <div className='category-container-div'>
                        {props.props.isCategories && props.props.isCategories === true &&
                            props.props.Categories && props.props.Categories !== null && props.props.Categories.length > 0 &&
                            props.props.Categories.map(({ name, id, childCategoryPresent, categoryProducts }) =>
                                categoryProducts.items.length > 0 &&
                                    childCategoryPresent === false ? (
                                    <div className='category-padding'>
                                        <div className='category-list-div' onClick={() => {
                                            setCategoryId(id);
                                        }}>
                                            <div className='category-name-div'>
                                                {name}
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    (<>
                                        <div className='category-padding'>
                                            <div className='category-list-div' onClick={() => setNoCategoryId(id)}>
                                                <div className='category-name-div'>
                                                    {name}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoryCarousel