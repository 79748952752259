import React from "react";
import * as Yup from "yup";
import EmailIcon from '@mui/icons-material/Email';
import HttpsIcon from '@mui/icons-material/Https';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

export const fieldState = {
  userName: "",
  password: "",
};
export const fields = [
  {
    id: "1",
    label: "E-mail Address",
    type: "email",
    name: "email",
    key: "email",
    icon: <EmailIcon />
  },
  {
    id: "2",
    label: "Password",
    type: "password",
    name: "password",
    key: "password",
    icon: <HttpsIcon />
  },
];

export const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Please Enter Your Email Address"),
  password: Yup.string()
    .trim("Space Not Allowed")
    .required("Please Enter Your Password")
    .min(6, "Password is too short - should be 6 chars minimum"),
});

export const mobilevalidationSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .matches(/^[0-9]*$/, "Please enter only numbers")
    .min(10, "Please enter 10 digit Mobile Number")
    .max(10, "Please enter 10 digit Mobile Number")
    .required("Please Enter Mobile Number"),
});

export const mobilesigninvalidationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(/^[0-9]*$/, "Please enter only numbers")
    .min(10, "Please enter 10 digit Mobile Number")
    .max(10, "Please enter 10 digit Mobile Number")
    .required("Please Enter Mobile Number"),
});
export const signupvalidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim('Space not allowed')
    .matches(/^\S+$/,"Space not allowed")
    .matches(/^[a-zA-Z]+$/, "Only alphabets are allowed")
    .strict(true)
    .max(20, "length exceeded")
    .required('Please Enter First Name'),
  lastName: Yup.string()
    .trim('Space not allowed')
    .matches(/^\S+$/,"Space not allowed")
    .matches(/^[a-zA-Z]+$/, "Only alphabets are allowed")
    .strict(true)
    .max(20, "length exceeded")
    .required('Please Enter Last Name'),
  email: Yup.string()
    .email('please enter valid email')
    .required('please Enter Your Email Address'),
  password: Yup.string()
    .trim("Space not allowed")
    .strict(true)
    .required('Password Is Required')
    /* eslint-disable no-useless-escape */
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/, 'Password must contain one number uppercase and lowercase letters and special character')
    .min(8, 'Password is too short - should be 8 characters minimum'),
});

export const resetpasswordvalidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('please enter valid email')
    .required('please Enter Your Email Address'),
});

export const mobilefieldState = {
  phoneNumber: "",
  firstName: "",
  lastName: "",
};
export const mobilefields = [
  {
    id: "1",
    key: "firstname",
    label: "First Name",
    type: "text",
    name: "firstName",
    placeholder: "Enter First Name",
  },

  {
    id: "2",
    key: "lastname",
    label: "Last Name",
    type: "text",
    name: "lastName",
    placeholder: "Enter Last Name",
  },
];
export const mobilesigninfields = [
  {
    id: "1",
    key: "phonNumber",
    label: "Phone Number",
    type: "text",
    name: "phoneNumber",
    placeholder: "Enter First Name",
  },
];
export const signupfields = [
  {
    id: "1",
    label: "First name",
    type: "text",
    name: "firstName",
    key: "firstName",
    icon: <PersonIcon />
  },
  {
    id: "2",
    label: "Last name",
    type: "text",
    name: "lastName",
    key: "lastName",
    icon: <PersonIcon />
  },
  {
    id: "3",
    label: "Email address",
    type: "email",
    name: "email",
    key: "email",
    icon: <EmailIcon />
  },
  {
    id: "4",
    label: "Password",
    type: "password",
    name: "password",
    key: "password",
    icon: <HttpsIcon />
  },
];
export const mobilesignupfields = [
  {
    id: "1",
    key: "firstname",
    label: "First Name",
    type: "text",
    name: "firstName",
    placeholder: "Enter First Name",
    icon: <PersonIcon />
  },

  {
    id: "2",
    key: "lastname",
    label: "Last Name",
    type: "text",
    name: "lastName",
    placeholder: "Enter Last Name",
    icon: <PersonIcon />
  },
  {
    id: "2",
    key: "phoneNumber",
    label: "Phone Number",
    type: "text",
    name: "phoneNumber",
    placeholder: "Enter phone Number",
    icon: <LocalPhoneIcon />
  },
];


export const mobilesignupvalidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim('Space not allowed')
    .matches(/^\S+$/,"Space not allowed")
    .matches(/^[a-zA-Z]+$/, "Only alphabets are allowed")
    .strict(true)
    .max(20, "length exceeded")
    .required('Please Enter First Name'),
  lastName: Yup.string()
    .trim('Space not allowed')
    .matches(/^\S+$/,"Space not allowed")
    .matches(/^[a-zA-Z]+$/, "Only alphabets are allowed")
    .strict(true)
    .max(20, "length exceeded")
    .required('Please Enter Last Name'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]*$/, "Please enter only numbers")
    .trim("Space not allowed")
    .strict(true)
    .min(10, "Please enter 10 digit Mobile number")
    .max(10, "Please enter 10 digit Mobile number")
    .required("Please Enter Mobile Number"),
});

