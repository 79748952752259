import * as Yup from "yup";

export const addressvalidationSchema = Yup.object().shape({
    housename: Yup.string()
    .required("Please enter housename/number")
    .max(100,"Length exceeded"),
    landmark: Yup.string()
    .max(100,"Length exceeded"),
    pincode: Yup.string().required("Please enter pincode / address"),
  });
  