import React from 'react';
import { useStyles } from "./style.js";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useFormik } from "formik";
import { addressvalidationSchema } from './utils.js';
import PinDropIcon from '@mui/icons-material/PinDrop';
import CloseIcon from '@mui/icons-material/Close';
import addaddress from '../../Assets/Images/addaddress.webp';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { Grid, InputAdornment, TextField } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import HomeIcon from '@mui/icons-material/Home';
import './index.css';

const Addaddress = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  // eslint-disable-next-line
  const [value, setValue] = React.useState(null);
  const [selectetedlocation, setSelectedLocation] = React.useState('');
  const [inputValue, setInputValue] = React.useState("");
  // eslint-disable-next-line
  const [options, setOptions] = React.useState([]);
  const fullscreen = useMediaQuery(theme.breakpoints.down('md'));
  // eslint-disable-next-line
  const [storedescription, setStoredescription] = React.useState("");
  React.useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    autocomplete(inputValue);
    if (active) {
      let newOptions = [];

      if (value) {
        newOptions = [value];
      }

      if (
        props.props.isSuggestions &&
        props.props.Suggestions
      ) {
        newOptions = [
          ...newOptions,
          ...props.props.Suggestions,
        ];
      }

      setOptions(newOptions);
    }

    return () => {
      active = false;
    };
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, inputValue]);

  const autocomplete = (e) => {
    props.props.autocomplete(e);
  };
  const handleClickOpen = () => {
    setOpen(true);
    setSelectedLocation('');
    formik.setFieldValue("");
    formik.setFieldValue("housename", '');
    formik.setFieldValue("landmark", '');
  };
  const handleClose = () => {
    setOpen(false);
  };
  const formik = useFormik({
    initialValues: {
      housename: "",
      landmark: "",
      pincode: "",
    },
    validationSchema: addressvalidationSchema,
    onSubmit: (values) => {
      props.props.addAddress(
        localStorage.getItem('userid'),
        values.housename,
        values.pincode,
        props.props.lattitude,
        props.props.longitude,
        values.landmark
      );
      setOpen(false);
      formik.touched.housename = false;
      formik.touched.landmark = false;
      formik.touched.pincode = false;
      formik.values.housename = "";
      handleClose();
      setStoredescription("");
      
    },
  });
  const selectLocation = (placeid, description) => {
    setSelectedLocation(description);
    setInputValue('');
    props.props.suggestioncordinates(placeid);
  };
  React.useEffect(() => {
    setInputValue('');
    setSelectedLocation('');
    formik.setFieldValue('pincode', '');
  },
    // eslint-disable-next-line
    [])
  return (
    <>
      <div className={props.screen === 'checkout' ? classes.addaddressCard : props.screen === 'checkout/popup' ?
        classes.addresspopup : classes.addaddressButton} onClick={handleClickOpen}>
        {props.screen === 'checkout' && <AddLocationIcon />}
        {props.screen === 'checkout' ? 'Add a new address' : 'Add Address'}
      </div>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: '10px',
            backgroundColor: '#ffffff',
            width: '100%',
          }
        }}
        fullScreen={fullscreen}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle
          className={classes.modalHeading}>
          <span
            className={classes.modalHeading}>
            {"Add a new address"}
          </span>
          <span
            style={{
              width: '25px',
              float: 'right',
              color: '#ffffff',
              borderRadius: '50%',
              fontSize: '14px',
              height: '25px',
              background: '#BDBDBD',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center'
            }}>
            <CloseIcon
              style={{
                cursor: 'pointer',
                fontSize: '14px'
              }}
              onClick={handleClose} />
          </span>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit} >
          <DialogContent>
            <div style={{ paddingBottom: '20px', width: '100%', position: 'relative', zIndex: 1 }}>
              <div
                style={{
                  width: '100%',
                  height: '45px',
                  backgroundColor: 'rgba(105, 168, 92, 0.1)',
                  borderRadius: '13px',
                  border: '1px solid rgba(0,0,0,0.1)',
                  boxShadow: '0px 8px 44px rgba(0, 0, 0, 0.04)',
                  color: 'rgba(105, 168, 92, 0.5)',
                  fontSize: '14px',
                  paddingLeft: '5px',
                  // paddingTop: '5px',
                  // paddingBottom: '5px',
                  paddingRight: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <div
                  style={{
                    backgroundColor: '#85BE49',
                    borderRadius: '9px',
                    width: '45px',
                    height: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    color: '#FFFFFF'
                  }}>
                  <PinDropIcon />
                </div>
                {selectetedlocation !== '' &&
                  props.props.isCoordinates && props.props.isCoordinates === true ?
                  <div className='location'>
                    <input
                      label="invite link"
                      placeholder={""}
                      style={{
                        background: 'transparent',
                        display: 'flex',
                        border: 'none',
                        width: '100%',
                        textAlign: 'left',
                        fontWeight: 600,
                        fontSize: '14px',
                        paddingLeft: '13px',
                        color: 'rgba(105, 168, 92, 0.8)'
                      }}
                      type="text"
                      readOnly
                    />
                    <span
                      onClick={() => { setInputValue(''); setSelectedLocation(''); formik.setFieldValue('pincode', '') }}
                      style={{
                        background: 'transparent',
                        display: 'flex',
                        border: 'none',
                        width: '100%',
                        textAlign: 'right',
                        fontWeight: 600,
                        fontSize: '14px',
                        justifyContent: 'right',
                        alignItems: 'center',
                        color: 'rgba(105, 168, 92, 0.8)',
                        cursor: 'pointer',
                      }}>
                      <ModeEditIcon />&nbsp;{'Edit Location'}
                    </span>
                  </div>
                  :
                  <div className='location'>
                    <input
                      label="invite link"
                      id="autocomplete"
                      placeholder='Search for location near you'
                      style={{
                        background: 'transparent',
                        display: 'flex',
                        border: 'none',
                        width: '100%',
                        textAlign: 'left',
                        fontWeight: 600,
                        fontSize: '14px',
                        paddingLeft: '13px',
                        color: 'rgba(105, 168, 92, 0.8)'
                      }}
                      autoComplete="off"
                      name={'pincode'}
                      onChange={(e) => {
                        setInputValue(e.target.value);
                        formik.setFieldValue('pincode', e.target.value)

                      }}
                      value={formik.values.pincode}
                      type="text"
                      className='input'
                    />
                  </div>
                }
              </div>
            </div>
            {
              selectetedlocation !== '' && props.props.isCoordinates && props.props.isCoordinates === true ?
                null :
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    fontSize: '14px',
                    textAlign: 'center',
                    alignItems: 'center',
                    paddingTop: '22px',
                    paddingBottom: '22px',
                  }}>
                  {/* <span
                  style={{
                    color: '#2D93FB',
                    fontSize: '14px',
                    cursor: 'pointer'
                  }}>
                  <MyLocationIcon />
                </span>
                <span
                  // onClick={(e)=>setInputValue(e.target.value)}
                  style={{
                    paddingLeft: '13px',
                    color: 'rgba(0,0,0,0.8)',
                    fontSize: '14px',
                    fontWeight: 700,
                    cursor: 'pointer',
                  }}>
                  {'Use current location'}
                </span> */}
                </div>
            }
            {inputValue !== '' && props.props.isSuggestions && props.props.isSuggestions === true &&
              props.props.Suggestions && props.props.Suggestions.length > 0 &&
              <div className={classes.suggestionDiv}>
                <span className={classes.suggestionsHeading}>
                  {'SEARCH RESULTS'}
                </span>
                {props.props.Suggestions.map((s) =>
                  <>
                    <Grid container alignItems="center" className={classes.suggestionList}
                      onClick={() => {
                        selectLocation(
                          s.place_id && s.place_id,
                          s.description && s.description
                        );
                        formik.setFieldValue('pincode', s.description);
                      }}>
                      <Grid item xs={1}>
                        <FmdGoodOutlinedIcon className={classes.icon} />
                      </Grid>
                      <Grid item xs={11}>
                        <span
                          style={{ fontWeight: 700, fontSize: '14px' }}
                        >
                          {s.description.split(",", 1)}
                        </span>

                        <div style={{ fontSize: '12px', color: '#BDBDBD' }}>
                          {s.description.substring(s.description.indexOf(",") + 1)}
                        </div>
                        {/* <Divider/> */}
                      </Grid>
                    </Grid>

                  </>
                )}

              </div>}

            {selectetedlocation !== '' &&
              props.props.isCoordinates && props.props.isCoordinates === true ?
              <>
                <div className={classes.Editlabel}>
                  {'Building Name / House Name'}
                </div>
                <TextField
                  fullWidth
                  inputProps={{
                    style: {
                      width: '100%',
                      background: '#ffffff',
                      border: ' 1px solid rgba(133, 190, 73, 0.19)',
                      boxSizing: 'border-box',
                      borderRadius: '8px',
                      height: '45px',
                      textAlign: 'left'
                    },
                    startAdornment: <InputAdornment position="start">
                      <HomeIcon />
                    </InputAdornment>
                  }}
                  name="housename"
                  // label="House Name/Number"
                  // defaultValue={props.props.houseName}
                  value={formik.values.housename}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.housename &&
                    Boolean(formik.errors.housename)
                  }
                  helperText={
                    formik.touched.housename &&
                    formik.errors.housename
                  } className={classes.input} />
                <div className={classes.Editlabel}>
                  {'Landmark'}
                </div>
                <TextField
                  fullWidth
                  inputProps={{
                    style: {
                      width: '100%',
                      background: '#ffffff',
                      border: ' 1px solid rgba(133, 190, 73, 0.19)',
                      boxSizing: 'border-box',
                      borderRadius: '8px',
                      height: '45px',
                      textAlign: 'left'
                    },
                  }}
                  name="landmark"
                  value={formik.values.landmark}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.landmark &&
                    Boolean(formik.errors.landmark)
                  }
                  helperText={
                    formik.touched.landmark &&
                    formik.errors.landmark
                  }
                />
                <div className='Addbutton' >
                  <button className='savebutton' type="submit">
                    Add Address
                  </button>
                </div>
              </>
              :
              <div>
                <img src={addaddress} alt='add address' />
              </div>
            }
          </DialogContent>
        </form>
      </Dialog>
    </>
  )
}

export default Addaddress