import React from 'react';
import { useFormik } from "formik";
import { mobilesignupfields, mobilesignupvalidationSchema, signupfields, signupvalidationSchema } from './utils';
import './style.css';
import { Dialog, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import logo from '../../Assets/Logos/logo.webp';
import OTPForm from './OTPForm';

const SignUpForm = (props) => {
  const [mobileSignup, setMobileSignup] = React.useState(false);
  const [passwordShown, setPasswordShown] = React.useState(false);
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));

  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  }
  const handleClose = () => {
    props.closesnackbars();
    props.otpclose();
  };
  const setEmailClick = () => {
    setMobileSignup(false);
  };
  const setMobileClick = () => {
    setMobileSignup(true);
  };
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validationSchema: signupvalidationSchema,
    onSubmit: (values) => {
      props.getSignup(values);
    },
  });
  const mobileformik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
    },
    validationSchema: mobilesignupvalidationSchema,
    onSubmit: (values) => {
      props.mobilesignupClick(
        values.firstName,
        values.lastName,
        values.phoneNumber
      );
    },
  });
  React.useEffect(() => {
    setMobileSignup(false);
    props.otpclose();
  },
  // eslint-disable-next-line 
  [])
  return (
    <>
      {mobileSignup === true ?
        <div className='login-form'>

          <div className='login-form-div' >
            <form onSubmit={mobileformik.handleSubmit}>
            {fullScreen?
              <div className='smallscreen-logo-div'>
              <img src={logo} 
              className="smallscreen-logo"
              alt={'NearShopz-Logo'}/>
              </div>
              :null}
              <p className="form-heading">
                Sign Up. Start Smart Shopping
              </p>
              {mobilesignupfields.map((field, index) =>
                <div className='form-section'>
                  <div className="field">
                    <div className="input-label-div">
                      <label className="input-label" >{field.label}</label>
                    </div>
                    <TextField
                      variant='standard'
                      autoFocus={index===0?true:false}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type={field.type}
                      name={field.name}
                      value={mobileformik.values[field.name]}
                      onChange={mobileformik.handleChange}
                      error={mobileformik.touched[field.name] && Boolean(mobileformik.errors[field.name])}
                      helperText={mobileformik.touched[field.name] && mobileformik.errors[field.name]} />
                  </div>
                </div>
              )}
              <div className="pass-link">
                By signing up, you agree to our
                &nbsp;<a href="/termsandconditions">Terms and Conditions </a> &
                <a href="/privacypolicy">&nbsp;Privacy Policy</a>
              </div>
              <div className='solid-btn-div'>
              <button type="submit"  className='solid-button'>
                Send OTP
              </button>
              </div>
            </form>
          </div>
          <div className='form-second-half'>
          {/* <form style={{width:'100%'}}> */}
            <button className='outlined-button' onClick={() => setEmailClick()}>
              Signup with email
            </button>
            {/* </form> */}
          </div>
        </div>
        :
        <div className='login-form'>
          <div className='login-form-div' >
            <form 
            onSubmit={formik.handleSubmit}>
               {fullScreen?
              <div className='smallscreen-logo-div'>
              <img src={logo} 
              className="smallscreen-logo"
              alt={'NearShopz-Logo'}/>
              </div>
              :null}
               <p className="form-heading">
                Sign Up. Start Smart Shopping
              </p>
              {signupfields.map((field, index) =>
                <div className='form-section'>
                  <div className="field">
                    <div className="input-label-div">
                      <label className="input-label" >{field.label}</label>
                    </div>
                    <div className='centerDiv'>
                      <TextField
                        variant='standard'
                        autoComplete="off"
                        autoFocus={index===0?true:false}
                        fullWidth
                        InputLabelProps={{ shrink: true , margin:'none' }}
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="start"> {field.type === 'password' ?
                          (passwordShown ? <IconButton
                            onClick={togglePassword}><RemoveRedEyeIcon /></IconButton>
                            :
                            <button className='EyeButton'
                              type="button"
                              onClick={togglePassword}><VisibilityOffIcon /></button>
                          )
                          : null}</InputAdornment>,
                        }}
                        type={field.type === 'password' ? (passwordShown ? "text" : "password") : field.type}
                        name={field.name}
                        value={formik.values[field.name]}
                        onChange={formik.handleChange}
                        error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                        helperText={formik.touched[field.name] && formik.errors[field.name]} />
                    </div>
                  </div>
                </div>
              )}
              <div className="pass-link">
                By signing up, you agree to our
                &nbsp;<a href="/termsandconditions">Terms and Conditions </a> &
                <a href="/privacypolicy">&nbsp;Privacy Policy</a>
              </div>
              <div className='solid-btn-div'>
              <button type="submit" className='solid-button'>
                Sign Up
              </button>
              </div>
            </form>
          </div>
          <div className='form-second-half'>
            <button className='outlined-button' onClick={() => setMobileClick()}>
              Signup with Mobile Number
            </button>
          </div>

        </div>
      }
      <Dialog
        PaperProps={{
          style: {
            borderRadius: '10px',
            backgroundColor: '#ffffff',
            width: '100%',
            padding: '0px',
            margin: 0
          }
        }}
        // fullScreen={'true'}
        maxWidth={'sm'}
        open={props.isOTP}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle style={{ textAlign: 'center' }}>
          <span
            style=
            {{
              float: 'left'
            }}>
            {"Verify your phone number"}
          </span>
          <span
            style={{
              width: '25px', float: 'right',
              color: '#ffffff',
              borderRadius: '50%',
              fontSize: '14px',
              height: '25px',
              background: '#BDBDBD',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center'
            }}>
            <CloseIcon
              style={{
                cursor: 'pointer',
                fontSize: '14px'
              }}
              onClick={handleClose} />
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <OTPForm
            phoneNumber={mobileformik.values.phoneNumber}
            {...props} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SignUpForm