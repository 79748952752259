import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import { Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import { AlertClient } from '../../Redux/Actions/SigninAction';
import { CANCEL_ORDER, SCHEDULE_ALERT_ON_STATUS } from '../../Redux/Actions/Queries';
import client from '../../Configurations/apollo';
import PayNow from './PayNow';
import Lightbox from "react-awesome-lightbox";
import "../ImageCarousel/lightbox.css";
import { useStyles } from './style';

export default function OrderStepper(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [activestep, setactiveStepNmber] = React.useState(0);
    const [liveorderdata, setLiveorderdata] = React.useState(0);
    const desktop = useMediaQuery(useTheme().breakpoints.up('md'));
    console.log("created",props.OrderDetails.stats[0].created)
    const steps = [
        {
            label: 'Order-Placed',
            description: `Order Placed on ${props.OrderDetails.stats[0].created}`,
        },
        {
            label: 'Order-Accepted',
            description:
                '',
        },
        {
            label: 'Shopping-In-Progress',
            description: ``,
        },
        {
            label: 'Order-Ready',
            description: ``,
        },
        {
            label: 'Completed',
            description: ``,
        },
    ];
    const setActiveStep = (status) => {
        let step;
        if (status === "Order-Placed") step = 0;
        else if (status === "Order-Accepted") step = 1;
        else if (status === "Shopping-In-Progress") step = 2;
        else if (status === "Order-Ready") step = 3;
        else if (status === "Completed") step = 4;
        return step;
    };
    const cancelOrder = (id) => {
        // props.cancelorder(id, "Order-Cancelled");
        // setCancel(false);
        // setLoading(true);
        client
            .mutate({
                mutation: CANCEL_ORDER,
                variables: {
                    orderid: `${id}`,
                    stats: { status: "Order-Cancelled" },
                },
            })
            .then((res) => {
                if (res.data && res.data.cancelOrder && res.data.cancelOrder !== null) {
                    setOpen(false);
                    AlertClient.mutate({
                        mutation: SCHEDULE_ALERT_ON_STATUS,
                        variables: {
                            storeid: res.data.cancelOrder && res.data.cancelOrder.storeid && res.data.cancelOrder.storeid.id && res.data.cancelOrder.storeid.id,
                            status: "Order-Cancelled",
                            orderNumber: res.data.cancelOrder && res.data.cancelOrder.orderNumber && res.data.cancelOrder.orderNumber,
                            orderid: res.data.cancelOrder && res.data.cancelOrder.id && res.data.cancelOrder.id,
                            deliveryDate: res.data.cancelOrder && res.data.cancelOrder.deliveryDate && res.data.cancelOrder.deliveryDate,
                            deliveryTime: res.data.cancelOrder && res.data.cancelOrder.deliveryTime && res.data.cancelOrder.deliveryTime,
                            shopassistantId: res.data.cancelOrder && res.data.cancelOrder.shopAssistantId && res.data.cancelOrder.shopAssistantId.id && res.data.cancelOrder.shopAssistantId.id
                        }
                    }).then((res) => {
                        console.log("ALERT_CLIENT")
                    })
                    props.getOrderdetails(props.orderid && props.orderid);
                    // setOpen(false);
                } else if (res.errors) {
                    alert("Some error occured while canceling order")
                }
            })
            .catch((err) => {
                alert("Some error occured while canceling order")
            });


    };
    const [messages, setMessages] = React.useState({});

    React.useEffect(() => {
        const messageListener = (message) => {
            if(message!==null)
           { setMessages((prevMessages) => {
                const newMessages = { ...prevMessages };
                newMessages[message.id] = message;
             
                setactiveStepNmber(message.lastStatus);
                setLiveorderdata({
                    finalBillImage:(message.finalBill&&message.finalBill!==null)?message.finalBill:null,
                    paymentStatus: message.paymentStatus,
                    paymentActive: message.paymentActive,
                    finalBillAmount: message.finalBillAmount,
                    deliveryCharge: message.deliveryCharge,
                    discountPrice: message.discountPrice,
                    estimatedDeliveryTime: message.estimatedDeliveryTime,
                    lastStatus:message.lastStatus
                })
                return newMessages;
            });}
            else return null
        };

        const deleteMessageListener = (messageID) => {
            setMessages((prevMessages) => {
                const newMessages = { ...prevMessages };
                delete newMessages[messageID];
                return newMessages;
            });
        };

        if (props.socket !== null) {
            props.socket.on(`${localStorage.getItem('Orderid')}_order`, messageListener);
            props.socket.on('deleteMessage', deleteMessageListener);
            props.socket.emit('getMessages');
        }

        return () => {
            if (props.socket !== null) {
                props.socket.off(`${localStorage.getItem('Orderid')}_order`, messageListener);
                props.socket.off('deleteMessage', deleteMessageListener);
            }
        };
    }, [props.socket,messages]);
    const [state, setState] = React.useState({
        vieweropen: false,
        activeimage: ''
      })
    const openImageViewer = (image) => {
        setState({
          ...state,
          vieweropen: true,
          activeimage: image
        })
      };
      const closeImageViewer = () => {
        setState({
          ...state,
          vieweropen: false,
        })
      };

    return (
        <Box sx={{ maxWidth: 400 }}>
               {
                ((props.OrderDetails.finalBill && props.OrderDetails.finalBill !== null )||
                (liveorderdata!==0 && liveorderdata.finalBillImage &&liveorderdata.finalBillImage!== null&&liveorderdata.finalBillImage!== "null"&&
                liveorderdata.finalBillImage!== undefined&&liveorderdata.finalBillImage!== "undefined" ))?
                <div
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '10px',
                    width: '100%',
                    textAlign: 'right'
                  }}>
                  <span
                    style={{
                      color: '#2D93FB',
                      fontSize: '13px',
                      fontWeight: 700,
                      float: 'right',
                      cursor: 'pointer'
                    }}
                    onClick={() => openImageViewer((liveorderdata!==0 && liveorderdata.finalBillImage &&liveorderdata.finalBillImage!== null&&liveorderdata.finalBillImage!== "null"&&
                    liveorderdata.finalBillImage!== undefined&&liveorderdata.finalBillImage!== "undefined" )?liveorderdata.finalBillImage:
                    (props.OrderDetails.finalBill && props.OrderDetails.finalBill !== null )?props.OrderDetails.finalBill:null)}>
                    <b>View Bill</b>
                  </span>
                </div>
                :null
              }
                    {state.vieweropen &&
        <Lightbox onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,1)"
          }}
          closeOnClickOutside={true} image={(liveorderdata!==0 && liveorderdata.finalBillImage &&liveorderdata.finalBillImage!== null&&liveorderdata.finalBillImage!== "null"&&
          liveorderdata.finalBillImage!== undefined&&liveorderdata.finalBillImage!== "undefined" )?liveorderdata.finalBillImage:
          (props.OrderDetails.finalBill && props.OrderDetails.finalBill !== null )?props.OrderDetails.finalBill:null} title="Image Title" />
      }
            {(props.socket && messages !== {} && activestep !== 0 && activestep === "Order-Rejected") ?
                <div className={classes.cancelorderdiv}>
                    {'This order has been cancelled by the store !'}
                </div>
                :
                <Stepper
                    activeStep={(props.socket && messages !== {} && activestep !== 0) ? setActiveStep(activestep) : setActiveStep(props.orderstatus)}
                    orientation="vertical"
                    color='#89C74A'>
                    {steps.map((step, index) => (
                        <Step
                            key={step.label}
                            color='#89C74A'>
                            <StepLabel
                                style={{
                                    fontFamily: 'Nunito',
                                    fontSize: '13px',
                                    fontWeight: 700,
                                    color: 'rgba(6, 19, 3, 0.8)'
                                }}
                            >
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex'
                                    }}>
                                    <span
                                        style={{
                                            float: 'left',
                                            textAlign: 'left',
                                            width: '200%'
                                        }}>
                                        <Typography
                                            style={{
                                                fontFamily: 'Nunito',
                                                fontSize: '12px',
                                                fontWeight: 700,
                                                color: 'rgba(6, 19, 3, 0.5)',
                                                width: '100%'
                                            }}>
                                            {step.description}
                                           
                                            {((liveorderdata !== 0 &&
                                                liveorderdata.paymentActive === false &&
                                                liveorderdata.paymentStatus === 0 )?
                                                null
                                                :
                                                props.socket &&
                                                activestep !== 0 &&
                                                liveorderdata !== 0 &&
                                                liveorderdata.paymentActive === true &&
                                                liveorderdata.paymentStatus === 0 &&
                                                liveorderdata.finalBillAmount && liveorderdata.finalBillAmount !== null) ?
                                               ( (liveorderdata.lastStatus&&liveorderdata.lastStatus==="Completed")
                                                ?
                                                null
                                                :
                                                <PayNow
                                                    id={props.orderid}
                                                    bill={(liveorderdata.version === 1)?
                                                        parseFloat(Number((liveorderdata.finalBillAmount!==null?liveorderdata.finalBillAmount:liveorderdata.totalPayable) - 
                                                        ((liveorderdata.couponID!==null && liveorderdata.couponID.type===4?liveorderdata.deliveryCharge:liveorderdata.discountPrice)===null?0:  
                                                        (liveorderdata.couponID!==null && liveorderdata.couponID.type===4?liveorderdata.deliveryCharge:liveorderdata.discountPrice))+
                                                        ((liveorderdata.deliveryCharge!==null && liveorderdata.deliveryCharge!==0)?liveorderdata.deliveryCharge:0))).toFixed(2)
                                                    :liveorderdata.totalPayable
                                                }
                                                    // bill={(liveorderdata.finalBillAmount && liveorderdata.finalBillAmount !== null &&
                                                    //     liveorderdata.finalBillAmount !== 0 && liveorderdata.deliveryCharge &&
                                                    //     liveorderdata.deliveryCharge !== 0 && liveorderdata.deliveryCharge !== null) ?
                                                    //     parseFloat(Number(liveorderdata.finalBillAmount + liveorderdata.deliveryCharge - ((liveorderdata.discountPrice && liveorderdata.discountPrice !== null) ?
                                                    //         liveorderdata.discountPrice : 0))).toFixed(2)
                                                    //     :
                                                    //     Number(liveorderdata.finalBillAmount) + Number(liveorderdata.deliveryCharge)}
                                                    props={props}
                                                    {...props} />)
                                                :
                                                (props.orderstatus !== "Order-Ready"&&props.paymentactive === true && props.paymentstatus === 0) ||
                                                    (props.orderstatus === "Order-Ready" && index === 3 && props.paymentstatus === 0) ?
                                                    <div
                                                        style={{
                                                            paddingTop: '10px',
                                                            width: '100%'
                                                        }}>
                                                         {  ( props.orderstatus && props.orderstatus === "Completed" )
                                                         ?
                                                         null
                                                         :
                                                        <PayNow
                                                            id={props.orderid}
                                                            bill={(props.OrderDetails.version === 1)?
                                                                parseFloat(Number((props.OrderDetails.finalBillAmount!==null?props.OrderDetails.finalBillAmount:props.OrderDetails.totalPayable) - 
                                                                ((props.OrderDetails.couponID!==null && props.OrderDetails.couponID.type===4?props.OrderDetails.deliveryCharge:props.OrderDetails.discountPrice)===null?0:  
                                                                (props.OrderDetails.couponID!==null && props.OrderDetails.couponID.type===4?props.OrderDetails.deliveryCharge:props.OrderDetails.discountPrice))+
                                                                ((props.OrderDetails.deliveryCharge!==null && props.OrderDetails.deliveryCharge!==0)?props.OrderDetails.deliveryCharge:0))).toFixed(2)
                                                            :props.OrderDetails.totalPayable
                                                        }
                                                            // bill={(props.OrderDetails.finalBillAmount && props.OrderDetails.finalBillAmount !== null &&
                                                            //     props.OrderDetails.finalBillAmount !== 0 && props.OrderDetails.deliveryCharge &&
                                                            //     props.OrderDetails.deliveryCharge !== 0 && props.OrderDetails.deliveryCharge !== null) ?
                                                            //     parseFloat(Number(props.OrderDetails.finalBillAmount + props.OrderDetails.deliveryCharge - ((props.OrderDetails.discountPrice && props.OrderDetails.discountPrice !== null) ?
                                                            //         props.OrderDetails.discountPrice : 0))).toFixed(2)
                                                            //     :
                                                            //     Number(props.OrderDetails.finalBillAmount) + Number(props.OrderDetails.deliveryCharge)}
                                                            props={props}
                                                            {...props} />}
                                                    </div>
                                                    : null
                                            }
                                            {props.orderdetailssuccess && props.orderdetailssuccess === true &&
                                                props.OrderDetails && props.OrderDetails !== null &&
                                                props.OrderDetails.estimatedDeliveryTime !== null &&
                                                props.orderstatus === "Order-Ready" ?
                                                <div
                                                    style={{ paddingTop: '15px' }}>
                                                    {`Estimated delivery time : ${new Date(Number(props.OrderDetails.estimatedDeliveryTime)).toLocaleTimeString([], { timeStyle: 'short' })}`}
                                                </div>
                                                :
                                                (liveorderdata !== 0 &&
                                                    liveorderdata.estimatedDeliveryTime &&
                                                    liveorderdata.estimatedDeliveryTime !== null &&
                                                    activestep === "Order-Ready")
                                                    ?
                                                    <div
                                                        style={{ paddingTop: '15px' }}>
                                                        {`Estimated delivery time : ${new Date(liveorderdata.estimatedDeliveryTime).toLocaleTimeString([], { timeStyle: 'short' })}`}
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </Typography></span>
                                    <span
                                        style={{
                                            float: 'right',
                                            textAlign: 'right',
                                            width: '100%'
                                        }}>
                                        {props.orderstatus === "Order-Placed" && index === 0 &&
                                            <button className={desktop ? classes.cancelorder : classes.mobilecancelorder} onClick={() => setOpen(true)}>
                                                {'Cancel Order'}
                                            </button>}
                                    </span>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            }
            {open === true &&
                <Dialog
                    PaperProps={{
                        style: {
                            padding: '15px'
                        }
                    }
                    }
                    open={true}>
                    <DialogTitle
                        style={{
                            fontSize: '16px',
                            fontFamily: 'Nunito',
                            fontWeight: 700,
                        }}>
                        {'Confirm'}
                    </DialogTitle>
                    <DialogContent>
                        {'Are you sure you want to cancel this order?'}
                    </DialogContent>
                    <DialogActions>
                        <button
                            className={classes.nobutton}
                            onClick={() => setOpen(false)}>
                            No
                        </button>
                        <button className={classes.yesbutton}
                            onClick={() => cancelOrder(props.orderid)}>
                            Yes
                        </button>
                    </DialogActions>
                </Dialog>}
        </Box>
    );
}
