import client from "../../Configurations/apollo";
import { GET_SIGNUP } from "./LoginQueries";
import { GET_LOGIN, GET_MOBILEOTP, GET_MOBILESIGNUP, LOGIN_OTP, RESET_PASSWORD } from "./LoginQueries";
import { GET_PLACE_BY_LATLNG } from "./Queries";

//QUERIES
export const setCartlimit = () => {
	return(dispatch) => {
		dispatch({
            type: "SET_CART_LIMIT",
        });
	};
};

export const getPlaceBylatlng = (params) => {

    return (dispatch) => {
        dispatch({
            type: "FETCHING_PLACE",
        });
        client
            .query({
                query: GET_PLACE_BY_LATLNG,
                variables: {
                    latlng:params
                },
            })
            .then((res) => {
                if (res.data) {
                    if ((res.data && res.data !== null&& res.data.googlemapreversegeocoding&& 
                        res.data.googlemapreversegeocoding!==null)) {
                        dispatch({
                            type: "PLACE_DESCRIPTION_SUCCESS",
                            payload: res.data && res.data.googlemapreversegeocoding && res.data.googlemapreversegeocoding,
                        });
                    }
                    else if (res.data&& res.data.googlemapreversegeocoding&& 
                        res.data.googlemapreversegeocoding===null) {
                        dispatch({
                            type: "PLACE_DESCRIPTION_FAILED",
                            payload: null
                        });
                    }
                    else if (res.errors) {
                        dispatch({
                            type: "PLACE_DESCRIPTION_FAILED",
                            payload: res.errors[0].message,
                        });
                    }
                    
                }
                else if (res.errors) {
                    dispatch({
                        type: "PLACE_DESCRIPTION_FAILED",
                        payload: res.errors[0].message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: "PLACE_DESCRIPTION_FAILED",
                    payload: err.message,
                });
            });
    };
};

export const getCurrentlocation = (params) => {
localStorage.setItem('lattitude',params.substring(params.indexOf(":") + 1));
localStorage.setItem('longitude',params.slice(0, params.indexOf(",")));
    return (dispatch) => {
        dispatch({
            type: "FETCHING_CURRENT_LOCATION",
        });
        client
            .query({
                query: GET_PLACE_BY_LATLNG,
                variables: {
                    latlng:params
                },
            })
            .then((res) => {
                if (res.data) {
                    if ((res.data && res.data !== null&& res.data.googlemapreversegeocoding&& 
                        res.data.googlemapreversegeocoding!==null)) {
                        dispatch({
                            type: "CURRENT_LOCATION_SUCCESS",
                            payload: {data:res.data && res.data.googlemapreversegeocoding && res.data.googlemapreversegeocoding,
								setlng:params.substring(params.indexOf(",") + 1),
								setlat:params.slice(0, params.indexOf(","))
							},
                        });
                    }
                    else if (res.data&& res.data.googlemapreversegeocoding&& 
                        res.data.googlemapreversegeocoding===null) {
                        dispatch({
                            type: "CURRENT_LOCATION_FAILED",
                            payload: null
                        });
                    }
                    else if (res.errors) {
                        dispatch({
                            type: "CURRENT_LOCATION_FAILED",
                            payload: res.errors[0].message,
                        });
                    }
                    
                }
                else if (res.errors) {
                    dispatch({
                        type: "CURRENT_LOCATION_FAILED",
                        payload: res.errors[0].message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: "CURRENT_LOCATION_FAILED",
                    payload: err.message,
                });
            });
    };
};

export const getLogin = (params) => {

    return (dispatch) => {
        dispatch({
            type: "LOGGING_IN",
        });
        client
            .query({
                query: GET_LOGIN,
                variables: {
                    email: params.email.toLowerCase(), 
                    password: params.password,
                    fcmToken:params.fcmToken
                },
            })
            .then((res) => {
                if (res.data) {
                    if ((res.data && res.data !== null&& res.data.login&& 
                        res.data.login!==null)) {
                        dispatch({
                            type: "LOGIN_SUCCESS",
                            payload: res.data && res.data.login && res.data.login,
                        });
                    }
                    else if (res.data&& res.data.login&& 
                        res.data.login===null) {
                        dispatch({
                            type: "LOGIN_FAILED",
                            payload: null
                        });
                    }
                    else if (res.errors) {
                        dispatch({
                            type: "LOGIN_FAILED",
                            payload: res.errors[0].message,
                        });
                    }
                    
                }
                else if (res.errors) {
                    dispatch({
                        type: "LOGIN_FAILED",
                        payload: res.errors[0].message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: "LOGIN_FAILED",
                    payload: err.message,
                });
            });
    };
};

export const getSignup= (params) => {

	return (dispatch) => {
		dispatch({
			type: "SIGNING_UP",
		});
		client
			.mutate({
				mutation: GET_SIGNUP,
				variables: {
					email: `${params.email.toLowerCase()}`,
					password: `${params.password}`,
					firstName: `${params.firstName}`,
					lastName: `${params.lastName}`,
					// primaryLatitude:Number(9.9816),
					// primaryLongitude:Number(76.2999),
					// primaryLocation:`Kollam,Kerala`,
					fcmToken:localStorage.getItem("fcmToken")?localStorage.getItem("fcmToken"):null
				},
			})
			.then((res) => {
				if (res.data&&res.data.createUser&&res.data.createUser!==null) {
					dispatch({
						type: "SIGNUP_SUCCESS",
						payload: res.data.createUser,
					});
				} else if(res.errors) {
					dispatch({
						type: "SIGNUP_FAILED",
						payload: res.errors[0].message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "SIGNUP_FAILED",
					payload: err.message,
				});
			});
	};
};
export const closesnackbars = () => {
    return {
        type:'CLOSE_SNACKBARS'
    }
};

export const resetsuggestions = () => {
	localStorage.clear();
    return {
        type:'CLEAR_LOCATION_SUGGESTIONS'
    }
};
export const sendloginOTP=(params)=> {

	return (dispatch) => {
		dispatch({
			type: "SEND_OTP_WAITING",
		});
		client
			.mutate({
				mutation: GET_MOBILEOTP,
				variables: {
					phoneNumber: `${params}`,
				},
			})
			.then((res) => {
				if (res.data) {
					dispatch({
						type: "SEND_OTP_SUCCESS",
						payload: {SendOTP:res.data.sendOTP,
						phoneNumber:`${params}`},
					});
					if(res.errors)
					{
						dispatch({
							type: "SEND_OTP_FAILED",
							payload: res.errors[0].message,
						})
					}
				} else if(res.errors) {
					dispatch({
						type: "SEND_OTP_FAILED",
						payload: res.errors,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "SEND_OTP_FAILED",
					payload: err.message,
				});
			});
	};
}
export const verifyOTP = (params1, params2) =>{
	return async (dispatch) => {
		function onSuccess(success) {
			dispatch({ type: "OTP_LOGIN_ACCESS_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
			dispatch({ type: "OTP_LOGIN_ACCESS_FAILED", payload: error });
			return error;
		}
		function onNetworkError(error) {
			dispatch({ type: "OTP_LOGIN_ACCESS_ERROR" });

		}
		const success = await client.query({
			query: LOGIN_OTP,
			variables: {
				phoneNumber: `${params1}`,
				otp: Number(params2)
			},
		});
		if (success.data.loginMobile !== null) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
}

export const mobilesignupAccess = (params1,params2,params3) => {

	let firstName=params1;
	let lastName=params2;
	let phoneNumber=params3;

	return (dispatch) => {
		dispatch({
			type: "MOBILE_SIGNUP_WAITING",
			payload:params3
		});
		client
			.mutate({
				mutation: GET_MOBILESIGNUP,
				variables: {
					phoneNumber:`${phoneNumber}`,
					firstName: `${firstName}`,
					lastName:`${lastName}`,
				},
			})
			.then((res) => {
				if (res.data&&res.data.createUserMobile&&res.data.createUserMobile!==null) {
					dispatch({
						type: "MOBILE_SIGNUP_SUCCESS",
						payload: res.data,
					});
					if(res.errors) {
						dispatch({
							type: "MOBILE_SIGNUP_FAILED",
							payload: res.errors[0].message,
						});
					}
				} else if (res.errors) {
					dispatch({
						type: "MOBILE_SIGNUP_FAILED",
						payload: res.errors[0].message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "MOBILE_SIGNUP_FAILED",
					payload: err.message,
				});
			});
	};
};

export const resetpassword =(params)=> {
	let email=params.email;

	return (dispatch) => {
		dispatch({
			type: "RESET_WAITING",
		});
		client
			.mutate({
				mutation: RESET_PASSWORD,
				variables: {
					email:`${email}`,
				},
			})
			.then((res) => {
				if (res.data) {
					dispatch({
						type: "RESET_SUCCESS",
						payload: res.data.resetPassword,
					});
					if(res.errors){
						dispatch({
							type: "RESET_FAILED",
							payload: res.errors[0].message,
						});
					}
				} else if(res.errors){
					dispatch({
						type: "RESET_FAILED",
						payload: res.errors[0].message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "RESET_FAILED",
					payload: err.message,
				});
			});
	};
}
export const resetForgotPasswordProps = () => {
	return (dispatch) => {
		dispatch({
			type: "RESET_FORGOR_PASSWORD_PROPS",
		});
	}
};

export const resetMobileSigninProps = () => {
	return (dispatch) => {
		dispatch({
			type: "RESET_MOBILE_SIGNIN_PROPS",
		});
	}
};