import React from "react";
import withClearCache from "../ClearCache";
import ReduxProvider from "./redux";
import { ApolloProvider } from "react-apollo";
import client from "./apollo";
import Main from './Main';


const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
    return (
        <div className="App">
            <ReduxProvider>
                <ApolloProvider client={client}>
                    <Main {...props}/>    
                </ApolloProvider>
            </ReduxProvider>
        </div>
    );
}

export default App;
