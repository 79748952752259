import React from 'react';
import './Carousel.css';
import errorproduct from '../../Assets/Images/errorproduct.svg';
import Lightbox from "react-awesome-lightbox";
import "./lightbox.css";

class ImageCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeimage: '',
            vieweropen: '',
            selectedimage:'',
        };
    }
    selectImage = (smallimage) => {
        document.getElementById("imageBox").src = smallimage;
    }
    openImageViewer = (image) => {
        this.setState({
            ...this.state,
            vieweropen: true,
            activeimage: image
        });
    };

    closeImageViewer = () => {
        this.setState({
            ...this.state,
            vieweropen: false,
        })
    };
    componentDidMount(){
        if(this.props.data && this.props.data.primary &&
            this.props.data.primary !== null &&
            this.props.data.primary !== 'null' &&
            this.props.data.primary !== undefined &&
            this.props.data.primary)
            {
                this.setState({...this.state,selectedimage:this.props.data.primary})
            }
    }
    render() {
        return (
            <div >
                {
                    (this.props.data && this.props.data.primary &&
                        this.props.data.primary !== null &&
                        this.props.data.primary !== 'null' &&
                        this.props.data.primary !== undefined &&
                        this.props.data.primary !== 'undefined') ?
                        this.props.data &&
                            this.props.data.secondary &&
                            this.props.data.secondary.length &&
                            this.props.data.secondary.length > 0 ?
                            <>
                            
                                <img
                                    src={this.state.selectedimage}
                                    onClick={() => this.openImageViewer(this.state.selectedimage)}
                                    style={{ margin: "2px", cursor: 'pointer', width: '100%', height: '300px',
                                maxWidth:'100%',maxHeight:'100%',filter:this.props.storeoffline===true?'saturate(10%)':'none' }}
                                    alt=""
                                />
                            </>
                            :
                            <div className='product'>
                                <div className='img-container'>
                                    {(this.props.data.primary === null ||
                                        this.props.data.primary === 'null' ||
                                        this.props.data.primary === undefined ||
                                        this.props.data.primary === 'undefined')?
                                        <img id="imageBox"
                                        style={{filter:this.props.storeoffline===true?'saturate(10%)':'none'}} src={errorproduct} alt="product" />
                                        :
                                        <img id="imageBox" src={this.props.data.primary} alt="product"
                                        onClick={() => {
                                            this.setState({...this.state,selectedimage:this.props.data.primary});
                                            this.openImageViewer(this.props.data.primary)}} />
                                    }

                                </div>
                            </div>
                        :
                        <div className='product'>
                            <div className='img-container'>
                                {(this.props.data.primary === null ||
                                    this.props.data.primary === 'null' ||
                                    this.props.data.primary === undefined ||
                                    this.props.data.primary === 'undefined') &&
                                    <img id="imageBox"style={{filter:this.props.storeoffline===true?'saturate(10%)':'none'}} src={errorproduct} alt="product" />
                                }
                            </div>
                        </div>
                }
                {this.state.vieweropen &&
                    <Lightbox onClose={this.closeImageViewer}
                    zoomStep={0.1}
                        disableScroll={false}
                        backgroundStyle={{
                            backgroundColor: "rgba(0,0,0,1)"
                        }}
                        closeOnClickOutside={true} image={this.state.activeimage} title="Image Title" />
                }
                {this.props.data.secondary && this.props.data.secondary.length > 0 &&
                    <div className='product-small-img'>
                        {[...this.props.data.secondary, this.props.data.primary].map((i) =>
                        <div className='product-small-img-container'>
                            <img src={i} alt="product"
                            style={{width:'70px',height:'52px',maxWidth:'100%',maxHeight:'100%',filter:this.props.storeoffline===true?'saturate(10%)':'none'}}
                             onClick={() => { this.setState({...this.state,selectedimage:i}) }} />
                            </div>
                        )}
                    </div>
                }
            </div>
        );
    }
}

export default ImageCarousel;