import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    prevButton:{
        width: '133px',
        height: '45px',
        background: '#89C74A',
        borderRadius: '26.5px',
        border: 'none',
        fontSize: '14px',
        fontWeight: 700,
        fontFamily: 'Nunito',
        color: '#FFFFFF',
        cursor: 'pointer',
    },
    nextbutton: {
        width: '133px',
        height: '45px',
        background: '#89C74A',
        borderRadius: '26.5px',
        border: 'none',
        fontSize: '14px',
        fontWeight: 700,
        fontFamily: 'Nunito',
        color: '#FFFFFF',
        cursor: 'pointer',
    },
    nextbuttondisabled: {
        width: '133px',
        height: '45px',
        background: '#CCCCCC',
        borderRadius: '26.5px',
        border: 'none',
        fontSize: '14px',
        fontWeight: 700,
        fontFamily: 'Nunito',
        color: '#FFFFFF',
        cursor: 'pointer',
    },
    placeorderDiv: {
        background: 'linear-gradient(180deg, #85BE49 0%, #69A85C 100%)',
        width: '100%',
        height:'100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center', 
        textAlign: 'center',
    },
    checkicon: {
        maxWidth:'100%',
        maxHeight:'100%',
        width:'98px',
        height:'98px',
    },
    successmessage: {
        paddingTop:'26px',
        color:'#fff',
        fontSize:'18px',
        fontWeight:600
    },
    keepbrowsing: {
        bottom:0,
        paddingBottom:'80px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        paddingTop:'26px',
    },
    keepbrowsingbutton: {
       background:'rgba(255, 255, 255, 0.2)',
       color:'#fff',
       borderRadius:'26.5px',
       height:'45px',
       width:'196px',
       display:'flex',
       justifyContent:'center',
       alignItems:'center',
       textAlign:'center',
       fontSize:'14px',
       fontWeight:600,
       cursor:'pointer'
    },
    loadicon:{
        maxWidth:'100%',
        maxHeight:'100%',
        width:'44px',
        height:'44px',
    },
    payment: {
        background: '#F7FAFF',
        width: '100%',
        height:'100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center', 
        textAlign: 'center',
    },
    loadmessage: {
        color:'#69A85C',
        fontSize:'18px',
        fontWeight:600,
        paddingTop:'26px',
    },
}));