import React from 'react';
import { Grid } from '@mui/material';
import { useStyles } from './style';
import { desc, heading1 } from './constants';
import getapp from '../../Assets/Images/getapp.webp';
import appstorebutton from '../../Assets/Images/AppStoreIcon.webp';
import googleplaybutton from '../../Assets/Images/PlayStoreIcon.webp';
import './style.css';

const GetApp = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={0} md={6} style={{ position: 'relative', zIndex: 1 }}>
        <div className={classes.col}>
          <img src={getapp}
            className={classes.getapp}
            alt={'NearShopz All-in One Delivery App '}
            title={'Shopping groceries online is super easy using the NearShopz mobile app. '}
            loading='lazy'
            width='700px'
            height='700px'
          />
        </div>
      </Grid>
      <Grid item xs={9} md={5} style={{ justifyContent: 'left' }}>
        <h2 style={{color:'#50555C'}}>
          <b>{heading1}</b>
        </h2>
        <div style={{ paddingTop: '20px' }}>
          <a href="https://apps.apple.com/in/app/nearshopz/id1626241460" target="blank">
          <img
            src={appstorebutton}
            width="133px"
            height="43px"
            alt="NearShopz-ios"
            title="NearShopz-ios"
            loading='lazy'
            className={'appStoreIcon'}
          />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.nearshopz.customer" target="blank" >
            <img
              src={googleplaybutton}
              width="133px"
              height="43px"
              alt="NearShopz-google-play"
              title="NearShopz-google-play"
              loading='lazy'
              className={'appStoreIcon'}
            />
          </a>
        </div>
        {/* <h3 className={classes.heading2}>
          <b>{heading2}</b>
        </h3> */}
        <p style={{textAlign:'justify',paddingTop:'20px'}}>
        {desc}
        </p>
    
      </Grid>
    </Grid>
  )
}

export default GetApp