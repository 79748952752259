import { gql } from "@apollo/client";
export const PAY_NOW = gql`mutation($orderid:ID!)
{
    payNow(orderid:$orderid)
{
    id
    amount
}
}`
export const VERIFY_PAYMENT = gql`mutation($orderid:ID!,$razorpayOrderid:String!,$paymentid:String!,$signature:String!)
{
    verifyRazorPayPayment(orderid:$orderid,razorpayOrderid:$razorpayOrderid,paymentid:$paymentid,signature:$signature)
  }`