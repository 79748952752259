import React from 'react';
import { Box, Grid } from '@mui/material';
import ProductCard from '../../Components/ProductCard';

const Category1Products = (props) => {
    React.useEffect(() => {
          props.props.getCategory1Products({storeid:localStorage.getItem('storeid'),
          categoryid1:localStorage.getItem('category1id'),
        userid:localStorage.getItem('userid')});
      }
        // eslint-disable-next-line
        , []);
    return (
        <Box flex={4} sx={{ paddingTop: '25px' }}>
            <Grid container spacing={2} sx={{flexGrow:1}}>
                {
                    props.props.isStoreDetailsLoading && props.props.isStoreDetailsLoading === true ?
                    'Loading' :
                    (props.props.isStoreDetailsSuccess && props.props.isStoreDetailsSuccess === true &&
                        props.props.StoreDetails &&
                        props.props.StoreDetails !== null &&
                        props.props.StoreDetails.storeStatus === "active" && props.props.StoreDetails.storeWorking === true) ?
                (props.props.isCategory1ProductsLoading && props.props.isCategory1ProductsLoading === true ?
                    'loading' :
                    props.props.isCategory1Products && props.props.isCategory1Products === true &&
                        props.props.Category1Products && props.props.Category1Products !== null && props.props.Category1Products.items&&
                        props.props.Category1Products.items.length > 0 ?
                        props.props.Category1Products.items.map((item) =>
                                    <Grid item style={{ paddingBottom: '25px' }}>
                                        <ProductCard
                                            id={item.id}
                                            productname={item.productname && item.productname}
                                            image={item.image && item.image.primary && item.image.primary}
                                            price={item.price && item.price}
                                            promoprice={item.promoprice && item.promoprice}
                                            storeid={localStorage.getItem('storeid')}
                                            storename={localStorage.getItem('storeName')}
                                            uom={item.uom && item.uom}
                                            quantity={item.quantity}
                                            bxgy={item.bxgy}
                                            storeoffline={false}
                                            {...props} />
                                    </Grid>
                        )
                        : props.props.isCategory1ProductsFailed && props.props.isCategory1ProductsFailed === true &&
                        'OOPS')
                        :(
                            props.props.isCategory1ProductsLoading && props.props.isCategory1ProductsLoading === true ?
                            'loading' :
                            props.props.isCategory1Products && props.props.isCategory1Products === true &&
                                props.props.Category1Products && props.props.Category1Products !== null && props.props.Category1Products.items&&
                                props.props.Category1Products.items.length > 0 ?
                                props.props.Category1Products.items.map((item) =>
                                            <Grid item style={{ paddingBottom: '25px' }}>
                                                <ProductCard
                                                    id={item.id}
                                                    productname={item.productname && item.productname}
                                                    image={item.image && item.image.primary && item.image.primary}
                                                    price={item.price && item.price}
                                                    promoprice={item.promoprice && item.promoprice}
                                                    storeid={localStorage.getItem('storeid')}
                                                    storename={localStorage.getItem('storeName')}
                                                    uom={item.uom && item.uom}
                                                    quantity={item.quantity}
                                                    bxgy={item.bxgy}
                                                    storeoffline={true}
                                                    {...props} />
                                            </Grid>
                                )
                                : props.props.isCategory1ProductsFailed && props.props.isCategory1ProductsFailed === true &&
                                'OOPS' 
                        )
                }
            </Grid>
        </Box>
    )
}

export default Category1Products