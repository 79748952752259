import React, { useState, useRef } from 'react';
import './index.css';

const SwipeableCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const carouselRef = useRef();

  const handleSwipe = (event) => {
    const { clientX } = event.touches[0];
    const startX = carouselRef.current.getBoundingClientRect().left + window.pageXOffset;
    const endX = startX + carouselRef.current.offsetWidth;
    if (clientX < startX) {
      setActiveIndex(prevActiveIndex => prevActiveIndex + 1);
    }
    if (clientX > endX) {
      setActiveIndex(prevActiveIndex => prevActiveIndex - 1);
    }
  };

  return (
    <div
      className="carousel-container"
      ref={carouselRef}
      onTouchStart={handleSwipe}
    >
     {props.children}
    </div>
  );
};

export default SwipeableCarousel;
