import React from 'react';
import TopSection from './TopSection';
import MiddleSection from './MiddleSection';
import Footer from './Footer';
import './index.css';

const AboutUsPage = (props) => {
  
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [])

  return (
    <React.Fragment>
      <TopSection />
      <MiddleSection />
      <div className="separator"></div>
      <Footer {...props} />
    </React.Fragment>
  )
}

export default AboutUsPage
