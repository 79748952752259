import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { db } from "../db.js";
import { useLiveQuery } from "dexie-react-hooks";
import { useStyles } from "./style.js";
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import {
  Alert,
  Badge,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
} from "@mui/material";
import CartList from "./CartList";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import TwoToneIcon from "@mui/icons-material/ShoppingCartTwoTone";
import "./index.css";

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const { cart } = db;
  const cartdata = useLiveQuery(() => cart.toArray(), []);
  const [state, setState] = React.useState({
    data: {},
    CheckAmount: [],
    cartid: "",
    storeid: "",
    storename: "",
    storelogo: "",
    storelength: "",
    deleteStore: false,
    error: "",
    restrictordernum: 0,
    right: false,
  });

  const [clearcartopen, setClearCartOpen] = React.useState(false);

  const handleClearCartClose = () => {
    setClearCartOpen(false);
  };

  const toggleDrawer = (anchor, value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (value === true) {
      props.getCart(localStorage.getItem("userid"));
    }
    setState({ ...state, [anchor]: value });
  };

  const clearCart = () => {
    props.clearcart();
    setClearCartOpen(false);
  };

  const handleErrorClose = () => {
    props.createcartreset();
  };

  const list = (anchor) => (
    <Box
      sx={{
        backgroundColor: "#EAEDEF",
        height: "auto",
        padding: { sm: "10px", md: "15px" },
      }}
      role="presentation"
    >
      <div
        style={{
          padding: "10px",
        }}
      >
        <>
          <CartList {...props} />
        </>
      </div>
    </Box>
  );

  const calculateTotalNumberOfItems = (data) => {
    let noofitems = 0;
    data.map((c) => (noofitems = noofitems + c?.products?.length));
    return noofitems;
  };

  const cartOnClick = () => {
    // client
    // .mutate({
    //   mutation: UPDATE_FULL_CART,
    //   variables: {
    //     userid: p1, 
    //     storeid: p2, 
    //     products: p3
    //   },
    // })
    // .then((res) => {
    //   if (res.data) {
    //     if ((res.data && res.data !== null && res.data.updateFullCart &&
    //       res.data.updateFullCart !== null)) {
    //       dispatch({
    //         type: "UPDATE_FULL_CART_SUCCESS",
    //         payload: res.data && res.data.updateFullCart && res.data.updateFullCart &&
    //           res.data.updateFullCart,
    //       });
    //     }
    //     else if (res.data && res.data.updateFullCart &&
    //       res.data.updateFullCart === null) {
    //       dispatch({
    //         type: "UPDATE_FULL_CART_FAILED",
    //         payload: null
    //       });
    //     }
    //     else if (res.errors) {
    //       dispatch({
    //         type: "UPDATE_FULL_CART_FAILED",
    //         payload: res.errors[0].message,
    //       });
    //     }

    //   }
    //   else if (res.errors) {
    //     dispatch({
    //       type: "UPDATE_FULL_CART_FAILED",
    //       payload: res.errors[0].message,
    //     });
    //   }
    // })
    // .catch((err) => {
    //   dispatch({
    //     type: "UPDATE_FULL_CART_FAILED",
    //     payload: err.message,
    //   });
    // });
  }
  //   React.useEffect(()=>{
  //     let data;
  //     async function setCart(){
  //     if(props.isCart && props.isCart===true&&
  //       props.Cart&& props.Cart!==null&&props.Cart.length>0)
  //   { data = props.Cart.map((c)=> ({
  //       storeid: `${c.storeid.id}`, storename: `${c.storeid.storeName}`, totalPrice: c.totalPrice ,
  //       products: c.products.map((p)=>(
  //         {
  //           productid: `${p.productid.id}`, productQuantity: p.productid.quantity, cartquantity: p.quantity, productname: `${p.productid.productname}`,
  //           promoprice:p.productid.promoprice,
  //           image: p.productid.image.primary, productprice: (p.productid.promoprice !== null) ? Number(p.productid.promoprice) : Number(p.productid.price), uom: p.productid.uom
  //       }
  //       ))
  //   }))}
  //   console.log("DCCCCC0",data)
  //   await db.cart.add({data});
  // }
  //   setCart();
  //   },
  //   [props.isCart])
  const cart_OnClick = () => {
    setState({ ...state, right: true }); 
  };

  return (
    <div>
      <React.Fragment key={"right"}>
        <button
          className="notification-button"
          // onClick={() => {
          //   props.navigate('/checkout');

          //   props.updatefullcart(
          //     localStorage.getItem('userid'),
          //     cartdata[0]?.storeid,
          //     cartdata[0]?.products?.map((p) => {
          //       return {
          //         productid: p.productid,
          //         productPrice: p.productprice,
          //         quantity: p.cartquantity,
          //         price: p.productprice && parseFloat(
          //           p.productprice * Number(p.cartquantity)
          //         ).toFixed(2)
          //       }
          //     }))
          // }}
          onClick={cart_OnClick}
        >
          <Badge
            badgeContent={
              cartdata &&
              cartdata !== null &&
              calculateTotalNumberOfItems(cartdata)
            }
            color="warning"
          >
            <ShoppingCartOutlinedIcon />
          </Badge>
          <span>Cart</span>
        </button>
      </React.Fragment>
      {props.cartFailed &&
        props.cartFailed !== "" &&
        props.cartFailed ===
        "Products from only 5 stores can be added to cart" && (
          <Snackbar
            open={true}
            autoHideDuration={5000}
            onClose={handleErrorClose}
            sx={{ height: "100%", width: "fit-content" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            severity="error"
          >
            <Alert
              onClose={handleErrorClose}
              severity="error"
              sx={{
                backgroundColor: "#d32f2f",
                borderRadius: "6px",
                color: "#fff",
                boxShadow:
                  "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
              }}
            >
              {"Only 5 stores can be added to cart !"}
            </Alert>
          </Snackbar>
        )}
      <Drawer
        anchor={"right"}
        open={state.right}
        onClose={toggleDrawer("right", false)}
        sx={{
          '& .MuiDrawer-paper':
          {
            boxSizing: 'border-box',
            // maxWidth:'500px',
            width: { xs: '100%', sm: '100%', md: 500, lg: 900, xl: 500 },
            backgroundColor: '#EAEDEF'
          },
        }}
      >
        <>
          <div
            style={{
              paddingTop: '40px',
              paddingLeft: '10px',
              paddingBottom: '18px',
              background: '#ffffff',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#000000',
              // top:'0px',
              // zIndex:'1',
              // position:'fixed',
              // paddingRight:'23px',
            }}>
            <div
              style={{
                paddingBottom: '10px',
                width: '100%',
                // paddingTop:'0px', 
              }}>
              <Grid 
              container 
              style={{ width: '100%' }}>
                <Grid item xs={0.5} />
                <Grid
                  item
                  xs={2}
                  justifyContent={"center"}
                  textAlign={"center"}
                  alignItems={"center"}
                >
                  <span
                    className={classes.leftarrow}
                    onClick={toggleDrawer("right", false)}
                  >
                    <KeyboardBackspaceIcon />
                  </span>
                </Grid>
                <Grid item xs={1} />
                <Grid
                  item
                  xs={8}
                  justifyContent={"left"}
                  textAlign={"center"}
                  alignItems={"center"}
                  style={{ width: "100%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "left",
                      alignItems: "center",
                      paddingLeft: "0px",
                      // marginLeft:"-200px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",

                      }}
                    >
                      <span
                        style={{
                          color: "#000000",
                          fontStyle: "normal",
                          fontWeight: "bold",
                          fontSize: "24px",
                          lineHeight: "33px",

                        }}
                      >
                        {"Shopping Cart"}
                      </span>
                      <span
                        style={{
                          color: "rgba(0,0,0,0.5)",
                          fontSize: "12px",
                          lineHeight: "16px",
                          textAlign: "left",
                        }}
                      >
                        {cartdata && cartdata !== null && cartdata.length > 0
                          ? `You have items from ${cartdata && cartdata !== null
                            ? cartdata.length
                            : "0"
                          } stores`
                          : "You have no items in your cart"}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        paddingLeft: "15px",

                      }}
                    >
                      <ShoppingCartTwoToneIcon fontSize="large" />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          {list("right")}
        </>
      </Drawer>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "26.5px",
            backgroundColor: `#EAEEF7`,
            width: "100%",
            padding: "25px",
          },
        }}
        maxWidth={"sm"}
        open={clearcartopen}
        onClose={handleClearCartClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          {"Are you sure you want to delete all the products in the cart?"}
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingTop: "8px",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "50%"
              }}>
              <button className={classes.buttonCancel} onClick={clearCart}>
                {"Yes"}
              </button>
              <button className={classes.button} onClick={handleClearCartClose}>
                {"No"}
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
