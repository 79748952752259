import { CircularProgress } from '@mui/material';
import React from 'react';
import AddPhoneNumber from './AddPhoneNumber';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from './style';

const Contact = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const validationSchema = Yup.object().shape({
        phoneNumber: Yup.string()
            .matches(/^[0-9]*$/, "Please enter only numbers")
            .min(10, "Please enter 10 digit Mobile number")
            .max(10, "Please enter 10 digit Mobile number")
            .required("Please enter Mobile number"),
    });

    const formik = useFormik({
        initialValues: {
            phoneNumber: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setOpen(false);
            props.props.addPlaceorderPhoneNumber(values.phoneNumber);
        },
    });
    React.useEffect(() => {
        props.props.getCheckoutPhoneNumber(localStorage.getItem('userid'));
    },
        // eslint-disable-next-line
        [])
    return (
        <>
            {props.props.isCheckoutPhoneNumberLoading &&
                props.props.isCheckoutPhoneNumberLoading === true ?
                <CircularProgress /> : props.props.isCheckoutPhoneNumber &&
                    props.props.isCheckoutPhoneNumber === true ? (
                    props.props.placeorderphonenumber === null ?
                        <AddPhoneNumber props={props} />
                        :
                        <div
                            style={{
                                background: 'rgba(133, 190, 73, 0.2)',
                                borderRadius: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'left',
                                color: '#69A85C',
                                fontWeight: 600,
                                fontSize: '12px',
                                paddingLeft: '15px',
                                paddingTop: '15px',
                                paddingBottom: '15px',
                                width: '100%',
                            }}>
                            {'Our Agent will contact you on '}{props.props.placeorderphonenumber}
                            <span style={{ float: 'right' }}>&nbsp;
                                <span
                                    onClick={() => setOpen(true)}
                                    style={{
                                        color: '#69A85C',
                                        fontWeight: 600,
                                        fontSize: '12px',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                    }}> Edit </span>
                            </span>
                        </div>

                )
                    :
                    props.props.isCheckoutPhoneNumberFailed &&
                    props.props.isCheckoutPhoneNumberFailed === true &&
                    'OOPS'
            }
            <Dialog
                PaperProps={{
                    style: {
                        borderRadius: '10px',
                        backgroundColor: '#ffffff',
                        width: '100%',
                        padding: '10px'
                    }
                }}
                fullScreen={fullScreen}
                maxWidth={'sm'}
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle className={classes.modalHeading}>
                    <div className={classes.modaltitle}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <span style={{
                            width: '90%',
                            fontFamily: 'Nunito',
                            fontWeight: 600, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'
                        }}>
                            {'Add phone number'}
                        </span>
                        <span
                            style={{
                                float: 'right', color: 'rgba(0, 0, 0, 0.8)',
                                width: '10%',
                                display: 'flex', justifyContent: 'right', alignItems: 'center', textAlign: 'right',
                            }}>
                            <span
                                style={{
                                    width: '25px',
                                    color: '#ffffff',
                                    borderRadius: '50%',
                                    fontSize: '14px',
                                    height: '25px', background: '#BDBDBD', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'
                                }}>
                                <CloseIcon
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: '14px'
                                    }}
                                    onClick={() => setOpen(false)} />
                            </span>
                        </span>
                    </div>
                </DialogTitle>

                <DialogContent dividers style={{ width: "100%" }}>
                    <form onSubmit={formik.handleSubmit} style={{ width: "100%", paddingTop: '15px' }}>
                        <TextField
                            fullWidth
                            InputProps={{
                                style: {
                                    width: '100%', borderRadius: '12px', fontSize: '16px',
                                    height: '56px', background: '#fff'
                                }, autoComplete: 'off'
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // className={classes.input}
                            name="phoneNumber"
                            value={formik.values.phoneNumber}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.phoneNumber &&
                                Boolean((formik.errors.phoneNumber))
                            }
                            helperText={
                                formik.touched.phoneNumber && formik.errors.phoneNumber
                            }
                            label="Enter your phone number"
                            // placeholder="Enter your phone number"
                            variant="outlined" />
                        {/* </> */}

                        <div style={{
                            display: 'flex',
                            paddingTop: '15px', justifyContent: 'center', width: '100%', textAlign: 'center', alignItems: 'center'
                        }}>
                            <button
                                className={classes.button}
                                type="submit"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Contact