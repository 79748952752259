import React, { useState } from 'react';
import './index.css';

const StoreCard = (props) => {
  const storeid = localStorage.getItem("storeid")
  const routeToStore = (e, id) => {
    e.persist();
    props.props.getDepartments(props.storeid);
    localStorage.setItem("storeLogo", props.StoreLogo);
    localStorage.setItem('storeid', props.storeid);
    localStorage.setItem("storeName", props.Storename);
    localStorage.setItem("businessType", props.businessType);
    localStorage.setItem("storelocation", props.storelocation);
   
      
      
      props.navigate(`/stores/categories`)
   
   
  }

  return (
    <div style={{paddingBottom:'15px'}}>
      <div className='Card' onClick={(e) => routeToStore(e, props.storeid)}>
        <div style={{height:'130px',display:'flex',justifyContent:'center',alignItems:'center'}}>
          <img src={props.StoreLogo} alt= {`${props.Storename} Logo`} className='Card-Image' />
        </div>
        <div className='gap'>
          <p className='Store-name'>
            {`${props.Storename}`}
          </p>
        </div>

      </div>
    </div>
  )
}
export default StoreCard