import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logo from '../../Assets/Images/WhiteLogo.svg';
import './index.css';

export default function CheckoutHeader(props) {
//   const classes = useStyles();
  const logoClick = () => {
    props.navigate("/stores");
  };

  return (
    <>
    <AppBar position="fixed" sx={{background:'linear-gradient(180deg, #85BE49 0%, #69A85C 100%)',boxShadow:'none'}}>
      <Toolbar style={{justifyContent:'center'}}>
          <img style={{height:'38px',width:'152px',cursor:'pointer',opacity:1}} src={logo} alt="SHOP.png"  onClick={logoClick}/>
        </Toolbar>
    </AppBar>
    <div className='checkoutchild-div'>
        {props.children}
    </div>
    </>
  );
}
