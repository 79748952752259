import React from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import ProductCard from '../../Components/ProductCard';
import { useStyles } from "./style";
import Carousel, { consts } from 'react-elastic-carousel';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SwipeableCarousel from "./SwipeableCarousel";

function myArrow({ type, onClick, isEdge }) {
    const pointer = type === consts.PREV ? <KeyboardArrowLeftIcon fontSize="small" color="red" /> : <KeyboardArrowRightIcon />
    return (
        <button
            style={{
                backgroundColor: 'transparent', border: 'none', width: '20px', borderRadius: '50%',
                textAlign: 'center', display: 'flex',
                justifyContent: 'center', alignItems: 'center'
            }}
            onClick={onClick} disabled={isEdge}>
            {pointer}
        </button>
    )
}


const Subcategory1 = (props) => {
    const classes = useStyles();
    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 4 },
        { width: 768, itemsToShow: 4 },
        { width: 900, itemsToShow: 5 },
        { width: 1100, itemsToShow: 5 },
    ];
    const setCategory1Id = (id) => {
        localStorage.setItem("category1id", id);
    };
    const routeToCategory1Products = () => {
        props.navigate(`/stores/categories/category1products`);
    };
    const routeToCategory2 = () => {
        props.navigate(`/stores/categories/category2`);
    };
    React.useEffect(() => {
        window.scrollTo(0, 0,);
        let sid = localStorage.getItem("storeid");
        // let categoryid = localStorage.getItem("categoryid");
        let userid = localStorage.getItem("userid");
        props.props.getCategory1list({ storeid: sid, categoryid: localStorage.getItem("categoryid"), userid: userid });
    },
        // eslint-disable-next-line
        [])

    return (
        <Box flex={4} sx={{ paddingTop: '25px' }}>
            <Grid container spacing={2} sx={{ flexGrow: 0.5 }}>
                {
                props.props.isStoreDetailsLoading && props.props.isStoreDetailsLoading === true ?
                        'Loading' :
                        (props.props.isStoreDetailsSuccess && props.props.isStoreDetailsSuccess === true &&
                            props.props.StoreDetails &&
                            props.props.StoreDetails !== null &&
                            props.props.StoreDetails.storeStatus === "active" && props.props.StoreDetails.storeWorking === true) ?
                (props.props.isCategories1Loading && props.props.isCategories1Loading === true ?
                    'Loading' :
                    props.props.isCategories1 && props.props.isCategories1 === true &&
                        props.props.Categories1 && props.props.Categories1 !== null && props.props.Categories1.length > 0 ?
                        props.props.Categories1.map(({ category1Products, name, id, storeid, childCategoryPresent }) =>
                            category1Products.items.length > 0 &&
                            <React.Fragment key={id}>
                                <Grid item xs={8} md={10} className={classes.departmentname}>
                                    {name}
                                </Grid>
                                <Grid item xs={4} md={2} className={classes.viewallgrid}>
                                    {childCategoryPresent === false ? (
                                        <span
                                            onClick={() => {
                                                setCategory1Id(id);
                                                routeToCategory1Products();
                                            }}
                                            className={classes.viewall}
                                        >View all</span>
                                    )
                                        : (
                                            <span
                                                onClick={() => {
                                                    setCategory1Id(id);
                                                    routeToCategory2();
                                                }}
                                                className={classes.viewall}
                                            >View all</span>
                                        )}
                                </Grid>
                                {fullScreen ?
                                    <SwipeableCarousel {...props}>
                                        {category1Products.items.slice(0, 5).map(item =>
                                            <Grid item className={classes.root}>
                                                <ProductCard
                                                    id={item.id}
                                                    productname={item.productname && item.productname}
                                                    image={item.image && item.image.primary && item.image.primary}
                                                    price={item.price && item.price}
                                                    promoprice={item.promoprice && item.promoprice}
                                                    storeid={storeid}
                                                    storename={localStorage.getItem('storeName')}
                                                    uom={item.uom && item.uom}
                                                    quantity={item.quantity}
                                                    bxgy={item.bxgy}
                                                    storeoffline={false}
                                                    {...props} />
                                            </Grid>
                                        )}
                                    </SwipeableCarousel>
                                    :
                                    <Carousel
                                        renderArrow={myArrow}
                                        itemPosition="START"
                                        breakPoints={breakPoints}
                                        showEmptySlots={true}
                                        itemPadding={[0]}
                                        outerSpacing={0}
                                        pagination={false}>
                                        {category1Products.items.slice(0, 5).map(item =>
                                            <Grid item className={classes.root}>
                                                <ProductCard
                                                    id={item.id}
                                                    productname={item.productname && item.productname}
                                                    image={item.image && item.image.primary && item.image.primary}
                                                    price={item.price && item.price}
                                                    promoprice={item.promoprice && item.promoprice}
                                                    storeid={storeid}
                                                    storename={localStorage.getItem('storeName')}
                                                    uom={item.uom && item.uom}
                                                    quantity={item.quantity}
                                                    bxgy={item.bxgy}
                                                    storeoffline={false}
                                                    {...props} />
                                            </Grid>
                                        )}
                                    </Carousel>
                                }
                            </React.Fragment>
                        )
                        : props.props.isDepartmentsFailed && props.props.isDepartmentsFailed === true &&
                        'OOPS'
                )
                :(
                    props.props.isCategories1Loading && props.props.isCategories1Loading === true ?
                    'Loading' :
                    props.props.isCategories1 && props.props.isCategories1 === true &&
                        props.props.Categories1 && props.props.Categories1 !== null && props.props.Categories1.length > 0 ?
                        props.props.Categories1.map(({ category1Products, name, id, storeid, childCategoryPresent }) =>
                            category1Products.items.length > 0 &&
                            <React.Fragment key={id}>
                                <Grid item xs={8} md={10} className={classes.departmentname}>
                                    {name}
                                </Grid>
                                <Grid item xs={4} md={2} className={classes.viewallgrid}>
                                    {childCategoryPresent === false ? (
                                        <span
                                            onClick={() => {
                                                setCategory1Id(id);
                                                routeToCategory1Products();
                                            }}
                                            className={classes.viewall}
                                        >View all</span>
                                    )
                                        : (
                                            <span
                                                onClick={() => {
                                                    setCategory1Id(id);
                                                    routeToCategory2();
                                                }}
                                                className={classes.viewall}
                                            >View all</span>
                                        )}
                                </Grid>
                                {fullScreen ?
                                    <SwipeableCarousel {...props}>
                                        {category1Products.items.slice(0, 5).map(item =>
                                            <Grid item className={classes.root}>
                                                <ProductCard
                                                    id={item.id}
                                                    productname={item.productname && item.productname}
                                                    image={item.image && item.image.primary && item.image.primary}
                                                    price={item.price && item.price}
                                                    promoprice={item.promoprice && item.promoprice}
                                                    storeid={storeid}
                                                    storename={localStorage.getItem('storeName')}
                                                    uom={item.uom && item.uom}
                                                    quantity={item.quantity}
                                                    bxgy={item.bxgy}
                                                    storeoffline={true}
                                                    {...props} />
                                            </Grid>
                                        )}
                                    </SwipeableCarousel>
                                    :
                                    <Carousel
                                        renderArrow={myArrow}
                                        itemPosition="START"
                                        breakPoints={breakPoints}
                                        showEmptySlots={true}
                                        itemPadding={[0]}
                                        outerSpacing={0}
                                        pagination={false}>
                                        {category1Products.items.slice(0, 5).map(item =>
                                            <Grid item className={classes.root}>
                                                <ProductCard
                                                    id={item.id}
                                                    productname={item.productname && item.productname}
                                                    image={item.image && item.image.primary && item.image.primary}
                                                    price={item.price && item.price}
                                                    promoprice={item.promoprice && item.promoprice}
                                                    storeid={storeid}
                                                    storename={localStorage.getItem('storeName')}
                                                    uom={item.uom && item.uom}
                                                    quantity={item.quantity}
                                                    bxgy={item.bxgy}
                                                    storeoffline={true}
                                                    {...props} />
                                            </Grid>
                                        )}
                                    </Carousel>
                                }
                            </React.Fragment>
                        )
                        : props.props.isDepartmentsFailed && props.props.isDepartmentsFailed === true &&
                        'OOPS'
                )
                }
            </Grid>
        </Box>
    );
};

export default Subcategory1;