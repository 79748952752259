import { gql } from "@apollo/client";

export const GET_LOGIN = gql`
query login($email: String!, $password: String!,$fcmToken:String) {
  login(email: $email, password: $password,fcmToken:$fcmToken) {
     userid
     token
	 tokenExpiration
	 primaryLatitude
	 primaryLongitude
	 primaryLocation
  }
}
`
export const GET_MOBILEOTP = gql`
mutation OTP($phoneNumber:String!){
	sendOTP(phoneNumber:$phoneNumber)	
  }`

export const LOGIN_OTP = gql`
  query login($phoneNumber:String!,$otp:Int!){
	loginMobile(phoneNumber:$phoneNumber,otp:$otp){
		  userid
		  token
		  primaryLatitude
  		  primaryLongitude
		  primaryLocation
	  }
	  
	}`

    export const GET_SIGNUP = gql`
    mutation($email:String!,
            $password:String!,
            $firstName:String!,
            $lastName:String!,
            $fcmToken:String)
            {
            createUser(email :$email,
                    password : $password, 
                    firstName : $firstName, 
                    lastName:$lastName,
                    fcmToken:$fcmToken) 
                    {
                         userid
                         token
                         primaryLatitude
                         primaryLongitude
                         primaryLocation
                    }
            }`
    

export const GET_MOBILESIGNUP = gql`
	mutation($phoneNumber:String!,
	  $firstName:String!,
	  $lastName:String!)
	  {
	  createUserMobile(phoneNumber:$phoneNumber,
					  firstName:$firstName, 
					  lastName:$lastName)
					  {
						  id
						  verified
						  firstName
						  lastName
						  primaryLatitude
						  primaryLongitude
						  primaryLocation
					  }
	}`
export const RESET_PASSWORD=gql`
query resetPassword($email:String!)
{
  resetPassword(email:$email)
}
`