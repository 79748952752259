import React from 'react';
import { Route, Routes, useParams } from "react-router-dom";
import Navbar from '../../Components/Navbar';
import Addresses from '../../Components/AccountSettings/Addresses';
import MyOrders from '../../Components/AccountSettings/MyOrders';
import CategoryListingPage from '../../Layouts/CategoryListingPage';
import StoreListing from '../../Layouts/StoreListing';
import AlertBar from '../../Components/AlertBar';

const SigninPage = (props) => {
  const params = useParams();
  React.useEffect(() => {
    if (!localStorage.getItem('token') && !localStorage.getItem('location')) {
      props.navigate('/')
    }
  },
    // eslint-disable-next-line
    [localStorage.getItem('token'), localStorage.getItem('location')])
  React.useEffect(() => {
    if (!localStorage.getItem('location') && props.isAccountFailed === true && props.accounterror === "Unauthenticated!") {
      localStorage.clear();
    }
    else if (!localStorage.getItem('location') && props.isNotificationLengthFailed === true && props.NotificationLengtherror === "Unauthenticated!") {
      localStorage.clear();
    }
  },
    // eslint-disable-next-line
    [props.isAccountFailed, props.isNotificationLengthFailed])
  return (
    <Navbar
      params={params}
      socket={props.socket}
      {...props}>
      <Routes>
        <Route exact={true} path={'/'} element={<StoreListing
          params={params}
          navigate={props.navigate} {...props} />} />
        <Route exact={true} path={'/Account/Addresses'} element={<Addresses
          params={params}
          navigate={props.navigate} {...props} />} />
        <Route exact={true} path={'/Account/MyOrders'} element={<MyOrders
          params={params} navigate={props.navigate} {...props} />} socket={props.socket}/>
        <Route exact={false} path={'/categories/*'} element={<CategoryListingPage
          params={params}
          navigate={props.navigate} {...props} />} />
        <Route exact={false} path={'/:storeid'} element={<CategoryListingPage
          params={params}
          navigate={props.navigate} {...props} />} />
      </Routes>
      {props.isLogInFailed === true &&
        <AlertBar type={'error'} message={props.loginerror} {...props} props={props} />
      }
      {props.isSignUpFailed === true &&
        <AlertBar type={'error'} message={props.signuperror} {...props} props={props} />
      }
      {props.isOTPFailed && props.isOTPFailed === true &&
        <AlertBar type={'error'} message={props.errorMessage.message} {...props} props={props} />
      }
      {props.MobileSignupfailed && props.MobileSignupfailed === true &&
        <AlertBar type={'error'} message={props.mobileerrorMessage} {...props} props={props} />}
      {props.isVerifyOTPFailed && props.isVerifyOTPFailed === true &&
        <AlertBar type={'error'} message={props.verifyOTPerror} {...props} props={props} />}
      {props.isReset && props.isReset === true &&
        <AlertBar type={'success'} message={props.ResetPassword} {...props} props={props} />}
      {props.isEditaddress && props.isEditaddress === true &&
        <AlertBar type="success" message={"Adrdress edited successfully!"}{...props} props={props} />
      }
      {props.isEditaddressFailed && props.isEditaddressFailed === true &&
        <AlertBar type="error" message={props.editaddresserror} props={props} {...props} />}
      {props.isDeleteAddress && props.isDeleteAddress === true &&
        <AlertBar type="success" message={"Address deleted successfully!"} {...props} props={props} />}
      {props.isAddaddressFailed && props.isAddaddressFailed === true && props.addaddresserrorMessage &&
        props.addaddresserrorMessage !== null &&
        <AlertBar type="error" message={props.addaddresserrorMessage} {...props} props={props} />}
      {props.isEmailFailed && props.isEmailFailed === true && props.addemailerror &&
        <AlertBar type="error" message={props.addemailerror} {...props} props={props} />}
      {props.isEmail && props.isEmail === true &&
        <AlertBar type="success" message={'Email updated successfully!'} {...props} props={props} />}
      {props.UpdatedPassword && props.UpdatedPassword === true &&
        <AlertBar type="success" message={'Password updated successfully!'} {...props} props={props} />}
      {props.isCartLimit === true &&
        <AlertBar type={'error'} message={'Only 5 stores can be added to cart'} {...props} props={props} />
      }
      {props.isEditAccount && props.isEditAccount === true &&
        <AlertBar type="success" message={'Account updated successfully!'} {...props} props={props} />}
      {props.isAddaddress && props.isAddaddress === true &&
        <AlertBar type="success" message={'Address added successfully!'} {...props} props={props} />}
      {props.ComplaintRegistration && props.ComplaintRegistration === true &&
        <AlertBar type="success" message={'Complaint registered successfully!'} {...props} props={props} />}
    </Navbar>
  )
}

export default SigninPage
