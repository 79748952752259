import React from 'react';
import PropTypes from 'prop-types';

const Card = ({ hasBorder, borderPosition, children }) => {
  const borderColor = hasBorder ? '#FFC800' : 'transparent';
  const borderStyle =
    borderPosition === 'top'
      ? { borderTop: `5px solid ${borderColor}` }
      : { borderBottom: `5px solid ${borderColor}` };

  const cardStyles = {
    padding: '40px',
    boxShadow: ' 0px 0px 25px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '1rem',
    background: '#fff',
    width:'100%',
    ...borderStyle,
  };

  return <div style={cardStyles}>{children}</div>;
};

Card.propTypes = {
  hasBorder: PropTypes.bool,
  borderPosition: PropTypes.oneOf(['top', 'bottom']),
  children: PropTypes.node.isRequired,
};

Card.defaultProps = {
  hasBorder: false,
  borderPosition: 'top',
};

export default Card;
