import React, { useState } from 'react';
import { Divider } from '@mui/material';
import Lottie from 'lottie-react-web';
import animationUrl from '../../../Assets/animationURL.json';
import './Coupon.css';
import tick from '../../../Assets/Images/tick.jpg'

const CouponPopup = ({props,setOpen,open,setState}) => {

    console.log("props from coupon popup", props);
    
  //  const [isVisible, setIsVisible] = useState(true);

    const couponCode = props?.Cart?.couponId?.couponCode || ''; // Default to empty string
    const discountAmt = props?.Cart?.couponId?.disAmt || ''; // Default to empty string

    const handleClose = () => {
       // setIsVisible(false);
       setOpen(false)
       setState({right:false})
    };

    // if ( !couponCode || !discountAmt) {
    //     return null; // Don't render if not visible or data is not available
    // }

    const lottieStyles = {
        width: '105.739px', // Set the desired width
        height: '105.739px', // Set the desired height
    };

    return (
       
        // <div className="coupon-popup">
        //     <Lottie
        //         options={{
        //             animationData: animationUrl,
        //             loop: true, // Set to true if you want the animation to loop
        //             autoplay: true, // Set to true if you want the animation to play on load
        //         }}
        //         style={lottieStyles}
        //     />
      <div>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
            <img src={tick} alt='tick image' height="40px" style={{width:"40px"}}/>
        </div>
            <div className='applied-coupon-name'>
                {`${couponCode} applied`}
            </div>
            <div className='applied-coupon-savings'>
                {`You Saved ${discountAmt}`}
            </div>
            <div className='applied-coupon-text'>
                {'With This Coupon Code '}
            </div>
            <Divider className='dashline'/>
            <div className='thanks-text' onClick={handleClose}>
                {'Thanks'}
            </div>
        </div>
    );
};

export default CouponPopup;
