import React from 'react';
import ExploreButton from '../Buttons/ExploreButton';
import { FEATURES } from './constants';
import './index.css';

const Whyus = (props) => {
    return (
        <section className='features_section'>
            <div className='features_top'>
                <h3 style={{ fontSize: '20px' }}>
                    {'Why Sell on NearShopz? Grow Your Business and Reach New Customers'}
                </h3>
                <p>NearShopz is the all-in-one solution for local businesses to thrive online.</p>
            </div>
            <div className='features_container'>
                {FEATURES.map((f) =>
                    <div className='feature_item'>
                        <div className='featureIcon_div'>
                            <img 
                            src={f.icon} 
                            alt={f.alt} 
                            title={f.heading} 
                            className='featureIcon' 
                            loading='lazy'
                            width='50px'
                            height='50px' />
                        </div>
                        <div className='featureItem_info'>
                            <h3>{f.heading}</h3>
                            <p>{f.description}</p>
                        </div>
                    </div>
                )}
            </div>
            <div className='become-a-seller-button-div'>
          <ExploreButton
          screen='small'
           {...props} />
          </div>
        </section>
    )
}

export default Whyus