import React from 'react';
import { AppBar, Box, Dialog, Divider, Drawer, Grid, useMediaQuery, useTheme } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import PreviousComplaints from './PreviousComplaints.js';
import { useStyles } from './style.js';
import ComplaintsList from './ComplaintsList.js';
import { makeStyles } from '@mui/styles';

const ReportIssue = (props) => {
    const desktop=useMediaQuery(useTheme().breakpoints.down('md'))
    const useStyles = makeStyles((theme) => ({
        drawer: {
          zIndex: 1400,
        },
      }));
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [dialogopen, setDialogOpen] = React.useState(false);
    const openDrawer = () => {
        if(desktop)
        setDialogOpen(true);
        else
        setOpen(true);
        props.clearComplaintsProps();
        props.getPreviousComplaints(props.orderID);
        if (props.ordertype === 0)
            props.getcomplaintslist(100);
        else if (props.ordertype === 1)
            props.getcomplaintslist(0);
    };
    const drawerClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className={classes.reportissueheading}
            style={{color: '#EA4335',cursor:'pointer',fontWeight: 700,
            fontSize: '12px',
            lineHeight: '16px',}} onClick={() => openDrawer()}>
                {'REPORT ISSUE'}
            </div>
            <Drawer
                open={open}
                classes={{ paper: classes.drawer }}
                onClose={drawerClose}
                anchor='right'
                PaperProps={{style:{zIndex:1400,position:'absolute',backgroundColor: '#EAEDEF'}}}
                sx={{
                    '& .MuiDrawer-paper':
                    {
                        boxSizing: 'border-box',
                        width: { xs: '100%', sm: '100%', md: 500 },
                        backgroundColor: '#EAEDEF',
                        zIndex:1400,
                    },
                }}
            >
                <AppBar
                    position={'fixed'}
                    sx={{
                        top: 0,
                        bottom: "auto",
                        width: { xs: '100%', sm: '100%', md: 500 },
                        backgroundColor: "#fff",
                        border: 'none',
                        boxShadow: 'none',
                        height: "100px",
                        paddingTop: "10px",
                        color: '#000',
                        justifyContent: 'center'
                    }}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#000000'
                        }}>
                        <div style={{ paddingBottom: '10px', width: '100%' }}>
                            <Grid container style={{ width: '100%' }}>
                                <Grid item xs={0.5} />
                                <Grid item xs={2}
                                    justifyContent={'center'} textAlign={'center'} alignItems={'center'}>
                                    <span className={classes.leftarrow} onClick={() => drawerClose()}>
                                        <KeyboardBackspaceIcon />
                                    </span>
                                </Grid>
                                <Grid item xs={1} />
                                <Grid item xs={8} justifyContent={'left'} textAlign={'center'} alignItems={'center'}
                                    style={{ width: '100%' }}>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'left', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span
                                                style={{
                                                    color: '#000000',
                                                    fontStyle: 'normal',
                                                    fontWeight: 'bold',
                                                    fontSize: '24px',
                                                    lineHeight: '33px',
                                                }}>
                                                {'Help & Support'}
                                            </span>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </AppBar>
                <Box
                    style=
                    {{
                        paddingTop: '120px',
                        paddingBottom: '20px',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                        width: '100%',
                        paddingLeft: '20px',
                        paddingRight: '20px'
                    }}>
                <ComplaintsList 
                orderID={props.orderID}
                {...props}/>
                </Box>
                <Box
                    sx={{
                        bottom: 0,
                        width: { xs: '100%', sm: '100%', md: 500 },
                        backgroundColor: "#fff",
                        border: 'none',
                        boxShadow: 'none',
                        height: "auto",
                        paddingTop: "20px",
                        paddingBottom: '20px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        color: '#000',
                    }}>
                    <div className={classes.previousComplaints}>
                        {'Past Conversation'}
                    </div>
                    <Divider/>
                    <div style={{paddingBottom:'10px'}}/>
                  <PreviousComplaints 
                  orderID={props.orderID}
                  {...props}/>
                </Box>
            </Drawer>
            <Dialog
                                open={dialogopen}
                                maxWidth={'sm'}
                                fullScreen={true}
                                PaperProps={{style:{position:'relative',zIndex:1000, backgroundColor: '#EAEDEF',}}}
                                onClose={() => setDialogOpen(false)}
                            >
                                 <AppBar
                    position={'fixed'}
                    sx={{
                        top: 0,
                        bottom: "auto",
                        width: { xs: '100%', sm: '100%', md: 500 },
                        backgroundColor: '#fff',
                        border: 'none',
                        boxShadow: 'none',
                        height: "100px",
                        paddingTop: "10px",
                        color: '#000',
                        justifyContent: 'center'
                    }}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#000000'
                        }}>
                        <div style={{ paddingBottom: '10px', width: '100%' }}>
                            <Grid container style={{ width: '100%' }}>
                                <Grid item xs={0.5} />
                                <Grid item xs={2}
                                    justifyContent={'center'} textAlign={'center'} alignItems={'center'}>
                                    <span className={classes.leftarrow} onClick={() => setDialogOpen(false)}>
                                        <KeyboardBackspaceIcon />
                                    </span>
                                </Grid>
                                <Grid item xs={1} />
                                <Grid item xs={8} justifyContent={'left'} textAlign={'center'} alignItems={'center'}
                                    style={{ width: '100%' }}>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'left', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span
                                                style={{
                                                    color: '#000000',
                                                    fontStyle: 'normal',
                                                    fontWeight: 'bold',
                                                    fontSize: '24px',
                                                    lineHeight: '33px',
                                                }}>
                                                {'Help & Support'}
                                            </span>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </AppBar>
                <Box
                    style=
                    {{
                        paddingTop: '120px',
                        paddingBottom: '20px',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                        width: '100%',
                        paddingLeft: '20px',
                        paddingRight: '20px'
                    }}>
                <ComplaintsList 
                orderID={props.orderID}
                {...props}/>
                </Box>
                <Box
                    sx={{
                        bottom: 0,
                        width: { xs: '100%', sm: '100%', md: 500 },
                        backgroundColor: "#fff",
                        border: 'none',
                        boxShadow: 'none',
                        height: "auto",
                        paddingTop: "20px",
                        paddingBottom: '20px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        color: '#000',
                    }}>
                    <div className={classes.previousComplaints}>
                        {'Past Conversation'}
                    </div>
                    <Divider/>
                    <div style={{paddingBottom:'10px'}}/>
                  <PreviousComplaints 
                  orderID={props.orderID}
                  {...props}/>
                </Box>
                                </Dialog>
        </>
    )
}

export default ReportIssue