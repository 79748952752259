import React from 'react';
import producterror from '../../Assets/Images/producterror.png';
import all from '../../Assets/Images/all.svg';
import './style.css';

const Businesstypes = (props) => {
    const [selectedindex, setSelectedIndex] = React.useState('');
    const findIconImage = (type) => {
        let array = props.BusinessType.filter((b) =>
            b.id === type
        )
        return array.map(a => a.iconImage)
    }
    const removeDuplicates = () => {
        let filteredbusinesstypelist = [];
        props.BusinessType.map((bt) =>
            props.AllStores.map((store) =>
                store.businessTypeId.map((b) => {
                    if (b === bt.id)
                        filteredbusinesstypelist = [...filteredbusinesstypelist, bt];
                }
                )
            )
        )
        return [...new Set(filteredbusinesstypelist)]
    }

    React.useEffect(() => {
        props.getBusinessTypes();
    },
        // eslint-disable-next-line 
        [localStorage.getItem('lattitude'), localStorage.getItem('longitude')])

    return (
        <>
            <div className='businesstype-container'>
                <div className='horizontal-scroll'>
                    {props.isBusinessTypeSuccess && props.isBusinessTypeSuccess === true
                        && props.BusinessType && props.BusinessType !== null &&
                        props.AllStores && props.AllStores !== null && props.AllStores.length > 0 ?
                        <div className='category-container'>
                            <div className='category-div'>
                                <div className={selectedindex === '' ? 'category-circle-selected' : 'category-circle'}
                                    onClick={() => {
                                        setSelectedIndex('');
                                        props.filterStores("All", props.AllStores)
                                    }}>
                                    <img src={all} alt="category" className='category-circle-img' />
                                </div>
                                <div className='category-name'>
                                    {'All'}
                                </div>
                            </div>
                            {removeDuplicates().map((b, i) =>
                                <div className='category-div'>
                                    <div className={i === selectedindex ? 'category-circle-selected' : 'category-circle'}
                                        onClick={() => {
                                            setSelectedIndex(i);
                                            props.filterStores(b.id, props.AllStores)
                                        }}>
                                        <img src={(findIconImage(b.id) === null || findIconImage(b) === []) ? producterror : findIconImage(b.id)} alt="category" className='category-circle-img' />
                                    </div>
                                    <div className='category-name'>
                                        {b.name}
                                    </div>
                                </div>
                            )}
                        </div>
                        : ""}
                </div>
            </div>
        </>
    )
}

export default Businesstypes