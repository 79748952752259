import React from "react";
import { useStyles } from "./style";
import checkicon from "../../Assets/Images/checkicon.svg";
import loadicon from "../../Assets/Images/loadicon.svg";

const PlaceOrder = (props) => {
 
  const classes = useStyles();
  return (
    //     <div>
    //         {(props.orderplaced && props.orderplaced === true) ?
    //           <div className={classes.placeorderDiv}>
    //             <img src={checkicon} className={classes.checkicon} alt="check" />
    //             <div className={classes.successmessage}>
    //               Order Successful
    //             </div>
    //             <div className={classes.keepbrowsing}>
    //               <div className={classes.keepbrowsingbutton} onClick={() => {
    //                 props.navigate('/stores');
    //                 props.resetCheckoutProps()
    //               }}>
    //                 {'Keep Shopping'}
    //               </div>
    //             </div>
    //           </div>
    //           :
    //           props.placeorderwaiting === true ?
    //             <div className={classes.payment}>
    //               <img src={loadicon} className={classes.loadicon} alt="check" />
    //               <div className={classes.loadmessage}>
    //                 Awaiting Payment
    //               </div>
    //             </div>
    //             :null
    // }
    // </div>

    <div className={classes.placeorderDiv}>
      <img src={checkicon} className={classes.checkicon} alt="check" />
      <div className={classes.successmessage}>Order Successful</div>
      <div className={classes.keepbrowsing}>
        <div
          className={classes.keepbrowsingbutton}
          onClick={() => {
            props.navigate("/stores");
            props.resetCheckoutProps();
          }}
        >
          {"Keep Shopping"}
        </div>
      </div>
    </div>
  );
};

export default PlaceOrder;
