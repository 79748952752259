import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    root: {
        background: 'rgba(209, 216, 224, 0.5)',
        boxShadow: '0px 8px 44px rgba(0, 0, 0, 0.04)',
        borderRadius: '24px',
        width: 'auto',
        height: 'auto',
        padding: '40px',
    },
    headingdiv: {
        background: '#D1D8E0',
        borderRadius: '10px',
        width: '100%',
        height: 'auto',
        padding: '10px',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        display:'flex',
        color:'#061303',
        fontSize:'18px',
        fontWeight:600,
        lineHeight:'25px',
        marginTop:'40px',
    },
    addresscard: {
        boxShadow:'0px 8px 44px rgba(0, 0, 0, 0.04)',
        backgroundColor:'#ffffff',
        borderRadius:'13px',
        padding:'30px',
        display:'flex',
        position:'relative'
    },
    addressdiv: {
        display:'flex',
        flexDirection:'column',
        float:'left',
        textAlign:'left',
        width:'100%',
    },
    updationdiv: {
        display:'flex',
        float:'right',
        textAlign:'right',
        position:'absolute',
        right:10,
    },
    addresstitle: {
        color:'#061303',
        fontSize:'14px',
        fontWeight:700,
        lineHeight:'19px',
    },
    mobileroot: {
        background: '#FFFFFF',
        boxShadow: '0px 8px 44px rgba(0, 0, 0, 0.04)',
        borderRadius: '24px',
        width: '100%',
        height: 'auto',
        paddingTop:'20px',
        paddingBottom:'20px',
    },
    title: {
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '20px',
        lineHeight: '25px',
        textAlign: 'center',
        color: '#000000',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    formlabel: {
        display:'flex',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '25px',
        textAlign: 'left',
        paddingTop: '20px',
        
    },
    Editlabel: {
        display:'flex',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '25px',
        justifyContent:'left',
        textAlign: 'left',
        paddingTop: '20px',
        
    },
    label1:{
        color: '#EA4335',
    },
    label2:{
        color: 'rgba(0,0,0,0.7)',
    },
    input: {
        background: '#FFFFFF',
        border: 'none',
        boxSizing: 'border-box',
        borderRadius: '8px',
        height: '45px',
        width: '100%',
        '& :-webkit-autofill': {
            backgroundColor: '#FFFFFF',
            height: '0px',
          }, 
    },
    titleDiv: {
        display: 'flex',
        paddingBottom: '25px',
    },
    addaddress: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '25px',
        textAlign: 'right',
        color: '#000000',
        float: 'right',
        width: '50%',
        alignItems: 'right',
    },
    addaddressButton: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '19px',
        textAlign: 'center',
        justifyContent:'center',
        alignItems:'center',
        display:'flex',
        color: '#fff',
        width: '93px',
        height:'27px',
        background: '#85BE49',
        borderRadius: '15.5px',
        cursor: 'pointer',
    },
    addresspopup: {
        border: 'none', 
        backgroundColor: 'rgba(196, 196, 196, 0.2)', 
        color: 'rgba(0,0,0,0.5)',
        fontSize: '12px', 
        borderRadius: '15px', 
        paddingLeft: '10px', 
        paddingRight: '10px', 
        paddingTop: '6px',
        paddingBottom: '6px', 
        fontWeight: 600, 
        cursor: 'pointer'
    },
    addressDiv: {
        background: '#FFFFFF',
        boxShadow: '0px 8px 44px rgba(0, 0, 0, 0.04)',
        borderRadius: '13px',
        width: '90%',
        padding: '20px',
        display: 'flex',
    },
    editAddress: {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        paddingRight: '25px',
        color: '#2D93FB',
    },
    houseName: {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '19px',
        color: '#061303',
    },
    houseLocation: {
        fontWeight: 600,
        fontSize: '13px',
        lineHeight: '18px',
        color: '#061303',
        opacity: 0.5, 
        width:'100%',
        overflow:'hidden',
        paddingTop:'5px',
        display:'inline-block',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap',
    },
    locationDescription:{
        overflow:'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap',
        width:'100%',
        display:'inline-block',
    },
    modalHeading: {
        fontWeight: 1000,
        fontSize: '18px',
        lineHeight: '25px',
        textAlign: 'center',
        color: '#000000',
        fontFamily:'Nunito',
    },
    modaltitle: {
        color:'#061303',
        fontSize:'18px',
        lineHeight:'25px',
        fontWeight:600,
    },
    button: {
      background: '#85BE49',
      color: '#FFFFFF',
      fontSize: '16px',
      fontWeight: 600,
      alignItems:'center',
      justifyContent:'center',
      textAlign: 'center',
      borderRadius: '12px',
      border: `1px #85BE49`,
      width: '40%',
      cursor: 'pointer',
    },
    buttondisabled: {
        background:'#CCCCCC',
        color: '#FFFFFF',
        fontSize: '14px',
        lineHeight: '19px',
        fontWeight: 'bold',
        textAlign: 'center',
        borderRadius: '12px',
        padding: '10px',
        border: `1px #89C74A`,
        width: '100%',
        cursor: 'pointer',
    },
    buttonCancel: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        background: '#ffffff',
        color: '#85BE49',
        fontSize: '16px',
        lineHeight: '19px',
        fontWeight: 600,
        textAlign: 'center',
        borderRadius: '12px',
        border: `1px solid #85BE49`,
        padding: '10px',
        width: '100%',
        cursor: 'pointer',
    },
    buttonDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        paddingTop: '10px',
        cursor: 'pointer',
    },
    addressbuttonDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        paddingBottom: '10px',
        cursor: 'pointer',
        position:'absolute',
        zIndex:2,
        right:0,
        top:-12,
    },
    active: {
        background: 'linear-gradient(180deg, #FFD951 0%, #FFC800 100%, #FFC800 100%)',
        borderRadius: '5px',
        padding: '10px',
        color: '#061303',
        fontSize: '14px',
        width: '94px',
        cursor: 'pointer',
        height:'29px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    },
    ordertab: {
        background:'#D1D8E0',
        borderRadius: '5px',
        padding: '10px',
        color: '#061303',
        fontSize: '14px',
        width: '94px',
        cursor: 'pointer',
        height:'29px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    },
    leftarrow: {
        background:'#EAF6E1',
        width:'30px',
        height:'30px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        borderRadius:'50%',
        cursor:'pointer',
      },
    numberactive: {
        float: 'right',
        width: '18px',
        borderRadius: '50%',
        background: '#FFFFFF',
        color: '#061303',
        fontSize: '12px',
        height: '18px',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    numbercomplete: {
        float: 'right',
        width: '18px',
        borderRadius: '25px',
        backgroundColor: '#85BE49',
        color: '#ffffff',
        fontSize: '12px',
        height: '18px',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    noordersDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '40px',
        width: '100%',
        height: '100%',
    },
    noordersimage: {
        maxHeight: '100%',
        maxWidth: '100%',
        width: '139px',
        height: '139px',
    },
    noordersheading: {
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'center',
        color: '#000000',
    },
    noorderstext: {
        fontWeight: 600,
        fontSize: '13px',
        textAlign: 'center',
        color: 'rgba(6, 19, 3, 0.5)',
    },
    valueDiv:{
        background: 'rgba(133, 190, 73, 0.2)',
        borderRadius: '10px',
        display: 'flex',
        textAlign: 'left',
        color: '#69A85C',
        fontWeight: 600,
        fontSize: '12px',
        paddingLeft: '15px',
        paddingTop: '15px',
        paddingBottom: '15px',
    },
   textDiv:{
        display: 'flex',
        textAlign: 'left',
        color: '#69A85C',
        fontWeight: 600,
        fontSize: '12px',
        paddingBottom: '15px',
    },
    orderDetailsHeading:{
        display: 'flex',
        width:'100%',
    },
    ordermodalHeading: {
        fontSize:'12px',
        display:'flex',
        fontWeight:800,
        float:'left',
        textAlign:'left',
        width:'100%',
    },
    reportissueDiv: {
        display: 'flex',
        float:'right',
        textAlign:'right',
        width: '100%',
        justifyContent:'right',
    },
    ordernumberfirst: {
        color:'rgba(6, 19, 3, 0.3)',
    },
    ordernumbersecond: {
        color:'rgba(6, 19, 3, 1)',
    },
    addaddressCard: {
        boxShadow:'0px 8px 44px rgba(0, 0, 0, 0.04)',
        border:'1px dashed rgba(0, 0, 0, 0.3)',
        borderRadius:'13px',
        background:'#FFFFFF',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        height:'98px',
        cursor:'pointer',
        color:' #69A85C',
        fontSize:'14px',
        lineHeight:'19px',
        fontWeight:600,
    },
    suggestionDiv: {
        backgroundColor:'#ffffff',
        color:'#000000',
        fontSize:'12px',
        fontWeight:600,
        lineHeight:'19px',
        position:'absolute',
        zIndex:2,
        width:'400px',
    },
    suggestionsHeading: {
        color:'#BDBDBD',
        fontSize:'14px',
        paddingBottom:'20px',
    },
    suggestionList:{
        paddingTop:'15px',
        width:'100%',
        cursor:'pointer',
        '&:hover':{
            backgroundColor:'rgba(189, 189, 189, 0.1)'
        }
    },
}));