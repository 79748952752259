import React from 'react';
import { Grid } from '@mui/material';
import * as Yup from "yup";
import { useFormik } from "formik";
// import { buttongreen } from '../../Assets/Colors';
import PublicIcon from '@mui/icons-material/Public';
import './index.css';

const validationSchema = Yup.object().shape({
  customername: Yup.string()
    .max(20, "Length exceeded")
    .matches(/^[a-zA-Z]+$/, 'only alphabets are allowed')
    .required("Please enter friend's name"),
  email: Yup.string().email().required("Please enter friend's email address"),
});

const EmailInvite = (props) => {
  const formik = useFormik({
    initialValues: {
      customername: "",
      email: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.ReferFriend(
        values.customername,
        values.email
      );
      formik.touched.customername = false;
      formik.touched.email = false;
      formik.values.customername = "";
      formik.values.email = "";
    },
  });
  React.useEffect(() => {
    formik.values.customername = "";
    formik.values.email = "";
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  return (
    <>
        <Grid container>
          <Grid item xs={12} md={12}>
            <div style={{
              color: "#061303",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: '22px',
              paddingBottom: "7px",
              paddingTop: "40px",
            }}>
              {'Send an email invite'}
            </div>
          </Grid>
          <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div
              style={{
                width: '100%',
                height: '45px',
                backgroundColor: 'rgba(105, 168, 92, 0.1)',
                borderRadius: '13px',
                border: '1px solid rgba(0,0,0,0.1)',
                boxShadow: '0px 8px 44px rgba(0, 0, 0, 0.04)',
                color: 'rgba(105, 168, 92, 0.5)',
                fontSize: '14px',
                paddingLeft: '5px',
                paddingRight: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div
                style={{
                  backgroundColor: 'rgba(105, 168, 92, 0.2)',
                  borderRadius: '9px',
                  width: '40px',
                  height: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center'
                }}>
                <PublicIcon />
              </div>
              <input
                placeholder="Friend's Name"
                name='customername'
                value={formik.values.customername}
                onChange={formik.handleChange}
                style={{
                  background: 'transparent',
                  display: 'flex',
                  border: 'none',
                  width: '100%',
                  textAlign: 'left',
                  fontFamily: 'Nunito',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '1rem',
                  lineHeight:'1.25rem',
                  paddingLeft: '13px',
                  color: 'rgba(105, 168, 92, 0.8)'
                }}
                type="text"
              />
             
            </div>
            {formik.errors.customername && formik.touched.customername ? (
           <div className='FError'>{`${formik.errors.customername}`}</div>
          ) : null}
          </Grid>
          <Grid item md={0.5} xs={0}/>
          <Grid item md={4} xs={12}>
            <div
              style={{
                width: '100%',
                height: '45px',
                backgroundColor: 'rgba(105, 168, 92, 0.1)',
                borderRadius: '13px',
                border: '1px solid rgba(0,0,0,0.1)',
                boxShadow: '0px 8px 44px rgba(0, 0, 0, 0.04)',
                color: 'rgba(105, 168, 92, 0.5)',
                fontSize: '14px',
                paddingLeft: '5px',
                paddingRight: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div
                style={{
                  backgroundColor: 'rgba(105, 168, 92, 0.2)',
                  borderRadius: '9px',
                  width: '40px',
                  height: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center'
                }}>
                <PublicIcon />
              </div>
              <input
                label="Email address"
                placeholder='Email address'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                style={{
                  background: 'transparent',
                  display: 'flex',
                  border: 'none',
                  width: '100%',
                  textAlign: 'left',
                  fontFamily: 'Nunito',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '1rem',
                  lineHeight:'1.25rem',
                  paddingLeft: '13px',
                  color: 'rgba(105, 168, 92, 0.8)'
                }}
                // ref={(input) => this.input = input}
                type="text"
              />
            </div>
            {formik.errors.email && formik.touched.email ? (
           <div className='FError'>{`${formik.errors.email}`}</div>
          ) : null}
          </Grid>
          <Grid item md={0.5} xs={0}/>
          <Grid item md={3} xs={12} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
            <button
              type="button"
              style={{
                background: '#85BE49',
                color: '#FFFFFF',
                fontSize: '0.875rem',
                lineHeight: '16px',
                fontWeight: 'bold',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '15.5px',
                height: '45px',
                border: 'none',
                width: '100%',
                cursor: 'pointer',
              }}
              onClick={formik.handleSubmit}
            >
              {props.SendingInvite ? 'Sending Invite' : 'Send Invite'}
            </button>
          </Grid>
          </Grid>
        </Grid>
    
    </>
  )
};

export default EmailInvite;
