import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
     width:'100%',
     display:'flex',
     flexDirection:'column',
     backgroundColor:'#EAEEF7',
     borderRadius:'24.5px',
     justifyContent:'center',
     alignItems:'center',
    textAlign:'center',
     paddingLeft:'40px',
     paddingRight:'40px',
     paddingTop:'15px',
     paddingBottom:'15px',
     height:'100%',
     marginRight:'20px'
    },
    autocompleteDropdownContainer:{
        background:'#ffffff',
        position:'absolute',
        zIndex:2,
        borderRadius:'30px',
        width:'100%',
        marginTop:'40px',
        textAlign:'left',
        justifyContent:'left',
        display:'flex',
        flexDirection:'column',
        alignItems:'left',
    },
    locationname: {
        display:'flex',
        flexDirection:'column',
        textAlign:'left',
        width:'100%',
        justifyContent:'left',
    },
    location: {
        textDecoration:'none',
        listStyle:'none',
        textAlign:'left',
        float:'left',
    },
    mainsuggestion: {
        textAlign:'left',
        float:'left',
        color:'rgb(23, 30, 48)',
        fontSize:'14px',
        fontWeight:800
    },
    subsuggestion: {
        color:'rgb(111, 117, 136)',
        fontSize:'12px',
        textAlign:'left',
    },
    locationlist: {
        listStyleType:'none'
    },
     mobileformdiv: {
        width:'100%',
     display:'flex',
     flexDirection:'column',
     backgroundColor:'#EAEEF7',
    //  borderRadius:'24.5px',
     justifyContent:'center',
     alignItems:'center',
     paddingLeft:'40px',
     paddingRight:'40px',
     padding:0,
     paddingTop:'15px',
     paddingBottom:'15px',
     height:'100%',
      },
    formlogo:{
        maxWidth:'100%',
        maxHeight:'100%',
        width:'160px',
        height:'40px',
    },
    title: {
        fontWeight:700,
        fontSize:'22px',
        lineHeight:'33.3px',
        textAlign:'center',
        color:'#000000',
        paddingBottom:'10px',
    },
    forgotpasswordlink:{
        fontSize:'22px',
        lineHeight:'33.3px',
        textAlign:'center',
        color:'#89C74A',
        fontWeight:900,
        paddingBottom:'10px',
    },
    subtitle: {
        fontWeight:600,
        fontSize:'14px',
        lineHeight:'19px',
        color:'#000000',
        paddingBottom:'15px',
    }, 
    formlabel: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '25px',
        textAlign: 'left',
        color: '#000000',
        paddingBottom:'6px',
        paddingTop:'10px',
        width:'100%',
    },
    input: {
        background: '#FFFFFF',
        border: '1px solid rgba(133, 190, 73, 0.19)',
        boxSizing: 'border-box',
        borderRadius: '8px',
        height: '45px',
        width:'100%',
        textAlign:'left'
    },
    otpinput: {
        // background: '#FFFFFF',
        // border: '1px solid rgba(133, 190, 73, 0.19)',
        boxSizing: 'border-box',
        borderRadius: '8px',
        height: '45px',
        width:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        // paddingLeft:'20px',
        // paddingRight:'20px',
        // paddingTop:'5px',
    },
    buttonDiv: {
        display:'flex',
        width:'100%',
        paddingTop:'20px',
    },
    button: {
        width:'100%',
        border:'none',
        background:'rgba(133,190,73,0.2)',
        color:'rgba(0,0,0,0.5)',
        borderRadius:'26.5px',
        fontSize:'14px',
        lineHeight:'19px',
        fontWeight:700,
        cursor:'pointer',
        height:'40px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        paddingLeft:'18px',
        paddingRight:'18px',
    },
    buttonActive: {
        width:'100%',
        border:'none',
        background:'#89C74A',
        color:'rgba(255, 255, 255,1)',
        borderRadius:'26.5px',
        fontSize:'14px',
        lineHeight:'19px',
        fontWeight:900,
        cursor:'pointer',
        height:'40px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        paddingLeft:'18px',
        paddingRight:'18px',
    },
    buttonMobile: {
        width:'100%',
        border:'none',
        background:'rgba(133, 190, 73, 0.2)',
        color:'rgba(0,0,0,0.4)',
        borderRadius:'26.5px',
        fontSize:'14px',
        lineHeight:'19px',
        fontWeight:600,
        cursor:'pointer',
        height:'40px',  display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        paddingLeft:'18px',
        paddingRight:'18px',
    },
    buttondisabled:{
        width:'100%',
        border:'none',
        background: '#CCCCCC',
        color:'#fff',
        borderRadius:'26.5px',
        fontSize:'14px',
        lineHeight:'19px',
        fontWeight:600,
        cursor:'pointer',
        height:'40px',  display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        paddingLeft:'18px',
        paddingRight:'18px',
    },
    haveanaccount: {
        display:'flex',
        width:'100%',
        paddingTop:'25px',
        textAlign:'center',
        alignItems:'center',
        justifyContent:'center',
    },
    title1: {
        color:'#000000',
        fontSize:'14px',
        fontWeight:600,
        lineHeight:'19px', 
    },
    title2: {
        color:'#89C74A',
        fontSize:'14px',
        fontWeight:800,
        lineHeight:'19px',
        cursor:'pointer',
    },
    formheading: {
        color: '#EAF6E1',
        fontSize: '24px',
        lineHeight: '33px',
        fontWeight: 600,
        padding: 0,
    },
    formsubheading: {
        color: '#EAF6E1',
        fontSize:'17px',
        lineHeight:'26px',
        fontWeight:400,
        paddingTop:'10px'
    },
    inputFieldDiv: {
        paddingTop:'20px',
        width: '100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
    },
    inputField: {
        background: '#FFFFFF',
        border: '1px solid #E2F6B9',
        borderRadius: '30px',
        height:'56px',
        display:'flex',
        // paddingLeft:'10px',
        // paddingRight:'70px',
        alignItems:'center',
        textAlign:'center',
        // width:'50%'
    },
    ButtonDiv: {
        paddingTop:'30px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        width:'100%',
    },
    continueButton: {
        background:'linear-gradient(180deg, #85BE49 0%, #69A85C 100%)',
        width:'100%',
        cursor:'pointer',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        paddingTop:'15px',
        paddingBottom:'15px',
        fontSize:'18px',
        fontWeight:700,
        color:'#ffffff',
        lineHeight:'25px',
        boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    loginHeading: {
        cursor:'pointer',
    },
    link: {
        color:'#EAF6E1',
        fontSize:'17px',
        lineHeight:'26px',
        fontWeight:400,
        paddingTop:'30px'
    },
    signuplink: {
        color:'#EAF6E1',
        fontSize:'17px',
        lineHeight:'26px',
        fontWeight:400,
        paddingTop:'20px'
    },
    forgotpassword: {
        width:'100%',
        textAlign:'right',
        alignItems:'right',
        justifyContent:'right',
        display:'flex',
        color:'rgba(133, 190, 73, 1)',
        fontSize:'18px',
        fontWeight:600,
        lineHeight:'25px',
        paddingRight:'20px',
    },
    inputDiv: {
        paddingBottom: '30px', 
        width: '100%', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
    buttonsDiv: {
        display:'flex',
        width:'100%',
    },
    leftSpan: {
        float:'left',
        width:'60%',
        padding:'15px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center'
    },
    rightSpan: {
        float:'right',
        width:'40%',
        padding:'15px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center'
    },
    leftButton: {
        background: '#ffffff',
        height:'56px',
        width:'100%',
        border:'1px solid #69A85C',
        color:'#85BE49',
        fontWeight:400,
        fontSize:'18px',
        lineHeight:'25px',
        // boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius:'12px',
        cursor:'pointer',
    },
    rightButton: {
        background: '#85BE49',
        height:'56px',
        width:'100%',
        border:'none',
        color:'#ffffff',
        fontWeight:400,
        fontSize:'18px',
        lineHeight:'25px',
        // boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius:'12px',
        cursor:'pointer',
    },
    haveanaccountDiv: {
        display:'flex',
        width:'100%',
        justifyContent:'center',
        textAlign:'center',
        alignItems:'center',
        fontSize:'17px',
        lineHeight:'26px',
        paddingTop:'30px',
    },
    modalHeading: {
        fontWeight: 1000,
        fontSize: '18px',
        lineHeight: '25px',
        textAlign: 'center',
        color: '#000000',
        fontFamily:'Nunito',
        // display:'flex',
        // width:'100%',
    },
    leftText: {
        color:'rgba(133, 190, 73, 1)',
        fontWeight:400,
    },
    rightText: {
        color:'rgba(133, 190, 73, 1)',
        fontWeight:600,
    },
  }));