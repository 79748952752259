import React from 'react';
import { logo } from '../../Assets/image';
import landingpageimage from '../../Assets/Images/landingpageimage.webp';
import LocationForm from '../Forms/LocationForm';
import { useMediaQuery, useTheme } from '@mui/material';
import ExploreButton from '../Buttons/ExploreButton';
import './style.css';

const Banner = (props) => {
  const desktop = useMediaQuery(useTheme().breakpoints.up('sm'));
  return (
    <>
      {desktop ?
        <div className="big-wrapper light">
          <img
            src={landingpageimage}
            alt="NearShopz"
            className="shape"
            loading='lazy'
            title='NearShopz'
            width='210px' height='auto' />
          <div className="showcase-area">
            <div className="containerdiv">
              <div className="left">
                <div 
                className="logo" 
                style={{ 
                  textAlign: 'left', 
                  width: '100%', 
                  justifyContent: 'left'
                }}>
                  <img
                    src={logo}
                    alt="NearShopzLogo"
                    className='nearshopzlogo'
                    loading='lazy'
                    title='NearShopzLogo'
                    width='243.13px'
                    height='60px' />
                </div>
                <div className="big-title">
                  <h1>Your All-in-One Delivery App
                    <br />For <span className='shopsnearby'>Everyday Needs</span></h1>
                </div>
                <p class="text">
                Order Groceries, Food, Essentials & More:
                  <span className='oneatatime'>
                    &nbsp;Fast Delivery App – NearShopz
                  </span>
                </p>
                <ExploreButton {...props} />
              </div>
              <div className="right">
                <LocationForm {...props} />
              </div>
            </div>
          </div>
        </div>
        :
        <LocationForm {...props} />
      }
    </>
  )
}

export default Banner
