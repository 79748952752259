import { Default } from "./initialState";

export const LoginReducer = (state = Default(), action) => {
    switch (action.type) {
        case "FETCHING_PLACE":
            return {
                ...state,
                isLoadingPlaces: true,
                isPlaceDescription: false,
                isPlaceDescriptionFailed: false,
            };
        case "PLACE_DESCRIPTION_SUCCESS":
            return {
                ...state,
                isLoadingPlaces: false,
                isPlaceDescription: true,
                isPlaceDescriptionFailed: false,
                PlaceDescription: action.payload
            };
        case "PLACE_DESCRIPTION_FAILED":
            return {
                ...state,
                isLoadingPlaces: false,
                isPlaceDescription: false,
                isPlaceDescriptionFailed: true,
                PlaceDescriptionError: action.payload
            };
        case "REMOVE_LOCATION_PROPS":
            return{
                ...state,
                isLoadingPlaces: false,
                isPlaceDescription: false,
                isPlaceDescriptionFailed: false,
                PlaceDescriptionError: null
            };
            case "FETCHING__CURRENT_LOCATION":
            return {
                ...state,
                isLoadingCurrentLocation: true,
                isCurrentLocation: false,
                isCurrentLocationFailed: false,
            };
        case "CURRENT_LOCATION_SUCCESS":
            return {
                ...state,
                isLoadingCurrentLocation: false,
                isCurrentLocation: true,
                isCurrentLocationFailed: false,
                CurrentLocation: action.payload.data,
                Currentlat:action.payload.setlat,
                Currentlng:action.payload.setlng
            };
        case "CURRENT_LOCATION_FAILED":
            return {
                ...state,
                isLoadingCurrentLocation: false,
                isCurrentLocation: false,
                isCurrentLocationFailed: true,
                CurrentLocationError: action.payload
            };
        case "LOGGING_IN":
            return {
                ...state,
                isLoggingIn: true,
                isLogInSuccess: false,
                isLogInFailed: false,
            };
        case "LOGIN_SUCCESS":
            return {
                ...state,
                isLoggingIn: false,
                isLogInSuccess: true,
                isLogInFailed: false,
                Login: action.payload
            };
        case "LOGIN_FAILED":
            return {
                ...state,
                isLoggingIn: false,
                isLogInSuccess: false,
                isLogInFailed: true,
                loginerror: action.payload
            };
        case "SIGNING_UP":
            return {
                ...state,
                isSigningUp: true,
                isSignUpSuccess: false,
                isSignUpFailed: false,
            };
        case "SIGNUP_SUCCESS":
            return {
                ...state,
                isSigningUp: false,
                isSignUpSuccess: true,
                isSignUpFailed: false,
                Signup: action.payload
            };
        case "SIGNUP_FAILED":
            return {
                ...state,
                isSigningUp: false,
                isSignUpSuccess: false,
                isSignUpFailed: true,
                signuperror: action.payload
            };
        case "SEND_OTP_SUCCESS":
            return {
                ...state,
                isOTP: true,
                isOTPFailed: false,
                SendOTP: action.payload.SendOTP,
                phoneNumber: action.payload.phoneNumber,
                isOTPCleared: false,
            };

        case "SEND_OTP_FAILED":
            return {
                ...state,
                errorMessage: { message: action.payload },
                isOTP: false,
                isOTPFailed: true,
                isOTPCleared: false,
            };
        case "SEND_OTP_ERROR":
            return {
                ...state,
                isOTP: false,
                isOTPFailed: true,
                Message: "Network Error",
                isOTPCleared: false,
            };
        case "OTP_LOGIN_ACCESS_WAITING":
            return {
                ...state,
                isVerifyOTP: false,
                isVerifyingOTP: true,
                isVerifyOTPFailed: false,
                isOTPCleared: false,
            };
        case "OTP_LOGIN_ACCESS_SUCCESS":
            return {
                ...state,
                isVerifyingOTP: true,
                isVerifyOTP: true,
                isVerifyOTPFailed: false,
                VerifyOTP: action.payload.data.loginMobile,
                isOTPCleared: false,
            };

        case "OTP_LOGIN_ACCESS_FAILED":
            return {
                ...state,
                isVerifyingOTP: false,
                isVerifyOTP: false,
                isVerifyOTPFailed: true,
                verifyOTPerror: action.payload.message,
                isOTPCleared: false,
                // VerifyOTP: false,
            };
        case "OTP_LOGIN_ACCESS_ERROR":
            return {
                ...state,
                isVerifyingOTP: false,
                isVerifyOTP: false,
                verifyOTPerror: "Network Error",
                isOTPCleared: false,
            };
        // case "CLOSE_SNACKBARS":
        //     return {
        //         ...state,
        //         isLogInSuccess: false,
        //         isLogInFailed: false,
        //         isSignUpSuccess: false,
        //         isSignUpFailed: false,
        //         isOTPFailed:false,
        //         isOTPCleared:true,
        //         isVerifyOTPFailed:false,
        //     };
        case "MOBILE_SIGNUP_WAITING":
            return {
                ...state,
                isMobileSignup: false,
                MobileSignupfailed: false,
                phoneNumber: action.payload,
            };
        case "MOBILE_SIGNUP_SUCCESS":
            return {
                ...state,
                isMobileSignup: true,
                MobileSignupfailed: false,
                mobilesuccessData: action.payload,
                isOTP: true,
            };

        case "MOBILE_SIGNUP_FAILED":
            return {
                ...state,
                mobileerrorMessage: action.payload,
                MobileSignupfailed: action.payload === 'Mobile Number not verified' ? false : true,
                isMobileSignupFailed: true,
                isMobileSignup: action.payload === 'Mobile Number not verified' ? true : false,
                isOTP: false,
            };
        case "MOBILE_SIGNUP_ERROR":
            return {
                ...state,
                isMobileSignup: false,
                MobileSignupfailed: true,
                isMobileSignupFailed: true,
                incorrectMessage: "Network Error",
            };
        case "RESET_WAITING":
            return {
                ...state,
                isResetWaiting: true,
                isReset: false,
                isResetFailed: false,
            };

        case "RESET_SUCCESS":
            return {
                ...state,
                isReset: true,
                isResetWaiting: false,
                isResetFailed: false,
                ResetPassword: action.payload,
            };

        case "RESET_FAILED":
            return {
                ...state,
                reseterrorMessage: action.payload,
                isResetWaiting: false,
                isResetFailed: true,
                isReset: false,
            };
        case "RESET_ERROR":
            return {
                ...state,
                isReset: false,
                incorrectMessage: "Network Error",
            };
        case "RESET_FORGOR_PASSWORD_PROPS":
            return {
                ...state,
                isReset: false,
                isResetWaiting: false,
                isResetFailed: false,
                isOTP: false,
            };
        case "RESET_MOBILE_SIGNIN_PROPS":
            return {
                ...state,
                isOTP: false,
                isVerifyOTP: false,
                isMobileSignup: false,
            };
        case "SET_CART_LIMIT":
            return {
                ...state,
                isCartLimit: true,
            };
        case "CLEAR_LOCATION_SUGGESTIONS":
            return {
                ...state,
                isPlaceDescription: false,
                isPlaceDescriptionFailed: false,
                isCoordinates: false,
                PlaceDescription: null,
            };
        case "CLOSE_SNACKBARS":
            return {
                ...state,
                isAddaddress: false,
                isAddaddressFailed: false,
                isAddressesFailed: false,
                isEditaddressFailed: false,
                isEditaddress: false,
                isDeleteAddress: false,
                InviteSentSuccess: false,
                InviteSentFailed: false,
                isEmail: false,
                isEmailFailed: false,
                passworderror: false,
                UpdatedPassword: false,
                addaddresserrorMessage: null,
                isLogInSuccess: false,
                isLogInFailed: false,
                isSignUpSuccess: false,
                isSignUpFailed: false,
                isOTPFailed: false,
                isOTPCleared: true,
                isVerifyOTPFailed: false,
                // isOTP: false,
                isMobileSignup: false,
                isMobileSignupFailed: false,
                MobileSignupfailed: false,
                isSendOTPFailed: false,
                isCartLimit: false,
                isResetFailed: false,
                isEditAccount: false,
                VerifyOTP: false,
                ComplaintRegistration: false,
            };
        case "RESET_OTP_PROPS":
            return {
                ...state,
                isOTP: false,
                VerifyOTP: false,
            };
        case "STORES_LOADING":
            return {
                ...state,
                isStoresLoading: true,
                isStoresSuccess: false,
                businessTypeSeleced: false,
                isStoresFailed: false,
            };
        case "STORES_SUCCESS":
            return {
                ...state,
                isStoresLoading: false,
                isStoresSuccess: true,
                isStoresFailed: false,
                businessTypeSeleced: false,
                AllStores: action.payload,
                Stores: action.payload
            };
        case "STORES_FAILED":
            return {
                ...state,
                isStoresLoading: false,
                isStoresSuccess: true,
                isStoresFailed: false,
                businessTypeSeleced: false,
                Stores: action.payload
            };
        case "STORE_DETAILS_LOADING":
            return {
                ...state,
                isStoreDetailsLoading: true,
                isStoreDetailsSuccess: false,
                isStoreDetailsFailed: false,
            };
        case "STORE_DETAILS_SUCCESS":
            return {
                ...state,
                isStoreDetailsLoading: false,
                isStoreDetailsSuccess: true,
                isStoreDetailsFailed: false,
                StoreDetails: action.payload
            };
        case "STORE_DETAILS_FAILED":
            return {
                ...state,
                isStoreDetailsLoading: false,
                isStoreDetailsSuccess: true,
                isStoreDetailsFailed: false,
                StoreDetails: action.payload
            };
        case 'BUSINESSTYPES_LOADING':
            return {
                ...state,
                isBusinessTypeLoading: true,
                isBusinessTypeSuccess: false,
                isBusinessTypeFailed: false,
            };
        case 'BUSINESSTYPES_SUCCESS':
            return {
                ...state,
                isBusinessTypeLoading: false,
                isBusinessTypeSuccess: true,
                isBusinessTypeFailed: false,
                BusinessType: action.payload
            };
        case 'BUSINESSTYPES_FAILED':
            return {
                ...state,
                isBusinessTypeLoading: false,
                isBusinessTypeSuccess: false,
                isBusinessTypeFailed: true,
            };
        case "FILTER_STORES":
            let BusinessType = action.payload.businessType;
            let stores = action.payload.stores;
            let filteredstores = [];
            if (BusinessType === "All") {
                filteredstores = stores;
            }
            else {
                stores.map((s) =>
                    s.businessTypeId.length > 0 &&
                    s.businessTypeId.map((b) => {
                        if (b === BusinessType)
                            filteredstores = [...filteredstores, s]
                    }
                    )
                )
            }
            return {
                ...state,
                isStoresLoading: false,
                isStoresSuccess: true,
                isStoresFailed: false,
                businessTypeSeleced: true,
                Stores: filteredstores,
            };
        case "CLEAR_PASSWORD_PROPS":
            return {
                ...state,
                isPassword: false,
                UpdatingPassword: false,
                AddingPassword: false,
                UpdatedPassword: false,
                AddPassword: false,
                passworderror: false,
            };
        case 'ADDSTORETOVISITED_LOADING':
            return {
                ...state,
                isAddingStoretoVisited: true,
                isAddedStoretoVisited: false,
                isAddingStoretoVisitedFailed: false,
            };
        case 'ADDSTORETOVISITED_SUCCESS':
            return {
                ...state,
                isAddingStoretoVisited: false,
                isAddedStoretoVisited: true,
                isAddingStoretoVisitedFailed: false,
                AddStoreToVisited: action.payload,
            };
        case 'ADDSTORETOVISITED_FAILED':
            return {
                ...state,
                isAddingStoretoVisited: false,
                isAddedStoretoVisited: false,
                isAddingStoretoVisitedFailed: true,
            };
        case "ADDRESSES_LOADING":
            return {
                ...state,
                isgetAddress: false,
                isAddressLoading: true,
                isAddresssesSuccess: false,
                isAddressesFailed: false,
                isAddaddress: false,
            };
        case 'ADDRESSES_SUCCESS':
            return {
                ...state,
                isgetAddress: true,
                isAddressLoading: false,
                isAddresssesSuccess: true,
                isAddressesFailed: false,
                Addresses: action.payload,
            };
        case 'ADDRESSES_FAILED':
            return {
                ...state,
                isgetAddress: false,
                isAddressLoading: false,
                isAddresssesSuccess: false,
                isAddressesFailed: true,
            };
        case "AUTOCOMPLETE_LOADING":
            return {
                ...state,
                isSuggestionsLoading: true,
                isSuggestions: false,
                isSuggestionsFailed: false,
                isCoordinates: false,
            };
        case "AUTOCOMPLETE_SUCCESS":
            return {
                ...state,
                isSuggestionsLoading: false,
                isSuggestions: true,
                isSuggestionsFailed: false,
                Suggestions: action.payload,
            };

        case "AUTOCOMPLETE_FAILED":
            return {
                ...state,
                isSuggestionsLoading: false,
                isSuggestionsFailed: false,
                errorMessage: action.payload,
                isSuggestions: false,
            };
        case "AUTOCOMPLETE_ERROR":
            return {
                ...state,
                isSuggestions: false,
                incorrectMessage: "Network Error",
            };
        case "ACCOUNT_LOADING":
            return {
                ...state,
                isAccountLoading: true,
                isAccount: false,
                isAccountFailed: false
            };
        case "ACCOUNT_SUCCESS":
            return {
                ...state,
                isAccountLoading: false,
                isAccount: true,
                isAccountFailed: false,
                Account: action.payload,
            };

        case "ACCOUNT_FAILED":
            return {
                ...state,
                isAccountLoading: false,
                isAccount: false,
                isAccountFailed: true,
                accounterror: action.payload,
            };
        case "UPDATING_PASSWORD":
            return {
                ...state,
                UpdatingPassword: true,
                UpdatedPassword: false,
                AddingPassword: false,
                AddPassword: false,
                passworderror: false,
            }
        case "UPDATE_PASSWORD_SUCCESS":
            return {
                ...state,
                UpdatingPassword: false,
                UpdatedPassword: true,
                AddPassword: false,
                AddingPassword: false,
                isPassword: true,
                userdetails: action.payload,
                passworderror: false,
            };

        case "UPDATE_PASSWORD_FAILED":
            return {
                ...state,
                errorMessage: action.payload,
                UpdatingPassword: false,
                AddingPassword: false,
                UpdatedPassword: false,
                AddPassword: false,
                isPassword: false,
                passworderror: true,
            };
        case "UPDATE_PASSWORD_ERROR":
            return {
                ...state,
                isPassword: false,
                UpdatingPassword: false,
                AddingPassword: false,
                UpdatedPassword: false,
                AddPassword: false,
                incorrectMessage: "Network Error",
                passworderror: true,
            };
        case "ADDING_PASSWORD":
            return {
                ...state,
                UpdatingPassword: false,
                AddingPassword: true,
                UpdatedPassword: false,
                AddPassword: false,
                passworderror: false,
            };
        case "ADD_PASSWORD_SUCCESS":
            return {
                ...state,
                isPassword: true,
                UpdatingPassword: false,
                AddingPassword: false,
                UpdatedPassword: false,
                AddPassword: true,
                userdetails: action.payload,
                passworderror: false,
            };

        case "ADD_PASSWORD_FAILED":
            return {
                ...state,
                UpdatingPassword: false,
                errorMessage: action.payload,
                AddingPassword: false,
                UpdatedPassword: false,
                AddPassword: false,
                isPassword: false,
                passworderror: true,
            };
        case "ADD_PASSWORD_ERROR":
            return {
                ...state,
                isPassword: false,
                UpdatingPassword: false,
                AddingPassword: false,
                UpdatedPassword: false,
                AddPassword: false,
                incorrectMessage: "Network Error",
                passworderror: true,
            };
        case "ACTIVEORDERS_LENGTH_LOADING":
            return {
                ...state,
                // isCompletedordersLoading: false,
                // isCompletedeorders: false,
                // isCompletedordersFailed: false,
                isordersLengthLoading: true,
                isordersLength: false,
                isordersLengthFailed: false
            };
        case "ACTIVEORDERS_LENGTH_SUCCESS":
            return {
                ...state,
                // isCompletedordersLoading: false,
                isCompletedeorders: false,
                // isCompletedordersFailed: false,
                isordersLengthLoading: false,
                isordersLength: true,
                isordersLengthFailed: false,
                ActiveOrdersLength: action.payload.length,
            };

        case "ACTIVEORDERS_LENGTH_FAILED":
            return {
                ...state,
                // isCompletedordersLoading: false,
                isCompletedeorders: false,
                // isCompletedordersFailed: false,
                isordersLengthLoading: false,
                isordersLength: false,
                isordersLengthFailed: true,
                orderslengtherror: action.payload,
            };
        case "ACTIVEORDERS_LOADING":
            return {
                ...state,
                // isCompletedordersLoading: false,
                isCompletedeorders: false,
                // isCompletedordersFailed: false,
                isordersLoading: true,
                isorders: false,
                isordersFailed: false
            };
        case "ACTIVEORDERS_SUCCESS":
            return {
                ...state,
                // isCompletedordersLoading: false,
                isCompletedeorders: false,
                isActiveorders: true,
                // isCompletedordersFailed: false,
                isordersLoading: false,
                isorders: true,
                isordersFailed: false,
                Orders: action.payload,
                ActiveOrdersLength: action.payload.length,
            };

        case "ACTIVEORDERS_FAILED":
            return {
                ...state,
                // isCompletedordersLoading: false,
                isCompletedeorders: false,
                // isCompletedordersFailed: false,
                isordersLoading: false,
                isorders: false,
                isordersFailed: true,
                orderserror: action.payload,
            };
        case "COMPLETEORDERS_LOADING":
            return {
                ...state,
                isordersLoading: true,
                isorders: false,
                isordersFailed: false,
                // isCompletedordersLoading: true,
                // isCompletedeorders: false,
                // isCompletedordersFailed: false,
            };
        case "COMPLETEDORDERS_SUCCESS":
            return {
                ...state,
                // isActiveordersLoading: false,
                isActiveorders: false,
                isCompletedeorders: true,
                // isActiveordersFailed: false,
                isordersLoading: false,
                isorders: true,
                isordersFailed: false,
                Orders: action.payload,
            };

        case "COMPLETEDORDERS_FAILED":
            return {
                ...state,
                // isActiveordersLoading: false,
                isActiveorders: false,
                // isActiveordersFailed: false,
                isordersLoading: false,
                isorders: false,
                isordersFailed: true,
                orderserror: action.payload,
            };
        case "CART_LOADING":
            return {
                ...state,
                isCartLoading: true,
                isCart: false,
                isCartFailed: false,
            };
        case "CART_SUCCESS":
            return {
                ...state,
                isCartLoading: false,
                isCart: true,
                isCartFailed: false,
                Cart: action.payload,
                cartCount: action.payload && action.payload.length > 0
                    ? action.payload[0].productCount
                    : 0,
                totalPrice: action.payload && action.payload.length > 0 ? action.payload[0].totalPriceUser : 0
            };

        case "CART_FAILED":
            return {
                ...state,
                isCartLoading: false,
                isCart: false,
                isCartFailed: true,
                carterror: action.payload,
            };
        case "DEPARTMENTS_LOADING":
            return {
                ...state,
                isDepartmentsLoading: true,
                isDepartments: false,
                isDepartmentsFailed: false,
            };
        case "DEPARTMENTS_SUCCESS":
            return {
                ...state,
                isDepartmentsLoading: false,
                isDepartments: true,
                isDepartmentsFailed: false,
                Departments: action.payload,
            };

        case "DEPARTMENTS_FAILED":
            return {
                ...state,
                isDepartmentsLoading: false,
                isDepartments: false,
                isDepartmentsFailed: true,
                departmentserror: action.payload,
            };
        case "CATEGORIES_LOADING":
            return {
                ...state,
                isCategoriesLoading: true,
                isCategories: false,
                isCategoriesFailed: false,
            };
        case "CATEGORIES_SUCCESS":
            return {
                ...state,
                isCategoriesLoading: false,
                isCategories: true,
                isCategoriesFailed: false,
                Categories: action.payload,
            };

        case "CATEGORIES_FAILED":
            return {
                ...state,
                isCategoriesLoading: false,
                isCategories: false,
                isCategoriesFailed: true,
                categorieserror: action.payload,
            };
        case "CATEGORIES1_LOADING":
            return {
                ...state,
                isCategories1Loading: true,
                isCategories1: false,
                isCategories1Failed: false,
            };
        case "CATEGORIES1_SUCCESS":
            return {
                ...state,
                isCategories1Loading: false,
                isCategories1: true,
                isCategories1Failed: false,
                Categories1: action.payload,
            };

        case "CATEGORIES1_FAILED":
            return {
                ...state,
                isCategories1Loading: false,
                isCategories1: false,
                isCategories1Failed: true,
                categories1error: action.payload,
            };
        case "CATEGORIES2_LOADING":
            return {
                ...state,
                isCategories2Loading: true,
                isCategories2: false,
                isCategories2Failed: false,
            };
        case "CATEGORIES2_SUCCESS":
            return {
                ...state,
                isCategories2Loading: false,
                isCategories2: true,
                isCategories2Failed: false,
                Categories2: action.payload,
            };

        case "CATEGORIES2_FAILED":
            return {
                ...state,
                isCategories2Loading: false,
                isCategories2: false,
                isCategories2Failed: true,
                categories2error: action.payload,
            };
        case "DEPARTMENT_PRODUCTS_LOADING":
            return {
                ...state,
                isDepartmentsProductsLoading: true,
                isDepartmentsProducts: false,
                isDepartmentsProductsFailed: false,
            };
        case "DEPARTMENT_PRODUCTS_SUCCESS":
            return {
                ...state,
                isDepartmentsProductsLoading: false,
                isDepartmentsProducts: true,
                isDepartmentsProductsFailed: false,
                DepartmentProducts: action.payload,
            };

        case "DEPARTMENT_PRODUCTS_FAILED":
            return {
                ...state,
                isDepartmentsProductsLoading: false,
                isDepartmentsProducts: false,
                isDepartmentsProductsFailed: true,
                departmentserror: action.payload,
            };
        case "CATEGORY_PRODUCTS_LOADING":
            return {
                ...state,
                isCategoryProductsLoading: true,
                isCategoryProducts: false,
                isCategoryProductsFailed: false,
            };
        case "CATEGORY_PRODUCTS_SUCCESS":
            return {
                ...state,
                isCategoryProductsLoading: false,
                isCategoryProducts: true,
                isCategoryProductsFailed: false,
                CategoryProducts: action.payload,
            };

        case "CATEGORY_PRODUCTS_FAILED":
            return {
                ...state,
                isCategoryProductsLoading: false,
                isCategoryProducts: false,
                isCategoryProductsFailed: true,
                categoryproductserror: action.payload,
            };
        case "CATEGORY1_PRODUCTS_LOADING":
            return {
                ...state,
                isCategory1ProductsLoading: true,
                isCategory1Products: false,
                isCategory1ProductsFailed: false,
            };
        case "CATEGORY1_PRODUCTS_SUCCESS":
            return {
                ...state,
                isCategory1ProductsLoading: false,
                isCategory1Products: true,
                isCategory1ProductsFailed: false,
                Category1Products: action.payload,
            };

        case "CATEGORY1_PRODUCTS_FAILED":
            return {
                ...state,
                isCategory1ProductsLoading: false,
                isCategory1Products: false,
                isCategory1ProductsFailed: true,
                category1productserror: action.payload,
            };
        case "CATEGORY2_PRODUCTS_LOADING":
            return {
                ...state,
                isCategory2ProductsLoading: true,
                isCategory2Products: false,
                isCategory2ProductsFailed: false,
            };
        case "CATEGORY2_PRODUCTS_SUCCESS":
            return {
                ...state,
                isCategory2ProductsLoading: false,
                isCategory2Products: true,
                isCategory2ProductsFailed: false,
                Category2Products: action.payload,
            };

        case "CATEGORY2_PRODUCTS_FAILED":
            return {
                ...state,
                isCategory2ProductsLoading: false,
                isCategory2Products: false,
                isCategory2ProductsFailed: true,
                category2productserror: action.payload,
            };
        case "PRODUCT_DETAILS_LOADING":
            return {
                ...state,
                isProductDetailsLoading: true,
                isProductDetails: false,
                isProductDetailsFailed: false,
            };
        case "PRODUCT_DETAILS_SUCCESS":
            return {
                ...state,
                isProductDetailsLoading: false,
                isProductDetails: true,
                isProductDetailsFailed: false,
                ProductDetails: action.payload,
            };

        case "PRODUCT_DETAILS_FAILED":
            return {
                ...state,
                isProductDetailsLoading: false,
                isProductDetails: false,
                isProductDetailsFailed: true,
                productdetailserror: action.payload,
            };
        case "AVAILABLEADDRESSES_LOADING":
            return {
                ...state,
                isAvailableAddressesLoading: true,
                isAvailableAddresses: false,
                isAvailableAddressesFailed: false,
            };
        case "AVAILABLEADDRESSES_SUCCESS":
            return {
                ...state,
                isAvailableAddressesLoading: false,
                isAvailableAddresses: true,
                isAvailableAddressesFailed: false,
                AvailableAddresses: action.payload,
            };

        case "AVAILABLEADDRESSES_FAILED":
            return {
                ...state,
                isAvailableAddressesLoading: false,
                isAvailableAddresses: false,
                isAvailableAddressesFailed: true,
                availableaddresseserror: action.payload,
            };
        case "CHECKOUT_DETAILS_LOADING":
            return {
                ...state,
                isCheckoutDetailsLoading: true,
                isCheckoutDetails: false,
                isCheckoutDetailsFailed: false,
            };
        case "CHECKOUT_DETAILS_SUCCESS":
            return {
                ...state,
                isCheckoutDetailsLoading: false,
                isCheckoutDetails: true,
                isCheckoutDetailsFailed: false,
                CheckoutDetails: action.payload,
            };

        case "CHECKOUT_DETAILS_FAILED":
            return {
                ...state,
                isCheckoutDetailsLoading: false,
                isCheckoutDetails: false,
                isCheckoutDetailsFailed: true,
                checkoutdetailserror: action.payload,
            };
        case "DELIVERYCHARGE_LOADING":
            return {
                ...state,
                isDeliveryChargeLoading: true,
                isDeliveryCharge: false,
                isDeliveryChargeFailed: false,
            };
        case "DELIVERYCHARGE_SUCCESS":
            return {
                ...state,
                isDeliveryChargeLoading: false,
                isDeliveryCharge: true,
                isDeliveryChargeFailed: false,
                DeliveryCharge: action.payload,
            };

        case "DELIVERYCHARGE_FAILED":
            return {
                ...state,
                isDeliveryChargeLoading: false,
                isDeliveryCharge: false,
                isDeliveryChargeFailed: true,
                deliverychargeerror: action.payload,
            };
        case "CART_COUNT_WAITING":
            return {
                ...state,
                cartCountLoading: true,
            };
        case "CART_COUNT_SUCCESS":
            return {
                ...state,
                cartCountLoading: false,
                cartCount:
                    action.payload.data &&
                    action.payload.data.cart &&
                    action.payload.data.cart.length > 0 &&
                    action.payload.data.cart[0].productCount,
            };
        case "CART_COUNT_ERROR":
            return {
                ...state,
                networkerror: true,
            };
        case "CART_RESET":
            return {
                state,
            };
        case "CART_WAITING":
            return {
                ...state,
                isCartwaiting: true,
                isCartLoading: false,
                cartCountLoading: false,
            };
        case "CART_LIST_WAITING":
            return {
                ...state,
                isCartLoading: true,
                isCartwaiting: false,
                updatingcheckoutcart: false,
                checkoutcartupdated: false,
            };
        case "UPDATE_CART_SUCCESS":
            let updateStore, other, i, final;
            updateStore = state.Cart.filter(
                (store) => store.id === action.payload.data.updateCart.id
            );
            if (updateStore.length > 0) {
                other = state.Cart.filter(
                    (store) => store.id !== action.payload.data.updateCart.id
                );
                i = state.Cart.findIndex(
                    (store) => store.id === action.payload.data.updateCart.id
                );
                final = [
                    ...other.slice(0, i),
                    action.payload.data.updateCart,
                    ...other.slice(i),
                ];
            } else {
                final = [...state.Cart, action.payload.data.updateCart];
            }
            return {
                ...state,
                isCart: true,
                isCartwaiting: false,
                isCartLoading: false,
                cartCountLoading: false,
                Cart: final,
                cartCount: action.payload.data.updateCart.productCount,
                totalPrice: action.payload.data.updateCart.totalPriceUser,
            };

        case "UPDATE_CART_FAILED":
            return {
                ...state,
                cartFailed: action.payload,
                isCartwaiting: false,
                isCartLoading: false,
                isCart: false,
            };
        case "CART_ERROR":
            return {
                ...state,
                isCartError: false,
                incorrectMessage: "Network Error",
                isCartwaiting: false,
                isCartLoading: false,
            };
        case "CART_LIST_SUCCESS":
            return {
                ...state,
                isCartlist: true,
                isCartwaiting: false,
                isCartLoading: false,
                cartSuccess: action.payload,
                cartCountLoading: false,
                cartCount:
                    action.payload.data.cart && action.payload.data.cart.length > 0
                        ? action.payload.data.cart[0].productCount
                        : 0,
                totalPrice:
                    action.payload.data.cart &&
                    action.payload.data.cart.length > 0 &&
                    action.payload.data.cart[0].totalPriceUser,
                Cart: action.payload.data.cart,
            };

        case "CART_LIST_FAILED":
            return {
                ...state,
                errorMessage: action.payload,
                isCartwaiting: false,
                isCartLoading: false,
                isCartlist: false,
            };
        case "CART_LIST_ERROR":
            return {
                ...state,
                isCartlist: false,
                isCartwaiting: false,
                incorrectMessage: "Network Error",
            };
        case "REMOVE_PRODUCT_SUCCESS":
            let index, otherStores, finalPayload;
            if (action.payload.data.deleteoneproductcart.products.length === 0) {
                finalPayload = state.Cart.filter(
                    (store) => store.id !== action.payload.data.deleteoneproductcart.id
                );
            } else {
                otherStores = state.Cart.filter(
                    (store) => store.id !== action.payload.data.deleteoneproductcart.id
                );
                index = state.Cart.findIndex(
                    (store) => store.id === action.payload.data.deleteoneproductcart.id
                );
                finalPayload = [
                    ...otherStores.slice(0, index),
                    action.payload.data.deleteoneproductcart,
                    ...otherStores.slice(index),
                ];
            }
            return {
                ...state,
                isCart: true,
                isCartwaiting: false,
                isCartLoading: false,
                cartSuccess: action.payload,
                cartCountLoading: false,
                cartCount: action.payload.data.deleteoneproductcart.productCount,
                totalPrice: action.payload.data.deleteoneproductcart.totalPriceUser,
                Cart: finalPayload,
            };
        case "CREATE_CART_SUCCESS":
            let createStore, others, finalCart;
            createStore = state.Cart.filter(
                (store) => store.id === action.payload.data.createCart.id
            );
            if (createStore.length > 0) {
                others = state.Cart.filter(
                    (store) => store.id !== action.payload.data.createCart.id
                );
                finalCart = [...others, action.payload.data.createCart];
            } else {
                finalCart = [...state.Cart, action.payload.data.createCart];
            }
            return {
                ...state,
                isCart: true,
                isCartwaiting: false,
                isCartLoading: false,
                cartCountLoading: false,
                cartCount: action.payload.data.createCart.productCount,
                totalPrice: action.payload.data.createCart.totalPriceUser,
                Cart: finalCart,
                cartFailed: "",
            };

        case "CREATE_CART_FAILED":
            return {
                ...state,
                cartFailed: action.payload,
                isCartwaiting: false,
                isCartLoading: false,
                isCart: false,
            };
        case "SET_CART":
            return {
                ...state,
                Cart: action.payload,
            };
        case "UPDATE_CART_QUANTITY_SUCCESS":
            let updateQuantityStore, otherstore, ind, finalcart;
            updateQuantityStore = state.Cart.filter(
                (store) => store.id === action.payload.data.updateCartQuantity.id
            );
            if (updateQuantityStore.length > 0) {
                otherstore = state.Cart.filter(
                    (store) => store.id !== action.payload.data.updateCartQuantity.id
                );
                ind = state.Cart.findIndex(
                    (store) => store.id === action.payload.data.updateCartQuantity.id
                );
                finalcart = [
                    ...otherstore.slice(0, ind),
                    action.payload.data.updateCartQuantity,
                    ...otherstore.slice(ind),
                ];
            } else {
                finalcart = [...state.Cart, action.payload.data.updateCartQuantity];
            }
            return {
                ...state,
                isCart: true,
                isCartwaiting: false,
                isCartLoading: false,
                cartCountLoading: false,
                Cart: finalcart,
                cartCount: action.payload.data.updateCartQuantity.productCount,
                totalPrice: action.payload.data.updateCartQuantity.totalPriceUser,
            }
        case "UPDATING_CHECKOUT":
            return {
                ...state,
                updatingcheckoutcart: true,
            };
        case "UPDATE_CHECKOUT_SUCCESS":
            return {
                ...state,
                updatingcheckoutcart: false,
                checkoutcartupdated: true,
                cartupdatedata: action.payload,
            };
        case "UPDATE_CHECKOUT_FAILED":
            return {
                ...state,
                updatingcheckoutcart: false,
                checkoutcartupdated: false,
            };
        case "UPDATE_CHECKOUT_ERROR":
            return {
                ...state,
                updatingcheckoutcart: false,
                checkoutcartupdated: false,
            };
        case "CREATE_CART_RESET":
            return {
                ...state,
                cartFailed: "",
                deletestore: false,
            };
        case "CLEARING_CART":
            return {
                ...state,
                clearingcart: true,
                clearcart: false,
                clearcartfailed: false,
            };
        case "CLEAR_CART_SUCCESS":
            return {
                ...state,
                clearingcart: false,
                clearcart: true,
                clearcartfailed: false,
                // action.payload==="Deleted cart successfully"&&
                cartCount: action.payload === "Deleted cart successfully" && 0,
                totalPrice: action.payload === "Deleted cart successfully" && 0,
                Cart: action.payload === "Deleted cart successfully" && [],
            };
        case "CLEAR_CART_FAILED":
            return {
                ...state,
                clearingcart: false,
                clearcart: false,
                clearcartfailed: true,
            };
        case "CLEAR_CART_ERROR":
            return {
                ...state,
                clearingcart: false,
                clearcart: false,
                clearcartfailed: true,
            };
        case "DELETE_STORE_SUCCESS":
            let DeleteStore;

            DeleteStore = state.Cart.filter(
                (store) => store.id !== action.payload.storeid
            );
            return {
                ...state,
                deletestore: true,
                deletestorefailed: false,
                cartCount: Number(Number(state.cartCount) - Number(action.payload.leng)),
                Cart: DeleteStore,
                totalPrice: state.totalPrice - action.payload.totalprice
            };
        case "DELETE_STORE_FAILED":
            return {
                ...state,
                deletestore: false,
                // clearcart: false,
                deletestorefailed: true,
            };
        case "DELETE_STORE_ERROR":
            return {
                ...state,
                ...state,
                deletestore: false,
                // clearcart: false,
                deletestorefailed: true,
            };
        case "CHECKOUT_ADDRESS_SELECTED":
            return {
                ...state,
                placeorderaddress: (action.payload.addressid === null || action.payload.addressid === 'null') ? null : action.payload,
                placeorderaddressid: action.payload.addressid,
                placeorderlattitude: action.payload.addressLattitude,
                placeorderlongitude: action.payload.addressLongitude,
            };
        case "CHECKOUT_SLOT_SELECTED":
            return {
                ...state,
                placeorderdate: action.payload.date,
                placeordertime: action.payload.time,
                placeorderinstructions: action.payload.instructions
            };
        case "CHECKOUT_SLOT_SELECTED_INIT":
            return {
                ...state,
                SelectedDateSlot: action.payload,
            };
        case "PHONENUMBER_LOADING":
            return {
                ...state,
                phoneNumberLoading: true,
            };
        case "CHECKOUT_PHONENUMBER_LOADING":
            return {
                ...state,
                isCheckoutPhoneNumberLoading: true,
                isCheckoutPhoneNumber: false,
                isCheckoutPhoneNumberFailed: false
            };
        case "CHECKOUT_PHONENUMBER_SUCCESS":
            return {
                ...state,
                isCheckoutPhoneNumberLoading: false,
                isCheckoutPhoneNumber: true,
                isCheckoutPhoneNumberFailed: false,
                placeorderphonenumber: action.payload,
            };

        case "CHECKOUT_PHONENUMBER_FAILED":
            return {
                ...state,
                isCheckoutPhoneNumberLoading: false,
                isCheckoutPhoneNumber: false,
                isCheckoutPhoneNumberFailed: true,
                placeorderphonenumbererror: action.payload,
            };
        case "GET_PHONENUMBER_SUCCESS":
            return {
                ...state,
                placeorderphonenumber: action.payload.phoneNumber
            };
        case "SEND_OTP_ORDER_SUCCESS":
            return {
                ...state,
                isOTP: true,
                isSendOTPFailed: false,
                sendOTP: action.payload,
            };

        case "SEND_OTP_ORDER_FAILED":
            return {
                ...state,
                errorMessage: action.payload,
                isSendOTPFailed: true,
                isOTP: false,
            };
        case "SEND_OTP_ORDER_ERROR":
            return {
                ...state,
                isOTP: false,
                isSendOTPFailed: false,
                incorrectMessage: "Network Error",
            };
        case "VERIFY_OTP_ORDER_SUCCESS":
            return {
                ...state,
                VerifyOTP: true,
                verifyOTPsuccess: action.payload,
            };

        case "VERIFY_OTP_ORDER_FAILED":
            return {
                ...state,
                errorMessage: action.payload,
                VerifyOTP: false,
            };
        case "VERIFY_OTP_ORDER_ERROR":
            return {
                ...state,
                VerifyOTP: false,
                incorrectMessage: "Network Error",
            };
        case "PLACE_ORDER_WAITING":
            return {
                ...state,
                orderplaced: false,
                placeorderwaiting: true,
            };
        case "PLACE_ORDER_SUCCESS":
            return {
                ...state,
                orderplaced: true,
                placeorderwaiting: false,
                placeorder: action.payload,
                NotificationLength: state.NotificationLength + 1,
            };

        case "PLACE_ORDER_FAILED":
            return {
                ...state,
                orderplaced: false,
                placeorderwaiting: false,
                placeordererror: action.payload,
            };
        case "PLACE_ORDER_ERROR":
            return {
                ...state,
                orderplaced: false,
                placeordererror: action.payload,
            };
        case "RESET_CHECKOUT_PROPS":
            return {
                ...state,
                orderplaced: false,
                placeorderaddress: null,
                placeorderinstructions: null,
                totalCouponDiscount: 0,
                placeordertime: null,
                placeorderaddressid: null,
                SelectedDateSlot: [],
                CheckoutInstructions: [],
                storeindex: [],
                dateIndex: [],
                dayIndex: [],
                selectedcouponslist: [],
            };
        case "CHECKOUT_SLOT_DATE_SELECTION":
            let slotes, otherslotes, finalslotes;
            slotes = state.SelectedDateSlot.filter((s) => s.storeindex === action.payload.Storeindex)
            if (slotes.length > 0) {
                otherslotes = state.SelectedDateSlot.filter((s) => s.storeindex !== action.payload.Storeindex)
                finalslotes = [...otherslotes, {
                    storeindex: action.payload.Storeindex,
                    keyword: action.payload.DateKeyword
                }]
            }
            else {
                finalslotes = [...state.SelectedDateSlot, {
                    storeindex: action.payload.Storeindex,
                    keyword: action.payload.DateKeyword
                }]
            }
            return {
                ...state,

                // filteredslots:state
                SelectedDateSlot: finalslotes.sort(function (x, y) {
                    return x.storeindex - y.storeindex;
                })
            }
        case "FILTER_SLOT_DATE_SELECTION":
            return {
                ...state,
                SelectedDateSlot: [...state.SelectedDateSlot.slice(0, action.payload.Storeindex), {
                    storeindex: action.payload.Storeindex,
                    keyword: null
                },
                ...state.SelectedDateSlot.slice(action.payload.Storeindex + 1)].sort(function (x, y) {
                    return x.storeindex - y.storeindex;
                })
            }

        case "RATING_SUCCESS":
            return {
                ...state,
                isRating: true,
            };
        case "RATING_FAILED":
            return {
                ...state,
                isRating: false,
            };
        case "CHECKOUT_SLOT_DAY_INDEX":
            return {
                ...state,
                dayIndex: action.payload.dayindex,
                dateIndex: action.payload.dateindex
            }
        case "CHECKOUT_INSTRUCTIONS":
            return {
                ...state,
                CheckoutInstructions: action.payload
            }
        case "RESETPROPS":
            return {
                ...state,
                orderplaced: false
            };
        case "NOTIFICATIONS_LENGTH_LOADING":
            return {
                ...state,
                isNotificationLengthLoading: true,
                isNotificationLength: false,
                isNotificationLengthFailed: false
            };
        case "NOTIFICATIONS_LENGTH_SUCCESS":
            return {
                ...state,
                isNotificationLengthLoading: false,
                isNotificationLength: true,
                isNotificationLengthFailed: false,
                NotificationLength: action.payload,
            };
        case "NOTIFICATIONS_LENGTH_FAILED":
            return {
                ...state,
                isNotificationLengthLoading: false,
                isNotificationLength: false,
                isNotificationLengthFailed: true,
                NotificationLengtherror: action.payload,
            };
        case "LIVE_ORDERS_LOADING":
            return {
                ...state,
                isLiveOrdersLoading: true,
                isLiveOrders: false,
                isLiveOrdersFailed: false
            };
        case "LIVE_ORDERS_SUCCESS":
            return {
                ...state,
                isLiveOrdersLoading: false,
                isLiveOrders: true,
                isLiveOrdersFailed: false,
                LiveOrders: action.payload,
            };

        case "LIVE_ORDERS_FAILED":
            return {
                ...state,
                isLiveOrdersLoading: false,
                isLiveOrders: false,
                isLiveOrdersFailed: true,
                liveorderserror: action.payload,
            };
        case "UPDATING_PRIMARYLOCATION":
            return {
                ...state,
                errorMessage: action.payload,
                isUpdating: true,
            };
        case "UPDATE_PRIMARYLOCATION_SUCCESS":
            return {
                ...state,
                isUpdated: true,
                UpdateLocation: action.payload,
            };

        case "UPDATE_PRIMARYLOCATION_FAILED":
            return {
                ...state,
                errorMessage: action.payload,
                isUpdated: false,
            };
        case "UPDATE_PRIMARYLOCATION_ERROR":
            return {
                ...state,
                isUpdated: false,
                incorrectMessage: "Network Error",
            };
        case "SET_USER_LOCATION":
            return {
                ...state,
                setLocation: true,
                UserLocation: action.payload.location,
                UserLatittude: action.payload.lattitude,
                UserLongitude: action.payload.longitude
            };
        case "RESET_UPDATE":
            return {
                ...state,
                isUpdated: false,
            }
        case "SUGGESTIONS_SUCCESS":
            return {
                ...state,
                isCoordinates: true,
                Coordinates: action.payload,
                lattitude: action.payload.data.googlemapplacedetails.lat,
                longitude: action.payload.data.googlemapplacedetails.lng,
            };

        case "SUGGESTIONS_FAILED":
            return {
                ...state,
                suggestionserrorMessage: action.payload,
                isCoordinates: false,
            };
        case "SUGGESTIONS_ERROR":
            return {
                ...state,
                isCoordinates: false,
                incorrectMessage: "Network Error",
            };
        case "ADDING_ADDRESS":
            return {
                ...state,
                Addingaddress: true,
                isAddaddress: false,
                isAddaddressFailed: false,
            };
        case "ADD_ADDRESS_SUCCESS":
            return {
                ...state,
                isAddaddress: true,
                Addingaddress: false,
                isAddaddressFailed: false,
                AddAddressClicked: true,
                Addresses: action.payload.address,
            };

        case "ADD_ADDRESS_FAILED":
            return {
                ...state,
                addaddresserrorMessage: action.payload,
                isAddaddress: false,
                isAddaddressFailed: true,
                Addingaddress: false,
                AddAddressClicked: true,
            };
        case "ADD_ADDRESS__ERROR":
            return {
                ...state,
                isAddaddress: false,
                Addingaddress: false,
                incorrectMessage: "Network Error",
                AddAddressClicked: true,
            };
        case "CHANGING_ADDRESS":
            return {
                ...state,
                Editingaddress: true,
                isEditaddress: false,
                isEditaddressFailed: false,
            };
        case "ADDRESS_CHANGE_SUCCESS":
            return {
                ...state,
                isEditaddress: true,
                Editingaddress: false,
                EditAddressClicked: true,
                Addresses: action.payload.address,
                isEditaddressFailed: false,
            };

        case "ADDRESS_CHANGE_FAILED":
            return {
                ...state,
                editaddresserror: action.payload,
                EditAddressClicked: true,
                Editingaddress: false,
                isEditaddress: false,
                isEditaddressFailed: true,
            };
        case "ADDRESS_CHANGE_ERROR":
            return {
                ...state,
                isEditaddress: false,
                EditAddressClicked: true,
                Editingaddress: false,
                isEditaddressFailed: true,
                incorrectMessage: "Network Error",
            };
        case "SET_ADDRESS_CORDINATES":
            return {
                ...state,
                editLocation: `${action.payload.params1}`,
            };
        case "CLEAR_ADRESS_PROPS":
            return {
                ...state,
                editLocation: null,
                lattitude: null,
                longitude: null,
            };
        case "CLEAR_ADDADRESS_PROPS":
            return {
                ...state,
                isAddaddress: false,
                Addingaddress: false,
                AddAddressClicked: false,
            };
        case "CLEAR_EDITADRESS_PROPS":
            return {
                ...state,
                isEditaddress: false,
                Editingaddress: false,
                EditAddressClicked: false,
            };
        case "DELETE_ADDADRESS_PROPS":
            return {
                ...state,
                isDeleteAddress: false,
                DeletingAddress: false,
                DeleteAddressClicked: false,
            };
        case "SET_EDIT_ADRESS_PROPS":
            return {
                ...state,
                editLocation: `${action.payload.p1}`,
                lattitude: action.payload.p2,
                longitude: action.payload.p3,
                landmark: action.payload.p4,
                houseName: action.payload.p5,
            };
        case "DELETING_ADDRESS":
            return {
                ...state,
                DeletingAddress: true,
                DeleteAddressClicked: true,
            };
        case "DELETE_ADDRESS_SUCCESS":
            return {
                ...state,
                isAddress: true,
                isDeleteAddress: true,
                Addresses: action.payload.deleteOneAddressOfUser.address,
                DeletingAddress: false,
                DeleteAddressClicked: true,
            };

        case "DELETE_ADDRESS_FAILED":
            return {
                ...state,
                errorMessage: action.payload,
                DeletingAddress: false,
                isDeleteAddress: false,
                DeleteAddressClicked: true,
            };
        case "DELETE_ADDRESS_ERROR":
            return {
                ...state,
                isDeleteAddress: false,
                incorrectMessage: "Network Error",
                DeleteAddressClicked: true,
            };
        case "CHANGING_ACCOUNT":
            return {
                ...state,
                isEditingaccount: true,
                isEditAccount: false,
                isEditAccountFailed: false,
            };
        case "ACCOUNT_CHANGE_SUCCESS":
            return {
                ...state,
                isEditingaccount: false,
                isEditAccount: true,
                isEditAccountFailed: false,
                Account: action.payload.updateUser,
            };

        case "ACCOUNT_CHANGE_FAILED":
            return {
                ...state,
                isEditingaccount: false,
                isEditAccountFailed: true,
                editaccounterror: action.payload,
                isEditAccount: false,
            };
        case "ACCOUNT_CHANGE_ERROR":
            return {
                ...state,
                isEditingaccount: false,
                isEditAccountFailed: true,
                isEditAccount: false,
                incorrectMessage: "Network Error",
            };
        case "SEARCHING_PRODUCTS":
            return {
                ...state,
                isSearchingProducts: true,
                isSearchProducts: false,
                isSeacrhProductsFailed: false,
            };
        case "SEARCH_PRODUCTS_SUCCESS":
            return {
                ...state,
                isSearchingProducts: false,
                isSearchProducts: true,
                isSeacrhProductsFailed: false,
                SearchProducts: action.payload,
            };

        case "SEARCH_PRODUCTS_FAILED":
            return {
                ...state,
                isSearchingProducts: false,
                isSeacrhProductsFailed: true,
                searchproductserror: action.payload,
                isSearchProducts: false,
            };
            case "CLEAR_SEARCH_PRODUCTS":
                return {
                    ...state,
                    isSearchingProducts: false,
                    isSeacrhProductsFailed: false,
                    isSearchProducts: false,
                    SearchProducts:null
                };
        case "REFERING_FRIEND":
            return {
                ...state,
                SendingInvite: true,
                InviteSentSuccess: false,
                InviteSentFailed: false,
            };
        case "REFER_FRIEND_SUCCESS":
            return {
                ...state,
                SendingInvite: false,
                InviteSentSuccess: true,
                InviteSentFailed: false
            };

        case "REFER_FRIEND_FAILED":
            return {
                ...state,
                SendingInvite: false,
                InviteSentSuccess: false,
                InviteSentFailed: true,
            };
        case "ADDING_EMAIL":
            return {
                ...state,
                isEmailAdding: true,
                isEmail: false,
                isEmailFailed: false,
            };
        case "ADD_EMAIL_SUCCESS":
            return {
                ...state,
                isEmail: true,
                isEmailAdding: false,
                isEmailFailed: false,
                Account: action.payload,
            };

        case "ADD_EMAIL_FAILED":
            return {
                ...state,
                addemailerror: action.payload,
                isEmailAdding: false,
                isEmail: false,
                isEmailFailed: true,
            };
        case "ADD_EMAIL_ERROR":
            return {
                ...state,
                addemailerror: action.payload,
                isEmailAdding: false,
                isEmail: false,
                isEmailFailed: true,
            };
        case "ORDERDETAILS_LOADING":
            return {
                ...state,
                orderdetailsLoading: true,
                orderdetailssuccess: false,
                orderdetailsfailed: false,
            };

        case "ORDERDETAILS_SUCCESS":
            return {
                ...state,
                orderdetailsLoading: false,
                orderdetailssuccess: true,
                orderdetailsfailed: false,
                OrderDetails: action.payload,
            };

        case "ORDERDETAILS_FAILED":
            return {
                ...state,
                orderdetailsLoading: false,
                orderdetailssuccess: false,
                orderdetailsfailed: true,
                eorderdetailserror: action.payload,
            };
        case "DELETING_ACCOUNT":
            return {
                ...state,
                isDeletingAccount: true,
                isAccountDeleted: false,
                isAccountDeletionFailed: false,
            };
        case "DELETE_ACCOUNT_SUCCESS":
            return {
                ...state,
                isDeletingAccount: false,
                isAccountDeleted: true,
                isAccountDeletionFailed: false,
                isSignUpSuccess: false,
                isLogInSuccess: false,
                isVerifyOTP: false,
            };

        case "DELETE_ACCOUNT_FAILED":
            return {
                ...state,
                isDeletingAccount: false,
                isAccountDeleted: false,
                isAccountDeletionFailed: true,
            };
        case "CLEAR_SIGNIN_PROPS":
            return {
                ...state,
                isSignUpSuccess: false,
                isLogInSuccess: false,
                isVerifyOTP: false,
                isResetFailed: false,
                isOTP: false,
            };
        case "LOADING_AVAILABLE_COUPONS":
            return {
                ...state,
                availablecouponsLoading: true,
                availablecouponssuccess: false,
                availablecouponsfailed: false,
            };
        case "AVAILABLE_COUPONS_SUCCESS":
            return {
                ...state,
                availablecouponsLoading: false,
                availablecouponssuccess: true,
                availablecouponsfailed: false,
                AvailableCoupons: action.payload,
            };
        case "AVAILABLE_COUPONS_FAILED":
            return {
                ...state,
                availablecouponsLoading: false,
                availablecouponssuccess: false,
                availablecouponsfailed: true,
            };
        case "APPLYING_COUPON":
            return {
                ...state,
                applyingCoupon: true,
                applycouponsuccess: false,
                applycouponfailed: false,
            };
        case "APPLY_COUPON_SUCCESS":
            return {
                ...state,
                applyingCoupon: false,
                applycouponsuccess: true,
                applycouponfailed: false,
                Cart: action.payload,
            };
        case "APPLY_COUPON_FAILED":
            return {
                ...state,
                applyingCoupon: false,
                applycouponsuccess: false,
                applycouponfailed: true,
                applycouponerror: action.payload
            };
        case "APPLY_COUPON_CLEAR":
            return{
                ...state,
                applycouponerror:null,
                applycouponsuccess:false,
            }
        case "CHECKOUT_COUPONS":
            return {
                ...state,
                selectedcouponslist: action.payload.coupons,
                totalCouponDiscount: state.totalCouponDiscount - action.payload.discounttoremove
            };
        case "REMOVING_COUPON":
            return {
                ...state,
                removingCoupon: true,
                removingcouponsuccess: false,
                removecouponfailed: false,
            };
        case "REMOVE_COUPON_SUCCESS":
            return {
                ...state,
                removingCoupon: false,
                removingcouponsuccess: true,
                removecouponfailed: false,
                RemoveCoupon: action.payload,
            };
        case "REMOVE_COUPON_FAILED":
            return {
                ...state,
                removingCoupon: false,
                removingcouponsuccess: false,
                removecouponfailed: true,
                removecouponerror: action.payload
            };
        case "COMPLAINTS_SUCCESS":
            return {
                ...state,
                iscomplaintslist: true,
                Complaints: action.payload.data.complaintsBot,
            };
        case "COMPLAINTS_FAILED":
            return {
                ...state,
                iscomplaintslist: false,
                complaintserror: action.payload,
            };
        case "COMPLAINTS_ERROR":
            return {
                ...state,
                iscomplaintslist: false,
                complaintserror: action.payload,
            };
        case "SUB_COMPLAINTS_SUCCESS":
            return {
                ...state,
                issubcomplaintslist: true,
                SubComplaints: action.payload.data.complaintsBot,
            };
        case "SUB_COMPLAINTS_FAILED":
            return {
                ...state,
                issubcomplaintslist: false,
                subcomplaintserror: action.payload,
            };
        case "SUB_COMPLAINTS_ERROR":
            return {
                ...state,
                issubcomplaintslist: false,
                subcomplaintserror: action.payload,
            };
        case "PREVIOUS_COMPLAINTS_LOADING":
            return {
                ...state,
                previousComplaintsLoading: true,
            };
        case "PREVIOUS_COMPLAINTS_SUCCESS":
            return {
                ...state,
                previousComplaintsLoading: false,
                previousComplaints: true,
                OrderComplaints: action.payload.data.orderComplaints,
            };

        case "PREVIOUS_COMPLAINTS_FAILED":
            return {
                ...state,
                previousComplaintsLoading: false,
                previousComplaints: false,
                previousComplaintsFailed: true,
            };
        case "PREVIOUS_COMPLAINTS_ERROR":
            return {
                ...state,
                previousComplaintsLoading: false,
                previousComplaints: false,
                previousComplaintsFailed: true,
            };
        case "CLEAR_COMPLAINTS":
            return {
                ...state,
                iscomplaintslist: false,
                Complaints: state.Complaints.filter(c => c.id === action.payload)
            };
        case "ORDER_ITEMS_LOADING":
            return {
                ...state,
                OrderItemsLoading: true,
            };
        case "ORDER_ITEMS_SUCCESS":
            return {
                ...state,
                OrderItemsLoading: false,
                OrderItemsList: true,
                OrderItems: action.payload,
            };

        case "ORDER_ITEMS_FAILED":
            return {
                ...state,
                OrderItemsLoading: false,
                OrderItemsList: false,
                OrderItemsListFailed: false,
            };
        case "UPLOADING_IMAGE":
            return {
                ...state,
                Uploadingimage: true,
            };
        case "UPLOAD_IMAGE_SUCCESS":
            return {
                ...state,
                Uploadingimage: false,
                Imageupload: true,
                UploadData: action.payload,
            };

        case "UPLOAD_IMAGE_FAILED":
            return {
                ...state,
                Uploadingimage: false,
                Imageupload: false,
                ImageuploadFailed: false,
            };
        case "REGISTERING_COMPLAINT":
            return {
                ...state,
                RegisteringComplaint: true,
            };
        case "REGISTER_COMPLAINT_SUCCESS":
            return {
                ...state,
                RegisteringComplaint: false,
                ComplaintRegistration: true,
                registeredComplaint: action.payload,
                SelectedItemsImage:null,
                SelectedItemsList:[],
                SelectedOption: {refund: false, replacement: false}
            };

        case "REGISTER_COMPLAINT_FAILED":
            return {
                ...state,
                RegisteringComplaint: false,
                ComplaintRegistration: false,
                ComplaintRegistrationFailed: true
            };
            case "RESET_COMPLAINT_PROPS":
                return {
                    ...state,
                    submititems: false,
                    submitimage: false,
                    SelectedItemsImage:null,
                    SelectedItemsList:null,
                    SelectedOption: {refund: false, replacement: false}
                };
        case "SELECTED_ITEMS_LIST": {
            return {
                ...state,
                submititems: false,
                SelectedItemsList: action.payload
            }
        }
        case "SUBMIT_ITEMS_LIST": {
            return {
                ...state,
                submititems: true,
                submitimage: false,
                SelectedItemsList: action.payload
            }
        }
        case "SELECTED_ITEMS_IMAGE": {
            return {
                ...state,
                submitimage: true,
                SelectedItemsImage: action.payload
            }
        }
        case "SELECTED_OPTION": {
            return {
                ...state,
                SelectedOption: {
                    refund: action.payload.refund,
                    replacement: action.payload.replacement
                }
            }
        }
        case "CLEAR_COMPLAINT_PROPS": {
            return {
                ...state,
                SelectedItemsList: [],
                SelectedOption: {
                    refund: false,
                    replacement: false
                },
                SelectedItemsImage: null,
                ComplaintRegistration: false,
                RegisteringComplaint: false,
                submitimage: false,
                submititems: false,
            }
        }
        case "UPDATING_FULL_CART": {
            return {
                ...state,
                isCartLoading:true,
                isCartSuccess:false,
                isCartFailed:false,
            }
        }
        case "UPDATE_FULL_CART_SUCCESS": {
            return {
                ...state,
                isCartLoading:false,
                isCartSuccess:true,
                isCartFailed:false,
                Cart:action.payload
            }
        }
        case "UPDATE_FULL_CART_FAILED": {
            return {
                ...state,
                isCartLoading:false,
                isCartSuccess:false,
                isCartFailed:true,
                updateFullCartError:action.payload
            }
        } 
        case "UPDATE_ADDRESS_ID_SUCCESS" :{
            return {
                ...state,
                Cart: action.payload
              };
        }
        
        case "UPDATE_ADDRESS_ID_FAILED": {
            return {
                ...state,
                Cart:{
                    ...state.Cart,
                    addressid:null
                }
            }
        }  
        case "UPDATE_DELIVERYTIP_SUCCESS":{
             return {
                ...state,
                Cart: action.payload
              };
        }
        case "UPDATE_DELIVERYTIP_FAILED":{
            return{
                ...state,
                Cart: action.payload 
            }
           
        }
        case "UPDATING_QUANTITY":{
            return{
                ...state,
                isQuantityUpdating:true
            }
        }
        case "UPDATE_QUANTITY_SUCCESS":{
            
            return{
                ...state,
                Cart: action.payload,
                isQuantityUpdating:false,
                isQuantityUpdated:true,
                isQuantityUpdtaedFailed:false,
            }
           
        }
        case "UPDATE_QUANTITY_FAILED":{

            return{
                ...state,
            isQuantityUpdated:false,
            isQuantityUpdtaedFailed:true,
            error:action.payload
            }
            
        }
       
        case "UPDATE_APPLYBEST_COUPON_SUCCESS":{
            
            return{
                ...state,
                Cart: action.payload === null? state.Cart:action.payload
            }
        }
        case "UPDATE_APPLYBEST_COUPON_FAILED":{

            return{
                ...state
            }
        }
        case "ADD_COOKING_INSTRUCTIONS":{
            return{
                ...state,
                CookingInstructions:action.payload
            }
        }
        case "ADD_DELIVERY_INSTRUCTIONS":{
            return {
                ...state,
                DeliveryInstructions:action.payload
            }
        }
        case "SELECTED_DATE_AND_TIME_FOR_DELIVERY": {
            return {
              ...state,
              isDeliveryDateTimeSelected:true,
              Selected_Details: action.payload,
            };
          }
          case "CLEAR_DATE_AND_TIME_FOR_DELIVERY":
      return {
        ...state,
        Selected_Details:null
      };
        case "DELETE_ADDRESS_SUCCESS":{
            return{
                ...state,
                DeleteAddress :action.payload
            }
        }
        case "DELETE_ADDRESS_FAILED" :{
            return{
                ...state,
                DeleteAddressFailed :action.payload
            }
        }
        
        case "POPULAR_PRODUCTS_LOADING" :{
            return{
                ...state,
                popularProductsLoading:true,
                popularProductsSuccess:false,
                popularProductsFailed:false
            }
        }
        
        case "GET_POPULAR_PRODUCTS_SUCCESS" :{
            return{
                ...state,
                popularProductsLoading:false,
                popularProductsSuccess:true,
                popularProductsFailed:false,
                popularProductsList:action.payload
            }
        }

        case "GET_POPULAR_PRODUCTS_FAILED" :{
            return{
                ...state,
                popularProductsLoading:false,
                popularProductsSuccess:false,
                popularProductsFailed:true,
                popularProductsError:action.payload
            }
        }
        case "SEARCH_PRODUCTS_GLOBALLY_LOADING" :{
            return{
                ...state,
                globalProductsLoading:true,
                globalProductsSuccess:false,
                globalProductsFailed:false,
            }
        }

        case "SEARCH_PRODUCTS_GLOBALLY_SUCCESS" :{
            return{
                ...state,
                globalProductsLoading:false,
                globalProductsSuccess:true,
                globalProductsFailed:false,
                searchProductsGlobalList:action.payload,
            }
        }

        case "SEARCH_PRODUCTS_GLOBALLY_FAILED" :{
            return{
                ...state,
                globalProductsLoading:false,
                globalProductsSuccess:false,
                globalProductsFailed:true,
                serachGlobalProductError:action.payload,
            }
        }
        case "GET_PRODUCT_SEARCH_LOADING" :{
            return{
                ...state,
                productsSearchLoading:true,
                productsSearchSuccess:false,
                productsSearchFailed:false,
            }
        }

        case "GET_PRODUCT_SEARCH_SUCCESS" :{
            return{
                ...state,
                productsSearchLoading:false,
                productsSearchSuccess:true,
                productsSearchFailed:false,
                productsSearchDetails:action.payload,
            }
        }

        case "GET_PRODUCT_SEARCH_FAILED" :{
            return{
                ...state,
                productsSearchLoading:false,
                productsSearchSuccess:false,
                productsSearchFailed:true,
                productsSearchError:action.payload,
            }
        }

        case "REMOVING_APPLIED_COUPON":
            return {
                ...state,
                removingAppliedCoupon: true,
                removingAppliedCouponsuccess: false,
                removeAppliedCouponfailed: false,
            };
        case "REMOVE_APPLIED_COUPON_SUCCESS":
            return {
                ...state,
                removingAppliedCoupon: false,
                removingAppliedCouponsuccess: true,
                removeAppliedCouponfailed: false,
                // RemoveAppliedCoupon: action.payload,
                Cart: action.payload === null? state.Cart:action.payload
            };
        case "REMOVE_APPLIED_COUPON_FAILED":
            return {
                ...state,
                removingAppliedCoupon: false,
                removingAppliedCouponsuccess: false,
                removeAppliedCouponfailed: true,
                removeAppliedCouponerror: action.payload
            };

        case "UPDATING_RECENT_SEARCH" :{
            return {
                ...state,
                updatingRecentSearch:true,
                updateRecentSearchSuccess:false,
                updateRecentSearchFailed:false
            }
        }
        case "RECENT_SEARCH_SUCCESS" :{
            return {
                ...state,
                updatingRecentSearch:false,
                updateRecentSearchSuccess:true,
                updateRecentSearchFailed:false,
                recentSearches:action.payload
            }
        }
        case "RECENT_SEARCH_FAILED" :{
            return {
                ...state,
                updatingRecentSearch:false,
                updateRecentSearchSuccess:false,
                updateRecentSearchFailed:true,
                recentsearchError:action.payload
            }
        }
        // case "STORE_DETAILS_SUCCESS":{
        //     return{
        //         ...state,
        //     }
        // }
        default:
            return {
                ...state
            }
    }
}