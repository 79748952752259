import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    addtocartbutton: {
        width: '100%',
        height:'34px',
        backgroundColor:'#EAF6E1',
        color: '#85BE49',
        border:'0.5px solid rgba(0, 0, 0, 0.03)',
        borderRadius:'16px',
        // paddingTop:'5px',
        // paddingBottom:'5px',
        // paddingLeft:'5px',
        // paddingRight:'5px',
        boxSizing:'border-box',
        fontSize:'14px',
        userSelect:'none',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        // transform:'scale(0.5)',
        '&:hover':{
            transform:'scale(1)',
        },
    },
    button: {
        background: '#85BE49',
         color: '#FFFFFF',
         fontSize: '16px',
         lineHeight: '19px',
         fontWeight: 600,
         textAlign: 'center',
         borderRadius: '12px',
         padding: '10px',
         border: `1px #85BE49`,
         // width: '40%',
         cursor: 'pointer',
         marginLeft:'10px',
   },
   drawerPaper: {
    height: '100vh',
    backgroundColor: 'red',
  },
}));
