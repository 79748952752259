import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import downloadappimage from '../../Assets/Images/downloadappimage.png';
import './index.css';

const MiddleSection = () => {

    const smallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
    
    return (
        <Grid container justifyContent={'center'} alignItems={'center'} sx={{ paddingTop: '65px' }}>
            <Grid item xs={10} justifyContent={'center'} alignItems={'center'}>
                <div className='para' style={{color:'#000'}}>
                With Nearshopz, you can order food, daily essentials, groceries, cakes and bakes, fish and meat, and even items for your pets, all from the comfort of your home. Say goodbye to stress, traffic, and long queues – we’re here to simplify your shopping experience!
                    <br />
                    <br />
                    Here's how you can enjoy hassle-free online shopping in just 3 easy steps:
                    <div style={{ paddingTop: '25px', paddingLeft: '30px' }}>
                        1. Tell Us Your Location: Share your location so we can connect you with nearby stores. From your local grocer to big retail chains, we've got them all. 
                        <br />
                        2.  Add to Cart: Pick your items from a variety of stores. It's quick and easy! 
                        <br />
                        3.  Checkout & Relax: Securely pay and get home-delivered. It's that simple!
                    </div>
                    <div style={{ paddingTop: '25px' }}>
                    Why Choose NearShopz?:
                        <br />
                        - Shop Local Stores: Access a wide range of stores right in your area. 
                        <br />
                        - Effortless Ordering: Browse products, add them to your cart, and checkout in minutes. 
                        <br />
                        - Quick Reorders: Save your shopping lists or past orders for one-click reordering. 
                        <br />
                        - Track Your Order: Stay updated on your delivery's progress.
                        <br />
                        - Flexible Delivery: Get your items instantly or schedule a time that suits you. 
                    </div>
                    {/* <div > */}
                   { smallScreen?
                   <Grid container spacing={2} sx={{paddingTop:'15px',fontSize:'30px'}}>
                     <Grid item xs={12} md={5} >
                            <img src={downloadappimage} alt="NearShopz" />
                        </Grid>
                        <Grid item xs={12} sx={{fontSize:'20px',fontWeight:700,color:'rgba(29, 77, 79, 1)',paddingTop:'15px'}}>
                        {'Shop essentials'}
                        </Grid>
                        <Grid item xs={12} textAlign={'left'} sx={{fontSize:'20px',fontWeight:600,color:'rgba(29, 77, 79, 1)'}}>
                                    {'in a few taps.'}
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={8} sx={{color:'rgba(29, 77, 79, 0.7)',fontSize:'20px',fontWeight:500}}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                {'Shop for groceries, pharmacy,'}
                                                {'baby, pet, wellness and electronics'}
                                            </Grid>
                                        </Grid> 
                                        </Grid>
                                        <Grid item xs={12} sx={{paddingTop:'5px',fontSize:'20px',fontWeight:500,color:'#85BE49'}}>
                                {'and get your order delivered at your doorstep!'}
                                </Grid>
                                    </Grid>
                                </Grid>
                    </Grid>
                   :
                   <Grid container spacing={2} className='download-app-section' >
                        <Grid item xs={12} md={7}>
                            <Grid container sx={{paddingBottom:'35px',paddingTop:'80px'}}>
                                <Grid item xs={12} textAlign={'left'} sx={{fontSize:'40px',fontWeight:700,color:'rgba(29, 77, 79, 1)'}}>
                                    {'GET STARTED  '}
                                </Grid>
                                <Grid item xs={12} textAlign={'left'} sx={{fontSize:'40px',fontWeight:600,color:'rgba(29, 77, 79, 1)',paddingTop:{md:'30px',sm:'18px'}}}>
                                    {'TODAY!'}
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={8} sx={{color:'rgba(29, 77, 79, 0.7)',fontSize:'30px',fontWeight:500,paddingTop:'40px'}}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                {'Shop local, shop fresh, shop convenient! '}
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                {'shop convenient! '}
                                            </Grid> */}
                                        </Grid> 
                                        </Grid>
                                        <Grid item xs={4}></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{paddingTop:'25px',fontSize:'30px',fontWeight:500,color:'#85BE49'}}>
                                {'Get started with Nearshopz today and have your groceries, daily essentials, and everything you need delivered right to your doorstep. '}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={5} >
                            <img src={downloadappimage} alt="NearShopz" />
                        </Grid>
                    </Grid>}
                </div>
            </Grid>
        </Grid>
    )
}

export default MiddleSection
