import React from 'react';
import { Grid } from '@mui/material';
import nearshopzmap from '../../Assets/Images/nearshopzmap.svg';
import downloadappimage from '../../Assets/Images/downloadappimage.png';
import './index.css';

const MiddleSection = () => {
    return (
        <>
            <Grid container justifyContent={'center'}>
                <div className='centerItems'>
                    <div className='addresspadding'>
                        <img src={nearshopzmap} alt={'Nearshopz-Contact-Us'} />
                    </div>
                    <div className='addresspadding'>
                        <div className='address-heading'>
                            {'NEARSHOPZ APP'}
                            <div className='address'>
                                {'5th floor, Yamuna Building,Phase 3, Technopark,'}
                                <br />
                                {'Trivandrum - 695581, Kerala, India'}
                            </div>
                            <div className='contact'>
                                {'Contact Support :  77368 77750'}
                                <br />
                                {'Email: support@nearshopz.com'}
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid container justifyContent={'center'} alignItems={'center'}>
                <Grid item xs={10} md={10}>
                    <Grid container spacing={2}>
                        <Grid item sx={12} md={7}>
                            <Grid container sx={{ paddingBottom: '35px', paddingTop: '80px' }}>
                                <Grid item xs={12} textAlign={'left'} sx={{ fontSize: '76px', fontWeight: 700, color: 'rgba(29, 77, 79, 1)' }}>
                                    {'GET STARTED'}
                                </Grid>
                                <Grid item xs={12} textAlign={'left'} sx={{ fontSize: '56px', fontWeight: 600, color: 'rgba(29, 77, 79, 1)', paddingTop: '30px' }}>
                                    {'TODAY!'}
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={8} sx={{ color: 'rgba(29, 77, 79, 0.7)', fontSize: '30px', fontWeight: 500, paddingTop: '40px' }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    {'Shop local, shop fresh, shop convenient! '}
                                                </Grid>
                                                {/* <Grid item xs={12}>
                                                    {'baby, pet, wellness and electronics'}
                                                </Grid> */}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ paddingTop: '25px', fontSize: '30px', fontWeight: 500, color: '#85BE49' }}>
                                    {'Get started with Nearshopz today and have your groceries, daily essentials, and everything you need delivered right to your doorstep. '}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sx={12} md={5} >
                            <img src={downloadappimage} alt="NearShopz" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default MiddleSection
