import React from "react";
import Grid from "@mui/material/Grid";
import AlertBar from "../../AlertBar/index";

import { db } from "../../db";

import { useState, useEffect } from "react";

const AddressListingSection = (props) => {
  console.log("props from addeesslistung section", props?.Cart);

  const [selectedColor, setSelectedColor] = useState(props?.Cart?.addressid);
  const [addressid, chnageAddress] = useState(props?.Cart?.addressid);
  const [availableAddresses, setAvailableAddresses] = useState(
    props?.Cart?.availableAddress
  );

  useEffect(() => {
    if (
      props?.Cart?.availableAddress === null ||
      (props?.Cart?.availableAddress?.length == 0 &&
        props?.AddAddressClicked === true)
    ) {
      window.location.reload();
    }
    if (props?.AddAddressClicked === true) {
      setAvailableAddresses(props?.Cart?.availableAddress);
    }
  }, [props?.isAddaddress, props?.AddAddressClicked]);

  React.useEffect(() => {
    props?.getAvailableAddresses(localStorage.getItem("userid"));
  }, []);

  useEffect(() => {
    if (
      props?.Cart?.availableAddress === null ||
      (props?.Cart?.availableAddress?.length == 0 &&
        props?.AddAddressClicked === true)
    ) {
      window.location.reload();
    }
    if (props?.AddAddressClicked === true) {
      window.location.reload();
    }
    setAvailableAddresses(props?.Cart?.availableAddress);
  }, [props?.isAddaddress, props?.AddAddressClicked]);

  useEffect(() => {
    const selectedIndex = props?.Cart?.availableAddress?.findIndex(
      (item) => item._id === props?.Cart?.addressid
    );
    setSelectedColor(selectedIndex);
  }, [props?.Cart?.availableAddress, props?.Cart?.addressid]);

  const onaddressChange = (userid, storeid, addressid) => {
    props?.upateAddress(userid, storeid, addressid);
    chnageAddress(addressid);
  };
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      style={{ boxSizing: "border-box" }}
    >
      <Grid item xs={12} md={12}>
        <div>
          <Grid item xs={12} md={12}>
            <span
              style={{
                color: "#000000",
                fontSize: "18px",
                fontWeight: 600,
                float: "right",
                textAlign: "right",
              }}
            ></span>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              {props?.Cart?.availableAddress?.map((item, index) => (
                <Grid item xs={12} sm={12} md={6} key={index}>
                  <Grid
                    container
                    style={{
                      borderRadius: " 0.8125rem",
                      background: " #FFF",
                      boxShadow: "0px 0px 15px 0px rgba(105, 168, 92, 0.1)",
                    }}
                  >
                    <Grid
                      item
                      xs={2}
                      md={2}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor:
                            selectedColor === index
                              ? "rgba(45, 147, 251, 1)"
                              : "rgba(230, 231, 230, 1)",
                          width: "19px",
                          cursor: "pointer",
                          height: "19px",
                          borderRadius: "17px",
                        }}
                        onClick={() => {
                          setSelectedColor(index);
                          onaddressChange(
                            props?.Cart &&
                              props?.Cart?.userid &&
                              props?.Cart?.userid?.id,
                            props?.Cart &&
                              props?.Cart?.storeid &&
                              props?.Cart?.storeid?.id,
                            item._id
                          );
                        }}
                      ></div>
                    </Grid>

                    <Grid
                      item
                      xs={10}
                      md={10}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "9px 0",
                        boxSizing: "border-box",
                      }}
                    >
                      <h1
                        style={{
                          color: " #061303",
                          fontFamily: "Nunito",
                          fontSize: "0.875rem",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "normal",
                        }}
                      >
                        {item?.houseNumber}
                      </h1>
                      <div
                        style={{
                          color: "#061303",
                          fontFamily: "Nunito",
                          fontSize: "0.8125rem",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "normal",
                          opacity: "0.5",
                        }}
                      >
                        {item?.landMark ? item?.landMark : ""}
                      </div>
                      <div
                        style={{
                          color: "#061303",
                          fontFamily: "Nunito",
                          fontSize: "0.8125rem",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "normal",
                          opacity: "0.5",
                        }}
                      >
                        {item?.locationDescription
                          ? item.locationDescription
                          : ""}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
      </Grid>
      {props?.isAddaddressFailed &&
        props?.isAddaddressFailed === true &&
        props?.addaddresserrorMessage &&
        props?.addaddresserrorMessage !== null && (
          <AlertBar
            type="error"
            message={props?.addaddresserrorMessage}
            {...props}
            props={props}
          />
        )}
      {props?.isAddaddress && props?.isAddaddress === true && (
        <AlertBar
          type="success"
          message={"Address added successfully!"}
          {...props}
          props={props}
        />
      )}
    </Grid>
  );
};

export default AddressListingSection;
