import { gql } from "@apollo/client";


export const create_order_2 = gql`
  mutation createOrder2(
    $userid: ID!,
    $deliveryAddress: String!,
    $deliveryType: String!,
    $deliveryDate: String!,
    $deliveryTime: String!,
    $specialInstructions: String,
    $deliveryInstructions: String,
    $mobileNumber: String!,
    $type: Int!
  ) {
    createOrder2(
      userid: $userid,
      deliveryAddress: $deliveryAddress,
      deliveryType: $deliveryType,
      deliveryDate: $deliveryDate,
      deliveryTime: $deliveryTime,
      specialInstructions: $specialInstructions,
      deliveryInstructions: $deliveryInstructions,
      mobileNumber: $mobileNumber,
      type: $type
    ) {
      id
      orderNumber
      products {
        productid {
          id
          productname
        }
        productPrice
        quantity
        price
      }
      totalPrice
      deliveryType
      deliveryAddress
      deliveryDate
      deliveryTime
      deliveryLat
      deliveryLng
      mobileNumber
      customerName
      specialInstructions
      deliveryInstructions
      stats {
        status
        situation
      }
      lastStatus
      deliveryCharge
      finalBillAmount
      couponId
      discountPrice
      travelTime {
        text
        value
      }
      travelDistance {
        text
        value
      }
      GST
      packingCharge
      handlingCharge
      deliveryTip
      total
      totalPayable
    }
  }
`;

export const Pay_Now = gql`mutation($orderid:ID!)
{
    payNow(orderid:$orderid)
{
    id
    amount
}
}`
export const Verify_Payment = gql`mutation($orderid:ID!,$razorpayOrderid:String!,$paymentid:String!,$signature:String!)
{
    verifyRazorPayPayment(orderid:$orderid,razorpayOrderid:$razorpayOrderid,paymentid:$paymentid,signature:$signature)
  }`