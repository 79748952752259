import React from 'react';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
import { logo } from "../../Assets/image";
// import useStyles from './styles';
import './index.css';

export default function Header(props) {
  // const classes = useStyles();

  return (
    <div className="Navbar">
      {/* <Toolbar style={{justifyContent:'center'}}> */}
        <button className='headerButton' onClick={()=>props.history.push({
      pathname: `/`,
    })}>
          <img className="logo-image" src={logo} alt="logo.png" />
          </button>
        {/* </Toolbar> */}
    </div>
  );
}
