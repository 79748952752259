import React from 'react';
import EmailInvite from './EmailInvite.js';
import AppLink from './AppLink.js';
import WebLink from './WebLink.js';
import AlertBar from '../AlertBar/index.js';
import { Divider } from '@mui/material';

const ReferFriends = (props) => {
    return (
        <React.Fragment>
            <div  style={{paddingBottom:'90px'}}>
                <WebLink {...props} />
                <AppLink {...props} />
                <div  style={{paddingTop:'40px'}}/>
                <Divider>
                    <div style={{background:'#EAF6E1',
                    width:'30px',
                    height:'30px',
                    borderRadius:'50%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    textAlign:'center',
                    color:'rgba(6, 19, 3, 0.7)',
                    fontSize:'14px',
                    fontWeight:500,
                    lineHeight:'19px'}}> 
                      Or
                    </div>
                </Divider>
                <EmailInvite {...props} />
                {(props.InviteSentSuccess || props.InviteSentFailed) ?
                    <AlertBar
                        type={props.InviteSentSuccess ? 'success' : props.InviteSentFailed ? 'error' : 'warning'}
                        message={props.InviteSentSuccess ?
                            'Invite sent successfully !' :
                            props.InviteSentFailed ?
                                'Something went wrong !' : 'Please try again !'}
                                props={props} {...props}
                    />
                    : null}
            </div>
        </React.Fragment>
    )
}

export default ReferFriends