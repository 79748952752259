import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { SUGGESTIONS } from "../../Redux/Actions/Queries";
import client from "../../Configurations/apollo";
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { useStyles } from './style';
import './style.css';

const Location = (props) => {

  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = React.useState('false');
  const [inputfieldClicked, setInputFieldClicked] = React.useState(false);
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));

  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    props.getPlaceBylatlng(`${lat},${lng}`);
    setOpen(false);
  }

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        reverseGeocode(position.coords);
        localStorage.setItem('lattitude', position.coords.latitude);
        localStorage.setItem('longitude', position.coords.longitude);
      })
    }
  }

  const inputClicked = () => {
    setInputFieldClicked(true);
  };

  const setgooglecordinates = (placeid, description) => {
    client
      .query({
        query: SUGGESTIONS,
        variables: {
          placeid: placeid,
        },
      })
      .then((res) => {
        if (res.data) {
          localStorage.setItem('lattitude', res.data.googlemapplacedetails.lat);
          localStorage.setItem('longitude', res.data.googlemapplacedetails.lng);
          localStorage.setItem('location', description);
          props.setUserLocation(
            description,
            res.data.googlemapplacedetails.lat,
            res.data.googlemapplacedetails.lng
          );
          setOpen(false);
          // setAddressOpen(false);
          // props.updateprimarylocation(
          //   localStorage.getItem("userid"),
          //   res.data.googlemapplacedetails.lat,
          //   res.data.googlemapplacedetails.lng,
          //   description
          // );
          props.navigate('/stores');
          // setLoading(false);
        } else if (res.error) return null;
      })
      .catch((err) => {
        alert(
          "OOps,Some error occured while loading location!Please try again"
        );
      });
  };

  const handleClick = (event) => {
    setOpen(true);
    props.getAddress(localStorage.getItem('userid'))
  };

  const handleClose = (e) => {
    e.persist();
    e.preventDefault();
    e.stopPropagation();
    setOpen(false);
  }

  React.useEffect(() => {
    if (props.componentkey === "navbar" && (!localStorage.getItem('location') || localStorage.getItem('location') === null)) {
      setOpen(true);
    }
    else
      setOpen(false);
  },
    // eslint-disable-next-line
    [localStorage.getItem('location')])

  //PLACEHOLDER ANIMATION IN TEXTFIELD

  const placeholderTexts = ['Trivandrum', 'Palakkad', 'Kollam',];
  const [placeholder, setPlaceholder] = React.useState('');
  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    const typingEffect = setInterval(() => {
      const currentPlaceholder = placeholderTexts[currentIndex];
      if (placeholder !== currentPlaceholder) {
        setPlaceholder(currentPlaceholder.substring(0, placeholder.length + 1));
      } else {
        clearInterval(typingEffect);
        setTimeout(() => {
          setPlaceholder('');
          setCurrentIndex((currentIndex + 1) % placeholderTexts.length);
        }, 1000);
      }
    }, 100);
    return () => {
      clearInterval(typingEffect);
    };
  }, [currentIndex, placeholder])

  React.useEffect(() => {
    if (props.isPlaceDescription && props.isPlaceDescription === true &&
      props.PlaceDescription && props.PlaceDescription !== null && props.PlaceDescription.formatted_address !== null) {
      localStorage.setItem('location', props.PlaceDescription.formatted_address);
      props.navigate('/stores');
    }
  },
    [props.isPlaceDescription])

  return (
    <>
      <div className='location-root-div' onClick={handleClick}>
        <div className={props.componentkey === 'store' ? 'location-box-store' :
          props.componentkey === 'navbar/xs' ? 'location-xs' : 'location-box'}>
          {props.componentkey === 'store' ?
            <>
              {'Choose Location'}
            </> :
            props.componentkey === 'navbar/xs' ?
              <>
                <div className='location-navbar'>
                  <span className='location-icon-div'>
                    <PlaceOutlinedIcon />
                  </span>
                  <span className='delivery-location'>
                    <p className='scroll-text'>
                      {`Now delivering at`}<b>{` ${localStorage.getItem('location') && localStorage.getItem('location').split(",", 1)}`}</b>
                    </p>
                  </span>
                  <span className='change-location-button-div'>
                    <button className='change-location-button' style={{ width: 'auto', display: 'inline-block', whiteSpace: 'nowrap' }}>
                      {'Change Location'}
                    </button>
                  </span>
                </div>
              </>
              :
              <>
                <div className='searchIcon'>
                  <LocationOnIcon />
                </div>
                <div className='location-name'>
                  <span className='location-first-name'>
                    {localStorage.getItem('location') && localStorage.getItem('location').split(",", 1)}
                  </span>
                  <span className='location-last-name'>
                    {localStorage.getItem('location') && localStorage.getItem('location').substring(
                      localStorage.getItem('location').indexOf(",") + 1)}
                  </span>
                </div>
              </>
          }
        </div>
      </div>
      <Dialog
        PaperProps={{
          sx: {
            borderRadius: { md: '10px', xs: 0 },
            backgroundColor: '#ffffff',
            width: '100%',
            height: { md: 'calc(100vh - 98px)', xs: '100%' },
            maxHeight: { md: 'calc(100vh - 98px)', xs: '100%' },
          }
        }}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle
          style={{
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '25px',
            textAlign: 'center',
            color: '#000000',
          }}>
          <span
            style={{
              width: '25px', float: 'right',
              color: '#ffffff',
              borderRadius: '50%',
              fontSize: '14px',
              height: '25px',
              background: '#BDBDBD',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center'
            }}
            onClick={() => setOpen(false)}>
            <CloseIcon 
            style={{ 
              cursor: 'pointer', 
              fontSize: '14px' }} 
              onClick={(e) => { 
                e.persist(); 
                e.preventDefault(); 
                setOpen(false); 
                handleClose(e) }} />
          </span>
          {'Choose location'}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Divider />
            <Grid item xs={12} className={classes.input}>
              <span className={classes.enterlocationinputspan}>
                <input
                  className={classes.enterlocationinput}
                  placeholder={`Enter your location e.g."${placeholder}"`}
                  onClick={() => inputClicked()}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    props.autocomplete(e.target.value);
                  }}
                />
              </span>
              <span className={classes.locatemebuttonspan}>
                <button
                  type="button"
                  onClick={findMyLocation}
                  className={classes.locatemebutton}>
                  {
                    (props.isLoadingPlaces && props.isLoadingPlaces === true) ?
                      <CircularProgress color="success" size="inherit" />
                      :
                      <>
                        <GpsFixedIcon color='inherit' />
                        &nbsp;{'Locate me'}
                      </>
                  }
                </button>
              </span>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '15px' }}>
              {
                inputfieldClicked === false ?
                  ((props.isAddresssesSuccess &&
                    props.isAddresssesSuccess === true &&
                    props.Addresses && props.Addresses !== null &&
                    props.Addresses.length > 0) ?
                    props.Addresses
                      .map(address =>
                        <div 
                        className={classes.suggestionList}
                          onClick={(e) => {
                            e.persist();
                            localStorage.setItem('lattitude', address.locationLat);
                            localStorage.setItem('longitude', address.locationLng);
                            localStorage.setItem('location', address.locationDescription);
                            props.setUserLocation(
                              address.locationDescription,
                              address.locationLat,
                              address.locationLng
                            );
                            setOpen(false);
                            props.navigate('/stores');
                          }}>
                          <span className={classes.suggestionHeading}>
                            <span className={classes.locationIcon}>
                              <HomeIcon />
                            </span>
                            <span>
                              {address.houseNumber.charAt(0).toUpperCase() +
                                address.houseNumber.slice(1).toLowerCase()}
                            </span>
                          </span>
                          <span className={classes.suggestionsubheading}>
                            {address.locationDescription}
                          </span>
                        </div>)
                    : null
                  ) : (
                    <>
                      {(inputfieldClicked === true &&
                        props.isAddresssesSuccess &&
                        props.isAddresssesSuccess === true &&
                        props.Addresses && props.Addresses !== null &&
                        props.Addresses.length > 0) ?
                        // eslint-disable-next-line
                        props.Addresses.filter((val) => {
                          if (searchTerm === "") {
                            return val;
                          } else if (
                            val.locationDescription
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          ) {
                            return val;
                          }
                          else if (
                            val.houseNumber
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          ) {
                            return val;
                          }
                        })
                          .map(address =>
                            <div 
                            className={classes.suggestionList}
                              onClick={(e) => {
                                e.persist();
                                localStorage.setItem('lattitude', address.locationLat);
                                localStorage.setItem('longitude', address.locationLng);
                                localStorage.setItem('location', address.locationDescription);
                                setOpen(false);
                                props.setUserLocation(
                                  address.locationDescription,
                                  address.locationLat,
                                  address.locationLng
                                );
                                props.navigate('/stores');
                              }}>
                              <span className={classes.suggestionHeading}>
                                <span className={classes.locationIcon}>
                                  <HomeIcon />
                                </span>
                                <span>
                                  {address.houseNumber.charAt(0).toUpperCase() +
                                    address.houseNumber.slice(1).toLowerCase()}
                                </span>
                              </span>
                              <span className={classes.suggestionsubheading}>
                                {address.locationDescription}
                              </span>
                            </div>
                          ) : null}
                      {(props.isSuggestions && props.isSuggestions === true &&
                        props.Suggestions && props.Suggestions !== null &&
                        props.Suggestions.length && props.Suggestions.length > 0) ?
                        props.Suggestions.map((place =>
                          <div className={classes.suggestionList}
                            onClick={(e) => {
                              e.persist();
                              setgooglecordinates(place.place_id, place.description);
                              localStorage.setItem('location', place.description);
                              setOpen(false);
                            }}>
                            <span className={classes.suggestionHeading}>
                              <span className={classes.locationIcon}>
                                <FmdGoodIcon />
                              </span>
                              <span>
                                {place.description.split(",", 1)}
                              </span>
                            </span>
                            <span className={classes.suggestionsubheading}>
                              {place.description.substring(
                                place.description.indexOf(",") + 1)}
                            </span>
                          </div>
                        ))
                        : (props.isSuggestions && props.isSuggestions === true &&
                          props.Suggestions && props.Suggestions !== null &&
                          props.Suggestions.length && props.Suggestions.length === 0) ?
                          'No Search Results'
                          : null
                      }
                    </>
                  )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Location