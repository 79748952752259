import React from 'react';
import './index.css';

const Footer = (props) => {

    return (
        <>
        <div className="container">
				<ul className="socialLinks mb ph">
					<li><a href="/ContactUs">Contact Us</a></li>
					<li><a href="/termsandconditions">Terms and Conditions</a></li>
					<li><a href="/privacypolicy">Privacy Policy</a></li>
					<div className="clr"></div>
				</ul>
			</div>
        </>
    )
}

export default Footer
