import React from 'react';
import { Divider, Grid } from '@mui/material';
import Product from './Product';
import OrderStepper from './OrderStepper';
// import Lightbox from "react-awesome-lightbox";
// import "../ImageCarousel/lightbox.css";
import { useStyles } from './style';

const OrderDetails = (props) => {
 
  const [messages, setMessages] = React.useState({});
  const [liveorderdata, setLiveorderdata] = React.useState(0);
  const classes = useStyles();
  // const [state, setState] = React.useState({
  //   vieweropen: false,
  //   activeimage: ''
  // })
  // const openImageViewer = (image) => {
  //   setState({
  //     ...state,
  //     vieweropen: true,
  //     activeimage: image
  //   })
  // };
  // const closeImageViewer = () => {
  //   setState({
  //     ...state,
  //     vieweropen: false,
  //   })
  // };

  React.useEffect(() => {
    const messageListener = (message) => {
        if(message!==null)
       { setMessages((prevMessages) => {
            const newMessages = { ...prevMessages };
            newMessages[message.id] = message;
            
            props.getOrderdetails(localStorage.getItem('Orderid'))
            return newMessages;
        });}
        else return null
    };

    const deleteMessageListener = (messageID) => {
        setMessages((prevMessages) => {
            const newMessages = { ...prevMessages };
            delete newMessages[messageID];
            return newMessages;
        });
    };

    if (props.socket !== null) {
        props.socket.on(`${localStorage.getItem('Orderid')}_order`, messageListener);
        props.socket.on('deleteMessage', deleteMessageListener);
        props.socket.emit('getMessages');
    }

    return () => {
        if (props.socket !== null) {
            props.socket.off(`${localStorage.getItem('Orderid')}_order`, messageListener);
            props.socket.off('deleteMessage', deleteMessageListener);
        }
    };
}, [props.socket]);

  React.useEffect(() => {
    props.getOrderdetails(props.orderid);
  },
    // eslint-disable-next-line
    [])
  return (
    <div
      style={{
        background: "transparent",
        borderRadius: "12px",
        paddingBottom: "15px",
      }}
    >
      {props.orderdetailsLoading && props.orderdetailsLoading === true
        ? "Loading..."
        : props.orderdetailssuccess &&
          props.orderdetailssuccess === true &&
          props.OrderDetails &&
          props.OrderDetails !== null && (
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={12} md={10}>
                <div className={classes.OrderItems}>
                  <span>
                    <img
                      src={props.storelogo}
                      alt="storelogo"
                      className={classes.storeLogo}
                    />
                  </span>
                  <span className={classes.storeDetails}>
                    <span className={classes.storeName}>{props.storename}</span>
                    <span className={classes.products}>
                      {props.product}
                      {props.product > 1 ? "items" : "item"}
                    </span>
                  </span>
                </div>
              </Grid>
              <Divider />
              <Grid item xs={12} md={10}>
                <Product props={props} {...props} />
              </Grid>
              <Grid item xs={12} md={10}>
                <div className={classes.divider}></div>
                <div className={classes.orderDetails}>
                  <div>
                    <span className={classes.detailHeading}>
                      {"Item Total"}
                    </span>
                    <span className={classes.detail}>
                      ₹{" "}
                      {parseFloat(
                        Number(props.OrderDetails.totalPrice)
                      ).toFixed(2)}
                    </span>
                    <br />
                  </div>
                  {props.OrderDetails.version === 2 && (
                    <div
                      style={{
                        paddingTop: "8px",
                      }}
                    >
                      <span className={classes.totalHeading}>
                        <b>{"Packing & Handling Charges"}</b>
                      </span>
                      <span className={classes.total}>
                        <b>
                          {" "}
                          ₹
                          {`${parseFloat(
                            props.OrderDetails.packingCharge +
                              props.OrderDetails.handlingCharge
                          ).toFixed(2)}`}
                        </b>
                      </span>
                      <br />
                    </div>
                  )}
                  <div
                    style={{
                      paddingTop: "8px",
                    }}
                  >
                    <span className={classes.totalHeading}>
                      <b>{"Partner Delievry Fee"}</b>
                    </span>
                    <span className={classes.total}>
                      <b>
                        {" "}
                        {`${
                          props.delivery === 0 || props.delivery === null
                            ? "Free Delivery"
                            : "₹" +
                              parseFloat(Number(props.delivery)).toFixed(2)
                        }`}
                      </b>
                    </span>
                    <br />
                  </div>

                  {props.OrderDetails.version === 2 &&
                    props.OrderDetails.deliveryTip !== 0 && (
                      <div
                        style={{
                          paddingTop: "8px",
                        }}
                      >
                        <span className={classes.totalHeading}>
                          <b>{"Tip For Delivery Partner"}</b>
                        </span>
                        <span className={classes.total}>
                          <b>
                            {" "}
                            ₹
                            {`${parseFloat(
                              props.OrderDetails.deliveryTip
                            ).toFixed(2)}`}
                          </b>
                        </span>
                        <br />
                      </div>
                    )}

                  {props.OrderDetails.version === 2 &&
                    props.OrderDetails.GST !== 0 && (
                      <div
                        style={{
                          paddingTop: "8px",
                        }}
                      >
                        <span className={classes.totalHeading}>
                          <b>{"GST"}</b>
                        </span>
                        <span className={classes.total}>
                          <b>
                            {" "}
                            ₹
                            {`${parseFloat(props.OrderDetails.GST).toFixed(2)}`}
                          </b>
                        </span>
                        <br />
                      </div>
                    )}
                  {props.OrderDetails.version === 2 && (
                    <div
                      style={{
                        paddingTop: "8px",
                      }}
                    >
                      <span className={classes.totalHeading}>
                        <b>{"Total"}</b>
                      </span>
                      <span className={classes.total}>
                        <b>
                          {" "}
                          ₹
                          {`${parseFloat(props.OrderDetails.total).toFixed(2)}`}
                        </b>
                      </span>
                      <br />
                    </div>
                  )}

                  {((props.OrderDetails.discountPrice &&
                  props.OrderDetails.discountPrice !== null)||
                  (props?.OrderDetails?.couponID?.type===4)) ? (
                    <div style={{ paddingTop: "8px" }}>
                      <span
                        className={classes.totalPriceHeading}
                        style={{
                          color: "rgba(45, 147, 251, 1)",
                          fontWeight: 1500,
                        }}
                      >
                        <b>{"Discount"}</b>
                      </span>
                      <span className={classes.totalPriceDiscount}>
                     { (props?.OrderDetails?.couponID?.type===4)?
                            "Free Delivery":
                        <b>
                          {" "}
                          -₹{" "}
                          {parseFloat(
                            Number(
                              props.OrderDetails.discountPrice)
                          ).toFixed(2)}
                        </b>}
                      </span>
                    </div>
                  ) : null}

                  <div style={{ paddingTop: "8px" }}>
                    <span
                      className={classes.totalPriceHeading}
                      style={{ color: "#000000", fontWeight: 1500 }}
                    >
                      <b>{"Total Payable"}</b>
                    </span>
                    {props.OrderDetails.version === 2 ? (
                      <span className={classes.totalPrice}>
                        <b>
                          {" "}
                          ₹
                          {parseFloat(
                            Number(props.OrderDetails.totalPayable)
                          ).toFixed(2)}
                        </b>
                      </span>

                    ) : ((props.OrderDetails.discountPrice &&
                      props.OrderDetails.discountPrice !== null)
                      ||(props?.OrderDetails?.couponID?.type===4)) ? (
                      <span className={classes.totalPrice}>
                        <b>
                          {" "}
                          ₹{" "}
                          {props.delivery === 0 || props.delivery === null
                            ? parseFloat(
                                Number(
                                  props.totalprice -
                                (  (props?.OrderDetails?.couponID?.type===4)?
                                  (props.delivery===null?0:props.delivery):
                                    props.OrderDetails.discountPrice)
                                )
                              ).toFixed(2)
                            : parseFloat(
                                Number(
                                  props.totalprice +
                                    props.delivery -
                                    ((props?.OrderDetails?.couponID?.type===4)?
                                    props.delivery:
                                      props.OrderDetails.discountPrice)
                                    
                                )
                              ).toFixed(2)}
                        </b>
                      </span>
                    ) : (
                      <span className={classes.totalPrice}>
                        <b>
                          {" "}
                          ₹{" "}
                          {props.delivery === 0 || props.delivery === null
                            ? parseFloat(Number(props.totalprice)).toFixed(2)
                            : parseFloat(
                                Number(props.totalprice + props.delivery)
                              ).toFixed(2)}
                        </b>
                      </span>
                    )}
                  </div>
                  {props.OrderDetails.paymentStatus === 2 && (
                   <div>
                    <p style={{color:"red",marginBottom:"10px"}}>
                       Payment Recieved
                    </p>
                   </div>
                   )}
                  <div className={classes.divider}></div>
                  {(props?.OrderDetails?.finalBillAmount) && (
                        <>
                          <div className={classes.divider}></div>
                          <div style={{ paddingTop: "8px" }}>
                            <span
                              className={classes.totalHeading}
                              style={{
                                color: "#000000",
                                fontWeight: 1500,
                              }}
                            >
                              <b>{"Bill Amount"}</b>
                            </span>
                            <span className={classes.total}>
                              <b>
                                {" "}
                                ₹{" "}
                               {props?.OrderDetails?.finalBillAmount!==null?
                               parseFloat(props?.OrderDetails?.finalBillAmount).toFixed(2):
                               parseFloat(liveorderdata?.finalBillAmount).toFixed(2)
                               }
                              </b>
                            </span>
                          </div>
                        </>
                      )}
                  {props.OrderDetails.finalBillAmount &&
                  props.OrderDetails.finalBillAmount !== null &&
                  props.OrderDetails.finalBillAmount !== 0?  (
                    <>
                      <div style={{ paddingTop: "8px" }}>
                        <span
                          className={classes.totalHeading}
                          style={{
                            color: "#000000",
                            fontWeight: 1500,
                          }}
                        >
                          <b>{"Amount to pay"}</b>
                        </span>
                        <span className={classes.total}>
                          <b>
                            {" "}
                            ₹{" "}
                            {
                            props.OrderDetails.version===2?
                            parseFloat(
                              Number(
                                props.OrderDetails.finalBillAmount +
                                ((props?.OrderDetails?.packingCharge)!==null?(props?.OrderDetails?.packingCharge):0)
                                +
                                ((props?.OrderDetails?.handlingCharge)!==null?(props?.OrderDetails?.handlingCharge):0)+
                                ((props?.OrderDetails?.deliveryTip)!==null?(props?.OrderDetails?.deliveryTip):0)+
                                  props.delivery -
                                  ((props?.OrderDetails?.couponID?.type===4)?
                                  props.delivery:
                                    (props.OrderDetails.discountPrice!==null?
                                      props.OrderDetails.discountPrice:0))
                              )
                            ).toFixed(2)
                            :

                            parseFloat(
                              Number(
                                props.OrderDetails.finalBillAmount +
                                  props.delivery -
                                  ((props?.OrderDetails?.couponID?.type===4)?
                                  props.delivery:
                                    (props.OrderDetails.discountPrice!==null?
                                      props.OrderDetails.discountPrice:0))
                              )
                            ).toFixed(2)}
                          </b>
                        </span>
                      </div>
                    </>
                  ) : null}
                </div>
              </Grid>
              <Divider />
              <Grid xs={10} md={10} style={{ paddingTop: "15px" }}>
                {props.OrderDetails &&
                props.OrderDetails.lastStatus &&
                props.OrderDetails.lastStatus === "Order-Cancelled" ? (
                  <div className={classes.cancelorderdiv}>
                    {"This order has been cancelled !"}
                  </div>
                ) : props.OrderDetails &&
                  props.OrderDetails.lastStatus &&
                  props.OrderDetails.lastStatus === "Order-Rejected" ? (
                  <div className={classes.cancelorderdiv}>
                    {"This order has been cancelled by the store !"}
                  </div>
                ) : (
                  <OrderStepper
                    orderstatus={
                      props.OrderDetails &&
                      props.OrderDetails.lastStatus &&
                      props.OrderDetails.lastStatus
                    }
                    paymentactive={
                      props.OrderDetails &&
                      props.OrderDetails.paymentActive &&
                      props.OrderDetails.paymentActive
                    }
                    paymentstatus={
                      props.OrderDetails &&
                      props.OrderDetails.paymentStatus &&
                      props.OrderDetails.payment
                    }
                    socket={props.socket}
                    props={props}
                    {...props}
                  />
                )}
              </Grid>
              <Divider orientation="horizontal" />
              <Grid
                item
                xs={12}
                md={10}
                style={{
                  paddingTop: "20px",
                  paddingBottom: "15px",
                  fontSize: "13px",
                  fontWeight: 1000,
                }}
              >
                <b>{"Delivery Details"}</b>
              </Grid>
              <Grid item xs={12} md={10}>
                <div
                  className={classes.orderDetails}
                  style={{
                    border: "1px dashed rgba(6, 19, 3, 0.3)",
                    borderRadius: "12px",
                    padding: "15px",
                  }}
                >
                  <div>
                    <span className={classes.detailHeading}>
                      {"Delivery Date"}
                    </span>
                    <span className={classes.detail}>
                      {props.OrderDetails.deliveryDate}
                    </span>
                    <br />
                  </div>
                  <div style={{ paddingTop: "8px" }}>
                    <span className={classes.totalHeading}>
                      <b>{"Delivery Time"}</b>
                    </span>
                    <span className={classes.total}>
                      <b>{props.OrderDetails.deliveryTime}</b>
                    </span>
                    <br />
                  </div>
                  <div style={{ paddingTop: "8px" }}>
                    <span className={classes.totalHeading}>
                      <b>{"Delivery Address"}</b>
                    </span>
                    <span
                      className={classes.total}
                      style={{ textAlign: "right" }}
                    >
                      <b> {props.OrderDetails.deliveryAddress.split(",", 1)}</b>
                      <br />
                      {props.OrderDetails.deliveryAddress.substring(
                        props.OrderDetails.deliveryAddress.indexOf(",") + 1
                      )}
                    </span>
                    <br />
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
    </div>
  );
}

export default OrderDetails