import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useStyles, } from "./style.js";
import Zoom from "@mui/material/Zoom";
import DeleteIcon from '@mui/icons-material/Delete';

export default function Deleteaddress(props) {
    
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleModalClose = () => {
        setOpen(false);
    };
    const handleClose = (
        e,
        userID,
        id,
        lattitude,
        longitude,
        type
    ) => {
        console.log(type)
        e.preventDefault();
        props.deleteaddress(
            userID,
            id,
            lattitude,
            longitude,
            type
        );
        setOpen(false);
    };
    let userID = localStorage.getItem("userid");
    return (
        <>
        <div onClick={handleClickOpen}>
            <DeleteIcon
                color='#85BE49'
                style={{ cursor: "pointer",color:'#85BE49' }}
            />
            </div>
            <Dialog
                open={open}
                PaperProps={{
                    style: {
                        borderRadius: '13px',
                        backgroundColor: `#ffffff`,
                        width: '100%',
                        padding: '25px'
                    }
                }}
                // fullScreen={fullScreen}
                onClose={handleModalClose}
                // disableBackdropClick={true}
                TransitionComponent={Zoom}
                transitionDuration={1000}
            >
                <DialogTitle id="alert-dialog-title" style={{  display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' ,fontFamily: 'Nunito',fontSize:'1.125rem',fontWeight:700}}>
                    {"Are you Sure ?"}
                </DialogTitle>
                <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                    <DialogContentText id="alert-dialog-description">
                        <p  className='subQuestion'> This will permanently delete your saved address !</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                    <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                      <div style={{paddingRight:'10px',width: '100%'}}>
                        <button  className={classes.buttonCancel} 
                        type="button"
                         onClick={handleModalClose}
                            >
                            {'Cancel'}
                        </button>
                        </div>
                        <div style={{width: '100%'}}>
                        <button
                            className='savebutton'
                            onClick={(e) => {
                                handleClose(
                                    e,
                                    userID,
                                    props.id,
                                    props.locationlattitude,
                                    props.locationlongitude,
                                    "other"
                                );
                            }}
                        >
                            {'Delete'}
                        </button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}
