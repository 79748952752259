import Dexie from 'dexie';

//DEFINE SCHEMA
export const db = new Dexie('Cart');
db.version(1).stores({
  cart: `storeid,storename,totalPrice,products`,
});

db.open().catch((err)=>{
  console.log(err.stack || err)
})
