import React from 'react';
import TopSection from './TopSection';
import MiddleSection from './MiddleSection';
import Footer from './Footer';
import './index.css';

const ContactusPage = (props) => {
  
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <>
      <TopSection />
      <MiddleSection />
      <Footer navigate={props.navigate} {...props} />
    </>
  )
}

export default ContactusPage
