import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    fontSize: '10px',
    color: 'white',
    display: 'flex',
    backgroundColor: 'transparent',
    textAlign: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    userSelect:'none',
  },
  removestore: {
    display:'flex',
    padding:0,
    backgroundColor: "transparent",
    border: "none",
    color: "rgb(137, 199, 74, 1)",
    fontFamily: "Nunito",
    cursor: "pointer",
    fontWeight: 500,
    alignItems:'center',
    fontSize: "12px",
    verticalAlign:'bottom',
    // paddingLeft:'10px',
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: "rgb(137, 199, 74, 1)",
      color: "rgb(137, 199, 74, 1)",
    },
  },
  floatLeft: {
    textAlign: 'left',
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '33px',
    cursor: 'pointer',
    float: 'left'
  },
  floatRight: {
    textAlign: 'center',
    color: '#061303',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19px',
    cursor: 'pointer',
    float: 'right',
    background: 'linear-gradient(180deg, #FFD951 0%, #FFC800 100%)',
    borderRadius: '9px',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
    width:'65px',
    // height:'18px'
  },
  clearcart: {
    float:'right',
    background: 'rgba(234, 67, 53, 0.2)',
    color: '#EA4335',
    fontSize:'14px',
    width:'80px',
    height:'22px',
    borderRadius:'14px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    textAlign:'center',
    cursor:'pointer',
    padding:'5px',
  },
  clearStorediv: {
    display:'flex',
    textAlign:'center',
    alignItems:'center',
    flexDirection:'row',
    width:'100%'
  },
  cartheadingdiv: {
    display:'flex',
    width:'100%',
  },
  cartheading: {
    display:'flex',
    // flexDirection:'column',
    // float:'left',
    width:'100%',
  },
  cartStoreHeading: {
    display: 'flex',
    justifyContent:'center',
    // width: '100%',
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft:'15px',
    paddingRight:'15px',
    background:'#EAF6E1',
    borderRadius:'13px 13px 0px 0px',
  },
  cartStoreName: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '25px',
    color: '#000000',
    float: 'left',
    textAlign: 'left',
    width: '100%',
    display:'flex',
    flexDirection:'column',
    overflow:'hidden',
    whiteSpace:'nowrap',
  },
  cartStoreTotal: {
    float: 'right',
    borderRadius: '15.5px',
    color: 'rgba(0, 0, 0,0.5)',
    fontSize: '14px',
    fontWeight: 800,
    lineHeight: '16px',
    paddingLeft: '15px',
    paddingRight: '15px',
    width: '25%',
    height:'25px',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems:'center',
    display:'flex',
  },
  leftarrow: {
    background:'#EAF6E1',
    width:'30px',
    height:'30px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    textAlign:'center',
    borderRadius:'50%',
    cursor:'pointer',
  },
  storetotal: {
    fontSize:'12px',
    color:'rgba(0,0,0,0.5)',
    textAlign:'center',
  },
  cartProductBox: {
    // width:'100%',
    padding: '15px',
    borderRadius: '13px',
    backgroundColor: '#FFFFFF',
    // position: 'relative',
    // zIndex: 1,
    cursor:'pointer',
    display:'flex',
  },
  deletebutton: {
    // position: 'absolute',
    // zIndex: 2,
    // top: 0,
    // right: 0,
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    background: 'rgba(234, 67, 53, 0.2)',
    color: '#EA4335',
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
  },
  cartProductDetails: {
    width: '100%',
    display: 'flex',
    userSelect:'none',
    alignItems:'center',
    justifyContent:"space-between"
  },
  cartImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: '50px',
    height: '36px',
    userSelect:'none',
  },
  productDetailsDiv:{
    display:'flex',
    flexDirection:'column',
    paddingLeft:'7px',
    overflow:'hidden',
    width:'9.375rem',
    paddingRight:'10px',
  },
  productDetailsDivSmallScreen:{
    display:'flex',
    flexDirection:'column',
    paddingLeft:'7px',
    overflow:'hidden',
    width:'10rem',
    paddingRight:'5px',
  },
  productName:{
    color:'#061303',
    fontSize:'12px',
    lineHeight:'16px',
    fontWeight:500,
    paddingRight:'5px',
  },
  productprice:{
    color:'rgba(6, 19, 3, 0.4)',
    fontSize:'12px',
    fontWeight:600,
    lineHeight:'16px',
    paddingTop:'2px',
  },
  cartPrice: {
    color:'#69A85C',
    justifyContent:'left',
    textAlign:'left',
    alignItems:'center',
    fontSize:'15px',
    fontWeight:700,
    paddingLeft:'10px',
    height:'auto', 
    // wordBreak:'break-word', 
  },
  cartPriceSmallScreen: {
    color:'#69A85C',
    justifyContent:'left',
    textAlign:'left',
    alignItems:'center',
    fontSize:'15px',
    fontWeight:700,
    height:'auto', 
    
    // wordBreak:'break-word', 
  },
  noordersDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    background:'#ffffff',
    borderRadius:'13px',
    // width: '100%',
    height: 'auto',
    paddingTop: '45%',
    paddingBottom: '45%',
  },
  noordersimage: {
    maxHeight: '100%',
    maxWidth: '100%',
    width: '139px',
    height: '139px',
  },
  noordersheading: {
    fontWeight: 600,
    fontSize: '14px',
    textAlign: 'center',
    color: '#000000',
  },
  noorderstext: {
    fontWeight: 600,
    fontSize: '13px',
    textAlign: 'center',
    color: 'rgba(6, 19, 3, 0.5)',
  },
  drawerPaper: {
    height: '100vh',
    backgroundColor: 'red',
  },
  button: {
     background: '#85BE49',
      color: '#FFFFFF',
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: 600,
      textAlign: 'center',
      borderRadius: '12px',
      padding: '10px',
      border: `1px #85BE49`,
      // width: '40%',
      cursor: 'pointer',
      marginLeft:'10px',
},
buttonCancel: {
  background: '#ffffff',
  color: '#85BE49',
  fontSize: '16px',
  lineHeight: '19px',
  fontWeight: 600,
  textAlign: 'center',
  borderRadius: '12px',
  border: `1px solid #85BE49`,
  padding: '10px',
  // width: '40%',
  cursor: 'pointer',
},
}));